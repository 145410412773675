// eslint-disable-next-line
export default (value: any): string => {
  if (typeof value === 'boolean') return value ? 'Yes' : 'No'
  if (/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/.test(value))
    return new Date(value).toLocaleDateString('gr', {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric'
    })
  return value
}
