<template>
  <div class="container-fluid">
    <div class="row justify-content-between">
      <div class="col-4">
        <h1>Alerts</h1>
      </div>
      <div class="col-4 text-right">
        <b-badge variant="primary" class="mr-2">Count: {{ alerts.length }}</b-badge>
      </div>
    </div>
    <b-table :items="alerts" :fields="fields" @row-clicked="viewAlert" primary-key="id" sticky-header="70vh">
      <template #cell(date)="data">
        <span :class="{'font-weight-bold': !data.item.readedOn}">
          {{ data.item.date | date }}
        </span>
      </template>
      <template #cell(source)="data">
        <span :class="{'font-weight-bold': !data.item.readedOn}">
          {{ data.item.source }}
        </span>
      </template>
      <template #cell(readedOn)="data">
        <span :class="{'font-weight-bold': !data.item.readedOn}">
          {{ data.item.readedOn | date }}
        </span>
      </template>
      <template #cell(readedBy)="data">
        <span :class="{'font-weight-bold': !data.item.readedOn}">
          {{ data.item.readedBy }}
        </span>
      </template>
    </b-table>
    <b-row>
      <b-col sm="2" md="3" class="my-1">
        <b-form-group label="Per page" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="right" label-size="sm" class="mb-0">
          <b-form-select id="per-page-select" v-model="listInput.perPage" :options="pageOptions" size="sm" @change="fetchAlerts"></b-form-select>
        </b-form-group>
      </b-col>
      <b-col sm="7" md="6" class="my-1">
        <b-pagination v-model="listInput.currentPage" :total-rows="totalRows" :per-page="listInput.perPage" align="fill" size="sm" class="my-0" @change="fetchAlerts"></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator';
  import { api } from '@/services/api';

  @Component
  export default class AlertList extends Vue {
    alerts: any[] = [];
    totalRows: number = 0;
    fields: Array<object> = [
      { key: 'date', label: 'Date' },
      { key: 'source', label: 'Source' },
      { key: 'readedOn', label: 'Readed on' },
      { key: 'readedBy', label: 'Readed by' },
    ];

    pageOptions: Array<number | object> = [5, 10, 20, 50, { value: 1000000, text: "Show all" }];

    listInput: ListInput = new ListInput();

    created() {
      this.fetchAlerts();
    }

    fetchAlerts() {
      api.postExt<ListInput, AlertListOutput>('/api/alert/list', this.listInput)
        .then(response => {
          this.alerts = response.items;
          this.totalRows = response.totalRows;
        });
    }

    viewAlert(alert: any) {
      this.$router.push({ name: 'AlertDetail', params: { id: alert.id } });
    }
  }

  class ListInput {
    perPage: number = 10;
    currentPage: number = 1;
  }

  interface Alert {
    id: number;
    source: string;
    date: string;
    readedOn: string | null;
    readedBy: string | null;
  }

  interface AlertListOutput {
    items: Alert[];
    totalRows: number;
  }

</script>
