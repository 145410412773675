<template>
    <vue-bootstrap-autocomplete :data="searchResults"
                             v-model="searchText"
                             :maxMatches="20"
                             :serializer="(s) => s.description"
                             @hit="onHit($event)"
                             :placeholder="placeholder2display" 
                             :showAllResults="true" />
</template>

<script lang="ts">
    import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
    import { Debounce } from 'vue-debounce-decorator'
    import { api } from '@/services/api'
    import { SearchOutput, SearchOutputItem } from '@/models/SearchOutput'

    @Component
    export default class FieldSearch extends Vue {
        @Prop(Boolean) clearOnHit!: boolean
        @Prop(String) placeholder!: string

        searchText: string | null = null
        searchResults: SearchOutputItem[] = []

        get placeholder2display(): string {
            return this.placeholder ?? 'type to search'
        }

        @Debounce(400)
        search() {
            if (this.searchText == null || this.searchText == '') {
                this.searchResults = []
                return
            }

            //this.searchText = this.searchText.trim()

            api.get<SearchOutput>('/api/search',
                { searchStr: this.searchText })
                .then((data: SearchOutput) =>
                    this.searchResults = data.items
                )
        }

        @Watch('searchText')
        onDisplayedTextChanged() {
            this.search()
        }

        onHit(event: any) {
            this.$emit('hit', event)
            if (this.clearOnHit) this.searchText = ''
        }
    }
</script>

<style scoped></style>
