<template>
  <div>
    <b-navbar id="navbar" toggleable="xl" type="dark" variant="mybg" fixed="top">
      <b-navbar-brand href="/CRM">
        <img src="./../assets/logo_white_small.png" />
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item-dropdown :text="$t('Realties')" right>
            <b-dropdown-item to="/Objects">{{ $t('Properties') }}</b-dropdown-item>
            <b-dropdown-item to="/ObjectKeys" right>{{ $t('Keys') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown :text="$t('Accounts')" right>
            <b-dropdown-item to="/Owners">{{ $t('Owners') }}</b-dropdown-item>
            <b-dropdown-item to="/Clients" right>{{ $t('Clients') }}</b-dropdown-item>
            <b-dropdown-item to="/Partners" right>{{ $t('Partners') }}</b-dropdown-item>
            <b-dropdown-item to="/Professionals" right>{{ $t('Specialists') }}</b-dropdown-item>
            <b-dropdown-item to="/Employes" v-if="isNotBranchUser" right>{{ $t('Employes') }}</b-dropdown-item>
            <b-dropdown-item to="/Leads" right>{{ $t('Leads') }}</b-dropdown-item>
            <b-dropdown-item to="/Alerts" v-if="isNotBranchUser" right>{{ $t('Alerts') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item to="/Viewing" right>{{ $t('Viewings') }}</b-nav-item>
          <b-nav-item to="/Deals" right>{{ $t('Deals') }}</b-nav-item>
          <!--<b-nav-item to="/AllAccounts" right>{{ $t('All accounts') }}</b-nav-item>-->
          <b-nav-item to="/Reservations" v-if="isNotBranchUser" right>{{ $t('Reservations') }}</b-nav-item>
          <b-nav-item to="/Payments" v-if="isNotBranchUser" right>{{ $t('Payments') }}</b-nav-item>
          <b-nav-item to="/DocumentList" right>{{ $t('Trustate Forms') }}</b-nav-item>
          <b-nav-item to="/Settings" v-if="isNotBranchUser" right>{{ $t('Settings') }}</b-nav-item>
          <b-nav-item to="/Alerts" class="position-relative" id="alert-menu-item" v-if="unreadAlertsCount>0">
            <font-awesome-icon icon="envelope" />
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              {{unreadAlertsCount}}
            </span>
          </b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <field-search-and-jump />
          <nav-item-lang></nav-item-lang>
          <b-nav-item-dropdown v-if="userName !== null" :text="userName.charAt(0).toUpperCase()">
            <b-dropdown-item v-on:click="Logout">
              <font-awesome-icon size="lg" icon="sign-out-alt" />
              {{ $t('Logout') }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="content">
      <b-alert v-if="alert.message" show :variant="alert.type" class="mb-0" v-html="alert.message"></b-alert>
      <router-view />
    </div>
  </div>
</template>
<script lang="ts">
  import { Component, Watch, Vue } from 'vue-property-decorator'
  import { Getter } from 'vuex-class'
  import NavItemLang from './../components/NavItemLang.vue'
  import FieldSearchAndJump from './../components/Shared/FieldSearchAndJump.vue'
  import router from '../router';
  import AlertState from '@/models/AlertState'
  import { api } from '@/services/api'

  @Component({
    components: {
      NavItemLang,
      FieldSearchAndJump
    }
  })
  export default class Crm extends Vue {
    @Getter('authentication/userName')
    private userName!: string

    Logout(): void {
      this.$store.dispatch('authentication/logout');
      router.push('/');
    }
    get alert(): AlertState {
      return this.$store.state.alert
    }

    unreadAlertsCount = 0; // Add this line

    mounted() {
      this.getUnreadAlertsCount();
      setInterval(() => {
        this.getUnreadAlertsCount();
      }, 300000);
    }

    getUnreadAlertsCount() {
      api.get<number>('/api/Alert/unreadCount')
        .then((data) => {
          this.unreadAlertsCount = data;
        })
    }

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(): void {
      this.getUnreadAlertsCount()
    }

    @Getter('authentication/branchId')
    private branchId!: number

    get isNotBranchUser(): boolean {
      return !this.branchId
    }
  }
</script>

<style scoped>
  .content {
    padding-top: 70px;
  }
  /* make all elements inside alert-menu-item white color */
  #alert-menu-item * {
    color: white;
  }
</style>