<template>
    <div class="mb-3">
        <h6>{{list.items.length == 0 ? $t('No assignments') : $t('Assigned to:')}}</h6>
        <div v-for="(item, index) in list.items" :key="item.accountId" class="mb-2">
            <span class="mr-2">
                <router-link :to="{ name: 'Accounts/View', params: { id: item.accountId } }">
                    {{ item.account }}
                </router-link>
            </span>

            <b-button v-if="editMode"
                      variant="outline-danger"
                      size="sm"
                      @click="deleteRequest(item, index)">
                <font-awesome-icon icon="trash" />
            </b-button>
        </div>
        <b-collapse v-model="editMode" class="mb-4">
            <b-form inline>
                <label class="mr-sm-2" for="inline-form-custom-select-pref">{{ $t('New assignment to:') }}</label>
                <b-form-group class="mr-sm-2">
                    <field-account-search v-model="newItem.accountId" :initialText="newItem.account" :filter="filter" />
                </b-form-group>
                <b-button variant="success" @click="add">{{ $t('Add') }}</b-button>

            </b-form>
        </b-collapse>
        <b-button :variant="editMode ? 'success' : 'primary'" @click="editMode = !editMode">{{$t('Edit assignments')}}</b-button>
        <b-modal v-model="showDeleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete assignment?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import FieldAccountSearch from '../Shared/FieldAccountSearch.vue'
    import { AccountSearchFilter } from '../Shared/FieldAccountSearch.vue'
    import { api } from '@/services/api'

    @Component({
        components: {
            FieldAccountSearch
        }
    })
    export default class Assignment extends Vue {
        @Prop() parent!: string
        @Prop() parentId!: string | number
        editMode = false
        list: IAssignmentList = {
            canEdit: true,
            items: []
        }
        @Watch('parentId')
        onParentIdSet() {
            this.fetch()
        }
        created() {
            this.fetch()
        }
        fetch() {
            if (!this.parentId) {
                return
            }
            api.get<IAssignmentList>(`/api/assignment/${this.getParentName()}/${this.parentId}`)
                .then((data: IAssignmentList) => {
                    this.list = data
                })

        }

        deleteIndex = -1
        deleteItem: IAssignmentListItem | null = null
        showDeleteConfirmation = false

        deleteRequest(item: IAssignmentListItem, index: number): void {
            this.deleteItem = item
            this.deleteIndex = index
            this.showDeleteConfirmation = true
            //this.$bvModal.show('deleteAssignmentConfirmation')
        }
        deleteConfirmed(): void {
            if (this.deleteIndex == -1 || this.deleteItem == null) return;
            api.del(`/api/assignment/${this.getParentName()}/${this.parentId}/${this.deleteItem.accountId}`)
                .then(() => {
                    if (this.list !== null && this.deleteIndex !== undefined)
                        this.list.items.splice(this.deleteIndex, 1)
                })

        }

        getParentName(): string {
            switch (this.parent) {
                case Parent[Parent.Account]: return 'account'
                case Parent[Parent.Lead]: return 'lead'
                case Parent[Parent.Object]: return 'object'
                case Parent[Parent.Viewing]: return 'viewing'
                default: throw 'Unknown value for Parent enum: ' + this.parent
            }
        }

        showAddBlock = false
        newItem = {} as IAssignmentListItem
        filter: AccountSearchFilter = AccountSearchFilter.OnlyEmployeAndProfessional
        add(): void {
            let res: Promise<IAssignmentListItem>
            switch (this.parent) {
                case Parent[Parent.Lead]:
                    res = api.postExt<IInputLead, IAssignmentListItem>(
                        '/api/assignment/lead/add',
                        {
                            assignedTo: this.newItem.accountId,
                            demandId: this.parentId as string
                        })
                    break
                case Parent[Parent.Account]:
                    res = api.postExt<IInputAccount, IAssignmentListItem>(
                        '/api/assignment/account/add',
                        {
                            assignedTo: this.newItem.accountId,
                            accountId: this.parentId as number
                        })
                    break
                case Parent[Parent.Object]:
                    res = api.postExt<IInputObject, IAssignmentListItem>(
                        '/api/assignment/object/add',
                        {
                            assignedTo: this.newItem.accountId,
                            objectId: this.parentId as number
                        })
                    break
                case Parent[Parent.Viewing]:
                    res = api.postExt<IInputViewing, IAssignmentListItem>(
                        '/api/assignment/viewing/add',
                        {
                            assignedTo: this.newItem.accountId,
                            viewingId: this.parentId as number
                        })
                    break
                default:
                    throw 'Unknown value for Parent enum: ' + this.parent
            }
            res.then((output: IAssignmentListItem) => {
                this.list.items.push(output)
            })
        }
    }

    // eslint-disable-next-line
    enum Parent { Object, Account, Lead, Viewing }

    interface IAssignmentList {
        canEdit: boolean
        items: IAssignmentListItem[]
    }

    interface IAssignmentListItem {
        accountId: number
        account: string
        createdBy: string
        createdOn: string
    }

    interface IInputLead {
        assignedTo: number
        demandId: string
    }
    interface IInputObject {
        assignedTo: number
        objectId: number
    }
    interface IInputAccount {
        assignedTo: number
        accountId: number
    }
    interface IInputViewing {
        assignedTo: number
        viewingId: number
    }
</script>

<style scoped lang="scss"></style>
