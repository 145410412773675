<template>
  <b-alert v-if="error" show variant="danger" class="mt-3">
    {{ error.message }}
    <div v-if="error.details">
      <b-button size="sm" v-b-toggle.collapse-1 variant="light" class="mt-1">Show details</b-button>
      <b-collapse id="collapse-1" class="mt-1">
        <p>
          {{ error.details }}
        </p>
      </b-collapse>
    </div>
  </b-alert>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { MyError } from '@/misc/ErrorService'

@Component({})
export default class ErrorBox extends Vue {
  @Prop() error?: MyError
}
</script>

<style scoped></style>
