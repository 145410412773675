<template>
    <div>
        <b-navbar id="navbar" toggleable="xl" type="dark" variant="mybg">
            <b-navbar-brand href="#"> Trustate Payments </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <nav-item-lang></nav-item-lang>
                    <b-nav-item-dropdown v-if="userName" :text="userName">
                        <b-dropdown-item v-on:click="Logout">{{ $t('Logout') }}</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <template v-if="$route.name == 'Home'">
            <transaction-account-list v-if="userRoles.indexOf('admin')!=-1"></transaction-account-list>
            <transaction-account-view v-if="userRoles.indexOf('admin')==-1 && userRoles.indexOf('clientPayments')!=-1"></transaction-account-view>
        </template>
        <b-alert v-if="alert.message" show :variant="alert.type" class="mb-0" v-html="alert.message"></b-alert>
        <router-view />
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { Getter } from 'vuex-class'
    import TransactionAccountView from '@/views/Transactions/TransactionAccountView.vue'
    import TransactionAccountList from '@/views/Transactions/TransactionAccountList.vue'
    import router from '../router';
    import AlertState from '@/models/AlertState'
    import NavItemLang from './../components/NavItemLang.vue'

    @Component({
        components: {
            NavItemLang,
            TransactionAccountList,
            TransactionAccountView
        }
    })
    export default class HomePayments extends Vue {
        @Getter('authentication/userName')
        private userName!: string

        @Getter('authentication/userRoles')
        private userRoles!: string

        Logout(): void {
            this.$store.dispatch('authentication/logout');
            router.push('/login');
        }
        get alert(): AlertState {
            return this.$store.state.alert
        }
    }
</script>
