var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',{attrs:{"id":"field-editor","title":_vm.$t('Edit'),"cancel-title":_vm.$t('Cancel')},on:{"ok":_vm.handleOk}},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[(_vm.type == 'boolNullable')?_c('field-form-bool-nullable',{attrs:{"label":_vm.label},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_c('b-form-group',{attrs:{"label-for":"control"}},[_c('template',{slot:"label"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.label)}})]),(_vm.type == 'text')?_c('b-form-input',{attrs:{"id":"control","type":"search"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),(_vm.type == 'textarea')?_c('b-form-textarea',{attrs:{"id":"control","rows":"8","max-rows":"16"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),(
        _vm.type == 'number' ||
        _vm.type == 'distance' ||
        _vm.type == 'distance-km' ||
        _vm.type == 'currency' ||
        _vm.type == 'area'
      )?_c('b-form-input',{attrs:{"id":"control","type":"number"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),(_vm.type == 'select' || _vm.type == 'enum')?_c('b-form-select',{attrs:{"options":_vm.selectList,"id":"control"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),(_vm.type == 'date')?_c('b-form-input',{attrs:{"id":"control","type":"date"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),(_vm.type == 'location')?_c('vue-bootstrap-autocomplete',{attrs:{"data":_vm.addresses,"maxMatches":20,"serializer":(s) => s.text,"placeholder":_vm.value},on:{"hit":function($event){_vm.item.locationId = $event.id}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e(),(_vm.type == 'flag-enum')?_c('b-form-checkbox-group',{attrs:{"id":"control","options":_vm.selectList,"name":"control"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }