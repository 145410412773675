export class AccountListQueryOutput {
  public items: AccountListQueryOutputItem[] = []
}

export class AccountListQueryOutputItem {
  id?: number
  name?: string
  public constructor(init?: Partial<AccountListQueryOutputItem>) {
    Object.assign(this, init)
  }
}
