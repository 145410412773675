<template>
    <div class="container-md mt-2">
        <h1>{{ $t('Viewing') }} <span v-if="data && data.viewingNumber">#{{data.viewingNumber}}</span></h1>
        <div id="loadingSpinner" v-if="!loaded" class="d-flex">
            <b-spinner small label="Busy"></b-spinner><span class="ml-1">{{ $t('Loading...') }}</span>
        </div>
        <b-form v-if="loaded">
            <b-form-row>
                <b-col class="col-4">
                    <label for="DateOfAappointmentCreated">{{ $t('Appointment created') }}</label>
                    <b-form-datepicker no-flip id="DateOfAappointmentCreated"
                                       v-model="dateOfAppointmentCreated"
                                       :readonly="mode == MODE_VIEW"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col class="col-3">
                    <b-form-group v-bind:label="$t('Time')">
                        <b-form-timepicker v-model="timeOfAppointmentCreated" locale="en"
                                           :readonly="mode == MODE_VIEW"></b-form-timepicker>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <label for="checkIn">{{ $t('Date of viewing') }}</label>
                    <b-form-datepicker no-flip id="dateOfViewing"
                                       v-model="dateOfViewing"
                                       :readonly="mode == MODE_VIEW"
                                       reset-button
                                       class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col class="col-3">
                    <b-form-group v-bind:label="$t('Time')">
                        <b-form-timepicker v-model="timeOfViewing" locale="en"
                                           :readonly="mode == MODE_VIEW"></b-form-timepicker>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-8">
                    <label for="demand">{{ $t('Original lead') }}</label>
                    <field-lead-search v-if="mode != MODE_VIEW" v-model="data.demandId" :initialText="data.demand" />
                    <router-link v-if="mode == MODE_VIEW && data.demandId" :to="{ name: 'Leads/View', params: { id: data.demandId } }">
                        {{ data.demand }}
                    </router-link>
                    <span v-if="mode == MODE_VIEW && !data.demandId">
                        {{ $t('not set') }}
                    </span>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">

                    <b-table :items="data.results" :fields="fields">
                        <template #cell(objectReferenceNumber)="data">
                            <router-link v-if="mode == MODE_VIEW" :to="{ name: 'Objects/View', params: { id: data.item.objectId } }">
                                #{{ data.item.objectReferenceNumber }}
                            </router-link>
                            <span v-if="mode != MODE_VIEW">#{{ data.item.objectReferenceNumber }}</span>
                        </template>
                        <template #cell(result)="cell">
                            <b-form-select v-model="cell.item.result"
                                           :disabled="mode == MODE_VIEW"
                                           :options="data.resultOptions"
                                           id="source"></b-form-select>
                        </template>
                    </b-table>
                </b-col>
            </b-form-row>
            <b-form-row class="mt-4" v-if="mode != MODE_CREATE">
                <b-card :title="$t('Assignments')">
                    <assignment parent="Viewing" :parentId="data.id"></assignment>
                </b-card>
            </b-form-row>
            <b-form-row class="mt-4" v-if="mode != MODE_CREATE">
                {{ $t('Created') }}: {{ data.createdOn | date }} by {{ data.createdByAccountDisplayName }} |
                {{ $t('Modified') }}: {{ data.modifiedOn | date }} {{ data.modifiedByAccountDisplayName }}
            </b-form-row>
            <b-form-row class="mt-2">
                <b-col class="col-12">
                    <b-button v-if="mode != MODE_VIEW" v-on:click="save" variant="success" class="mr-2">{{$t('Save')}}</b-button>
                    <b-button v-if="mode != MODE_VIEW" v-on:click="back" class="mr-2">{{$t('Cancel')}}</b-button>
                    <b-button v-if="mode == MODE_EDIT" v-on:click="deleteRequest" variant="danger">{{$t('Delete')}}</b-button>
                    <b-button v-if="mode == MODE_VIEW" v-on:click="edit" variant="success" class="mr-2">{{$t('Edit')}}</b-button>
                    <b-button v-if="mode == MODE_VIEW" v-on:click="back" class="mr-2">{{$t('Back')}}</b-button>
                </b-col>
            </b-form-row>
            <div v-if="mode == MODE_VIEW" class="mt-4">
                <h1>{{ $t('Activities of the Viewing') }}</h1>
                <ActivityList2 :parentEntity="entityType" :parentId="id"></ActivityList2>
            </div>
        </b-form>
        <b-modal id="deleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">{{$t('Delete the viewing?')}}</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Mixins } from 'vue-property-decorator'
    import BaseComponent from '@/components/Shared/BaseComponent.vue'
    import { Route } from 'vue-router'
    import { api } from '@/services/api'
    import FieldLeadSearch from '@/components/Shared/FieldLeadSearch.vue'
    import ActivityList2 from './../../components/Activity/ActivityList2.vue'
    import { EntityType } from '../../models/Enums'
    import { ISelectListItem } from '@/models/ISelectListItem'
    import Assignment from '@/components/Shared/Assignment.vue'

    @Component({
        components: {
            ActivityList2,
            FieldLeadSearch,
            Assignment 
        }
    })
    export default class ViewingEdit extends Mixins(BaseComponent) {
        id: number | null = null
        data: Viewing | null = null
        loaded = false

        readonly MODE_UNDEFINED = 0
        readonly MODE_CREATE = 1
        readonly MODE_EDIT = 2
        readonly MODE_VIEW = 3
        private mode = this.MODE_UNDEFINED

        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            this.id = Number(r.params.id)
            this.mode = this.id ? this.MODE_VIEW : this.MODE_CREATE
            var result =
                this.mode != this.MODE_CREATE
                    ? api.get<Viewing>(`/api/Viewing/edit/${this.id}`)
                    : api.get<Viewing>('/api/Viewing/add')

            result
                .then((data) => {
                    this.data = data
                    this.loaded = true
                })
        }
        save(): void {
            api.post<Viewing>('/api/Viewing/update', this.data as Viewing)
                .then(() => { this.back() })
        }
        deleteRequest(): void {
            this.$bvModal.show('deleteConfirmation')
        }
        deleteConfirmed(): void {
            if (this.mode != this.MODE_EDIT) return

            api.del(`/api/Viewing/${this.id}`)
                .then(() => {
                    this.back()
                })
        }
        get demandDecription(): string {
            return 'not defined'
        }

        edit(): void {
            this.mode = this.MODE_EDIT
        }
        view(): void {
            this.mode = this.MODE_VIEW
        }
        activityViewMode = 0
        entityType = EntityType.Viewing

        get dateOfViewing(): string {
            return !this.data ||
                this.data.dateOfViewing == undefined ||
                this.data.dateOfViewing.length < 19
                ? ''
                : this.data.dateOfViewing.substr(0, 10)
        }
        set dateOfViewing(value: string) {
            if (
                !this.data ||
                this.data.dateOfViewing == undefined ||
                this.data.dateOfViewing.length < 19
            ) {
                console.log('dateOfViewing')
                return
            }
            this.data.dateOfViewing =
                value + this.data.dateOfViewing.substr(10, 25)
        }
        get timeOfViewing(): string {
            return !this.data ||
                this.data.dateOfViewing == undefined ||
                this.data.dateOfViewing.length < 19
                ? ''
                : this.data.dateOfViewing.substr(11, 8)
        }
        set timeOfViewing(value: string) {
            if (
                !this.data ||
                this.data.dateOfViewing == undefined ||
                this.data.dateOfViewing.length < 19
            )
                return
            this.data.dateOfViewing =
                this.data.dateOfViewing.substr(0, 11) +
                value +
                this.data.dateOfViewing.substr(19, 14)
        }
        get dateOfAppointmentCreated(): string {
            return !this.data ||
                this.data.appointmentCreated == undefined ||
                this.data.appointmentCreated.length < 19
                ? ''
                : this.data.appointmentCreated.substr(0, 10)
        }
        set dateOfAppointmentCreated(value: string) {
            if (
                !this.data ||
                this.data.appointmentCreated == undefined ||
                this.data.appointmentCreated.length < 19
            )
                return
            this.data.appointmentCreated =
                value + this.data.appointmentCreated.substr(10, 25)
        }
        get timeOfAppointmentCreated(): string {
            return !this.data ||
                this.data.appointmentCreated == undefined ||
                this.data.appointmentCreated.length < 19
                ? ''
                : this.data.appointmentCreated.substr(11, 8)
        }
        set timeOfAppointmentCreated(value: string) {
            if (
                !this.data ||
                this.data.appointmentCreated == undefined ||
                this.data.appointmentCreated.length < 19
            )
                return
            this.data.appointmentCreated =
                this.data.appointmentCreated.substr(0, 11) +
                value +
                this.data.appointmentCreated.substr(19, 14)
        }

        fields = [
            { key: 'objectReferenceNumber', label: 'Object' }, 'result'
        ]
    }
    interface Viewing {
        id: number
        appointmentCreated: string | null
        dateOfViewing: string | null
        demand: string | null
        demandId: string | null
        createdByAccountDisplayName: string | null
        createdOn: string | null
        modifiedByAccountDisplayName: string | null
        modifiedOn: string | null
        results: ViewingObjectStatus[]
        resultOptions: ISelectListItem[]
    }
    interface ViewingObjectStatus {
        objectId: number
        result: number
    }
</script>

<style scoped></style>
