<template>
  <FieldSearch v-on:hit="onHit"></FieldSearch>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import FieldSearch from './FieldSearch.vue'
import { SearchOutputItem, SearchQueryOutputItemType } from '../../models/SearchOutput'

@Component({ components: { FieldSearch } })
export default class FieldSearchAndJump extends Vue {
  onHit(item: SearchOutputItem) {
    if (item.entity == SearchQueryOutputItemType.Object)
      this.$router.push({ name: 'Objects/View', params: { id: item.id as string } })
    else if (item.entity == SearchQueryOutputItemType.Lead)
      this.$router.push({ name: 'Leads/View', params: { id: item.id as string } })
    else this.$router.push({ name: 'Accounts/View', params: { id: item.id as string } })
  }
}
</script>

<style scoped></style>
