<template>
    <action-list />
</template>
<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import ActionList from '@/views/Activity/ActionList.vue'
    @Component({ components: { ActionList } })
    export default class MainPage extends Vue {
    }
</script>
