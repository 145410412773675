<template>
  <div>
    <gmap-map v-if="lat" :center="mapCenter" :zoom="zoomCalc" style="width: 100%; height: 400px">
      <gmap-marker :position="position"></gmap-marker>

      <gmap-circle v-if="approxRadius != 0"
                   :center="approxPosition"
                   :radius="approxRadius"
                   :options="{
          fillColor: 'blue',
          fillOpacity: 0.35,
          strokeColor: 'blue',
          strokeOpacity: 0.8,
          strokeWeight: 1
        }">
      </gmap-circle>
    </gmap-map>
    <p v-else>{{ $t('Map location not set') }}</p>
  </div>
</template>

<script>
  export default {
    name: 'ObjectViewMap',
    props: ['lat', 'lng', 'zoom', 'approxRadius', 'approxLat', 'approxLng'],
    data() {
      return {
        defaultCenter: {
          lat: 40.63471386411826,
          lng: 22.93874616154762
        },
        defaultZoom: 12
      }
    },
    computed: {
      position() {
        if (!this.lat) return this.defaultCenter
        return {
          lat: this.lat,
          lng: this.lng
        }
      },
      approxPosition() {
        if (!this.approxRadius) return this.defaultCenter
        return {
          lat: this.approxLat,
          lng: this.approxLng
        }
      },
      mapCenter() {
        if (!this.lat) return this.defaultCenter
        return this.position
      },
      zoomCalc() {
        return this.zoom ? this.zoom : this.defaultZoom
      }
    }
  }
</script>
