<template>
  <div>
    <span v-for="item in items" v-bind:key="item.id" class="mr-2">
      <a :href="getLink(item)">
        {{ item.description }}
      </a>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ActivityLinkDto } from './../../models/ActivityDto2'
import { EntityType } from './../../models/Enums'

@Component
export default class ActivityLinks extends Vue {
  @Prop() items?: ActivityLinkDto[]

  getLink(item: ActivityLinkDto): string {
    return item.entity == EntityType.Account
      ? '/Accounts/View/' + item.id
      : '/Objects/View/' + item.id
  }
}
</script>

<style scoped lang="scss"></style>
