<template>
  <span v-if="value != 'Undefined'">
    {{ label }}
    <field
      name="name"
      :type="type"
      :value="value"
      :edit="edit"
      v-on:click="click"
      :suffix="suffix"
    />
  </span>
</template>

<script>
import field from './../Shared/Field.vue'

export default {
  name: 'FieldWLabel',
  props: ['name', 'value', 'edit', 'type', 'suffix'],
  components: { field },
  computed: {
    label: function () {
      return this.$t(this.name)
    }
  },
  methods: {
    click: function () {
      if (this.edit) this.$emit('click', this.name, this.type)
    }
  }
}
</script>
