<template>
    <div class="container-md mt-4">
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <h1>{{ $t('Accounts Merge') }}</h1>
        <table class="table">
            <thead>
                <tr>
                    <th>Field name</th>
                    <th>Value from record 1</th>
                    <th>Value from record 2</th>
                </tr>
            </thead>
            <tr v-for="i in list"
                v-bind:key="i.name"
                :class="a1[i.name] === a2[i.name] ? 'table-success' : 'table-warning'">
                <td>{{ i.name }}</td>
                <td>
                    <b-form-radio v-model="i.a2Selected" :name="'radios' + i.name" value="false">
                        {{
            a1[i.name] | boolOrAny
                        }}
                    </b-form-radio>
                </td>
                <td>
                    <b-form-radio v-model="i.a2Selected" :name="'radios' + i.name" value="true">
                        {{
            a2[i.name] | boolOrAny
                        }}
                    </b-form-radio>
                </td>
            </tr>
        </table>
        <b-button variant="primary" v-on:click="merge" class="mr-2 mb-2">Merge</b-button>
        <b-button variant="outline-primary" v-on:click="cancel" class="mr-2 mb-2">Cancel</b-button>
    </div>
</template>

<script lang="ts">
    import { Vue, Component, Watch } from 'vue-property-decorator'
    import { Route } from 'vue-router'
    import AccountDto from '@/models/AccountDto'
    import { api } from '@/services/api'

    @Component
    export default class AccountMerge extends Vue {
        a1id: number | null = null
        a2id: number | null = null
        a1: AccountDto = new AccountDto()
        a2: AccountDto = new AccountDto()

        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            this.a1id = +r.params.a1
            this.a2id = +r.params.a2
            const p1 = api.get<AccountDto>(`/api/account/view/${this.a1id}`)
            const p2 = api.get<AccountDto>(`/api/account/view/${this.a2id}`)
            Promise.all([p1, p2]).then(v => {
                this.a1 = v[0]
                this.a2 = v[1]
                this.createMergeTable()
            })
        }
        list: Item[] = []
        createMergeTable(): void {
            for (var p in this.a1) {
                if (this.fields2Ignore.indexOf(p) != -1) continue
                /* eslint-disable  @typescript-eslint/no-explicit-any */
                if (!(this.a1 as any)[p] && !(this.a2 as any)[p]) continue
                this.list.push({
                    name: p,
                    a2Selected: (this.a1 as any)[p] === null || (this.a2 as any)[p] === true
                })
            }
        }
        fields2Ignore = [
            'id',
            'filesNew',
            'files',
            'ownedObjectsReferenceNumbers',
            'rentedObjectsReferenceNumbers',
            'createdOn',
            'createdBy',
            'modifiedOn',
            'modifiedBy'
        ]

        public error: string | null = null

        merge(): void {
            if (!this.a2id) return;
            this.list.forEach((item) => {
                if (item.a2Selected)
                    /* eslint-disable  @typescript-eslint/no-explicit-any */
                    (this.a1 as any)[item.name] = (this.a2 as any)[item.name]
            })
            api.
                post<AccountMergeCommandInput>('/api/account/merge', {
                    account: this.a1,
                    duplicateId: this.a2id
                })
                .then(() => {
                    this.goBack()
                })
        }
        cancel(): void {
            this.goBack()
        }
        goBack(): void {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        }
    }

    interface AccountMergeCommandInput {
        account: AccountDto,
        duplicateId: number
    }

    interface Item {
        name: string
        a2Selected: boolean
    }

    export interface ICanBeSelected {
        selectedInListView: boolean
    }
</script>

<style scoped lang="scss"></style>
