import { Attachment } from '@/models/Transactions/Attachment'

export class AccountViewOutput {
  id?: number
  accountDisplayName?: string
  accountEmail?: string
  accountCreatedOn?: string
  balance?: number
  totalDebit?: number
  totalCredit?: number
  items: AccountViewOutputItem[] = []
  page?: number
  totalPages?: number
}

export class AccountViewOutputItem {
  id?: string
  referenceNumber?: string
  transactionDate?: string
  isDebit?: boolean
  debit?: number
  credit?: number
  balance?: number
  status?: string
  statusId?: number
  categoryName?: string
  confirmedByClientDate?: string
  expirationDate?: string
  startDate?: string
  endDate?: string
  paymentDate?: string
  paymentMethod?: string
  attachments?: Attachment[]

  public constructor(init?: Partial<AccountViewOutputItem>) {
    Object.assign(this, init)
  }
}
