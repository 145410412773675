export class OwnerListQueryOutput {
  public items: OwnerListQueryOutputItem[] = []
}

export class OwnerListQueryOutputItem {
  id?: number
  name?: string
  objects?: string
  public constructor(init?: Partial<OwnerListQueryOutputItem>) {
    Object.assign(this, init)
  }
}
