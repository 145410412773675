var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('gmap-map',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.mapCircleLatitude ? _vm.positionC : _vm.positionM,"zoom":_vm.zoomCalc}},[(_vm.latitude)?_c('gmap-marker',{attrs:{"position":_vm.positionM}}):_vm._e(),(_vm.mapCircleLatitude)?_c('gmap-circle',{attrs:{"center":_vm.positionC,"radius":_vm.mapCircleRadius,"options":{
        fillColor: 'blue',
        fillOpacity: 0.35,
        strokeColor: 'blue',
        strokeOpacity: 0.8,
        strokeWeight: 1
      }}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }