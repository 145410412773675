import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import router from '@/router'
import store from '@/store'

export const api = {
    configure,
    get,
    post,
    postExt,
    postFormData,
    postOut,
    del,
    delExt,
    patch,
    upload,
    download
};

configAxios()

function configAxios(): void {
    localizeServerOutput()
}
function localizeServerOutput(): void {
    sendCookiesOnRequest()
}
function sendCookiesOnRequest(): void {
    axios.defaults.withCredentials = true
}

function get<T>(url: string, params: any = null): Promise<T> {
    const cfg: AxiosRequestConfig = {
        params: params
    }
    return axios.get<T>(url, cfg)
        .then(response => {
            return response.data as T;
        })
}
function post<T>(url: string, m: T): Promise<T> {
    return axios.post<T>(url, m)
        .then(response => {
            return response.data as T;
        })
}

function postExt<TIn, TOut>(url: string, m: TIn): Promise<TOut> {
    return axios.post<TOut>(url, m)
        .then(response => {
            return response.data as TOut;
        })
}

function postOut<TOut>(url: string): Promise<TOut> {
    return axios.post<TOut>(url)
        .then(response => {
            return response.data as TOut;
        })
}

function postFormData<T>(url: string, m: T): Promise<T> {
    const formData = jsonToFormData(m)
    return axios.post<T>(url, formData)
        .then(response => {
            return response.data as T;
        })
}

function del(url: string): Promise<void> {
    return axios.delete(url);
}

function delExt(url: string, params: any): Promise<void> {
    const cfg: AxiosRequestConfig = {
        params: params
    }
    return axios.delete(url, cfg);
}

function patch<T>(
    entityName: string,
    entityId: string | number,
    fieldName: string,
    value: string | string[] | number | null
): Promise<T> {
    const patch = [
        {
            op: 'replace',
            path: `/${fieldName}`,
            value: value
        }
    ]

    return axios.patch<T>(`/api/${entityName}/patch/${entityId}`, patch)
        .then(response => {
            return response.data as T;
        })
}

function upload(url: string, formData: FormData) {
    return axios.post(url, formData)
}

function download(url: string, fileName: string): void {
    const cfg: AxiosRequestConfig = {
        responseType: 'blob'
    }
    axios.get(url, cfg)
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
}

function configure(): void {
    axios.interceptors.request.use(config => {
        const user = JSON.parse(localStorage.getItem('user') ?? '{}');
        if (user && user.token) {
            config.headers = {
                Authorization: `Bearer ${user.token}`
            };
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });
    axios.interceptors.response.use(config => {
        store.dispatch('alert/clear')
        return config;
    }, (error: AxiosError) => {
        if (error.response?.status === 401) {
            router.push('/login')
        }
        else if (error.response?.status === 400) {
            if (Array.isArray((error.response as AxiosResponse).data["UserManager"])) {
                const myMsg =
                    ((error.response as AxiosResponse).data["UserManager"] as Array<string>).join("<br>")
                store.dispatch('alert/error', myMsg, { root: true });
            }
            else {
                const errorTitle = (error.response.data as ServerError).detail
                store.dispatch('alert/error', errorTitle, { root: true });
            }
        }
        else if (error.response?.status === 500) {
            const errorTitle = (error.response.data as ServerError).title
            if (errorTitle == 'Access denied')
                store.dispatch('alert/warning', errorTitle, { root: true });
            else
                store.dispatch('alert/error', errorTitle, { root: true });
        }
        else {
            store.dispatch('alert/error', error, { root: true });
        }
        return Promise.reject(error);
    });
}
function jsonToFormData(data: any): FormData {
    const formData = new FormData()
    buildFormData(formData, data, '')
    return formData
}
function buildFormData(formData: FormData, data: any, parentKey: string): void {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        if (data != null) {
            formData.append(parentKey, data)
        }
    }
}

export interface ValidationError {

}
export interface ServerError {
    title: string
    detail: string
}

