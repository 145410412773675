<template>
    <div></div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import store from '@/store'
    import router from '../router';

    @Component
    export default class Home extends Vue {
        constructor() {
            super();
            const isAuthenticated = store.getters['authentication/isAuthenticated'] as boolean
            console.log(isAuthenticated)
            if (isAuthenticated)
                router.push('CRM');
            else
                router.push('Login');
        }
    }
</script>
