<template>
    <div class="container mt-3">
        <h3>{{ $t('Set Password') }}</h3>
        
        <b-alert v-if="error" show variant="danger"><pre>{{ error }}</pre></b-alert>
        <b-alert v-if="success" show variant="success">{{ $t('Password set successfully for ') + successEmail }}</b-alert>
        
        <b-form @submit.prevent="submit">
            <b-form-group :label="$t('Email')" label-for="email">
                <b-form-input
                    id="email"
                    v-model="form.email"
                    type="email"
                    required
                    :placeholder="$t('Enter email')"
                ></b-form-input>
            </b-form-group>

            <b-form-group :label="$t('Password')" label-for="password">
                <b-form-input
                    id="password"
                    v-model="form.password"
                    type="password"
                    required
                    :placeholder="$t('Enter password')"
                ></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary" :disabled="loading">
                {{ loading ? $t('Setting...') : $t('Set Password') }}
            </b-button>
            
            <b-button class="ml-2" @click="$router.back()" variant="secondary">
                {{ $t('Cancel') }}
            </b-button>
        </b-form>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { api } from '@/services/api'

interface SetPasswordForm {
    email: string;
    password: string;
}

interface ApiError {
    response?: {
        data?: {
            errors?: Record<string, string[]>;
        } & Record<string, any>;
    };
    message: string;
}

@Component
export default class SetPassword extends Vue {
    form: SetPasswordForm = {
        email: '',
        password: ''
    }

    error: string | null = null
    success = false
    loading = false
    successEmail = ''

    async submit(): Promise<void> {
        this.error = null
        this.success = false
        this.loading = true

        try {
            await api.post('/api/authenticate/setPassword', this.form)
            this.successEmail = this.form.email
            this.success = true
            this.form = {
                email: '',
                password: ''
            }
        } catch (err: unknown) {
            const error = err as ApiError
            if (error.response?.data && Array.isArray(error.response.data)) {
                this.error = error.response.data
                    .map(e => e.description)
                    .join('\n')
            } else if (error.response?.data?.errors) {
                this.error = Object.values(error.response.data.errors).join('\n')
            } else if (error.response?.data) {
                this.error = String(error.response.data)
            } else {
                this.error = error.message
            }
        } finally {
            this.loading = false
        }
    }
}
</script>

<style scoped>
.container {
    max-width: 500px;
}

pre {
    margin: 0;
    white-space: pre-line;
    font-family: inherit;
}
</style> 