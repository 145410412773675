<template>
    <div>
        <navbar :showBackButton="true" :header="$t('Leads')">
            <template v-slot:visibleButtons>
                <b-nav-text class="mr-2">
                    <toggle-button :width="25 + $tc('Edit').length * 6"
                                   :value="false"
                                   :labels="{ checked: $tc('Edit'), unchecked: $tc('Edit') }"
                                   v-on:change="viewModeChange()" />
                </b-nav-text>
                <b-nav-text v-if="viewMode != VIEW_MODE_VIEW">
                    <b-button class="mr-2" v-on:click="editModeChange()" size="sm">
                        {{ editModeLabel }}
                    </b-button>
                </b-nav-text>
            </template>
            <template v-slot:hiddenButtons>
                <b-dropdown-item v-on:click="deleteRequest()">
                    <font-awesome-icon size="lg" icon="trash" /> {{ $t('Delete lead') }}
                </b-dropdown-item>
            </template>
        </navbar>
        <div class="container-md">
            <div>
                <leadViewFields v-if="viewMode != VIEW_MODE_EDIT_EXPANDED"
                                v-on:referenceNumberReceived="referenceNumber = $event"
                                :view-mode="viewMode"></leadViewFields>
                <leadEditRequest v-else :id="id"></leadEditRequest>
            </div>
        </div>
        <b-modal id="deleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete lead?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import navbar from '@/components/Navbar.vue'
    import leadEditRequest from '@/components/Leads/LeadEditRequest.vue'
    import leadViewFields from '@/components/Leads/LeadViewFields.vue'
    import { VIEW_MODE_VIEW, VIEW_MODE_EDIT, VIEW_MODE_EDIT_EXPANDED } from '@/misc/viewConst'
    import { api } from '@/services/api'

    @Component({
        components: {
            navbar,
            leadViewFields,
            leadEditRequest
        }
    })
    export default class LeadView extends Vue {
        public id: string | null = null
        public referenceNumber = ''
        public buttons = ['edit']
        public viewMode = VIEW_MODE_VIEW

        // workaround for [Vue warn]: Property or method "VIEW_MODE_EDIT_EXPANDED" is not defined on the instance but referenced during render. Make sure that this property is reactive, either in the data option, or for class-based components, by initializing the property. See: https://vuejs.org/v2/guide/reactivity.html#Declaring-Reactive-Properties.
        public VIEW_MODE_VIEW = VIEW_MODE_VIEW
        public VIEW_MODE_EDIT = VIEW_MODE_EDIT
        public VIEW_MODE_EDIT_EXPANDED = VIEW_MODE_EDIT_EXPANDED

        created(): void {
            this.id = this.$route.params.id
            if (this.$route.params.viewMode) {
                this.viewMode = parseInt(this.$route.params.viewMode)
            }
        }

        viewModeChange(): void {
            this.viewMode = this.viewMode === VIEW_MODE_VIEW ? VIEW_MODE_EDIT : VIEW_MODE_VIEW
        }

        editModeChange(): void {
            this.viewMode =
                this.viewMode === VIEW_MODE_EDIT_EXPANDED ? VIEW_MODE_EDIT : VIEW_MODE_EDIT_EXPANDED
        }

        get editModeLabel(): string {
            return this.viewMode === VIEW_MODE_EDIT_EXPANDED ? this.$tc('Collapse') : this.$tc('More')
        }

        deleteRequest(): void {
            this.$bvModal.show('deleteConfirmation')
        }

        deleteConfirmed(): void {
            if (this.id === undefined) return
            api
                .del(`/api/lead/${this.id}`)
                .then(() => {
                    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
                })
        }
    }
</script>

<style scoped></style>
