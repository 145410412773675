export class ProfessionalListQueryOutput {
    public items: ProfessionalListQueryOutputItem[] = []
}

export class ProfessionalListQueryOutputItem {
    id?: number
    name?: string
    isAttorney?: boolean
    isNotary?: boolean
    isLawyer?: boolean
    isWorker?: boolean
    isElectrician?: boolean
    isConstructionEngineer?: boolean
    isCleaner?: boolean
    isInteriorDesigner?: boolean
    isTranslator?: boolean
    isAccountant?: boolean
    isEconomist?: boolean
    isLandscapeDesigner?: boolean
    isSecurityService?: boolean
    isPlumber?: boolean
    isLocksmith?: boolean
    isIT?: boolean
    isWebDesigner?: boolean
    isEnergySupplyCompany?: boolean | null
    isElectricalStore?: boolean | null
    isTechnician?: boolean | null
    isRealEstateAgencyOrBroker?: boolean | null
    numberOfSoldObjects?: number
    public constructor(init?: Partial<ProfessionalListQueryOutputItem>) {
        Object.assign(this, init)
    }
}
