<template>
    <div class="container-md">
        <h1>{{ $t('Action Log') }}</h1>
        <b-card title="Filters" style="background-color: lightyellow">
            <b-form>
                <b-form-row class="mb-2">
                    <b-col sm="2">
                        <label class="mr-sm-2" for="employeeFilterType">Action by:</label>
                    </b-col>
                    <b-col sm="6">
                        <field-account-search v-model="filters.actionBy" filter="2" :initialText="filters.actionByText" />
                    </b-col>
                </b-form-row>
                <b-form-row class="mb-2">
                    <b-col sm="2">
                        <label class="mr-sm-2" for="entity">Action over:</label>
                    </b-col>
                    <b-col sm="3">
                        <b-form-select v-model="filters.entity" id="entity">
                            <b-form-select-option :value="1">Account</b-form-select-option>
                            <b-form-select-option :value="2">Object</b-form-select-option>
                        </b-form-select>
                    </b-col>
                    <b-col sm="2">
                        <label class="mr-sm-2" for="entityId">ID or Ref#:</label>
                    </b-col>
                    <b-col sm="3">
                        <b-form-input v-model="filters.entityId" id="entityId"></b-form-input>
                    </b-col>
                </b-form-row>
                <b-form-row class="mb-2">
                    <b-col sm="2">
                        <label class="mr-sm-2" for="startDate">Date range</label>
                    </b-col>
                    <b-col sm="3">
                        <b-form-datepicker no-flip class="mr-2"
                                           id="startDate"
                                           reset-button
                                           v-model="filters.startDate"></b-form-datepicker>
                    </b-col>
                    <b-col sm="3">
                        <b-form-datepicker no-flip class="mr-2"
                                           id="endDate"
                                           reset-button
                                           v-model="filters.endDate"></b-form-datepicker>
                    </b-col>
                </b-form-row>
                <b-form-row class="mb-2">
                    <b-button variant="primary" v-on:click="applyFilters" class="mr-4">
                        {{
            $t('Apply Filters')
                        }}
                    </b-button>
                    <b-button variant="primary" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
                </b-form-row>
            </b-form>
        </b-card>
        <b-table striped hover :items="data.items" :fields="fields" primary-key="id">
            <template #cell(date)="data">
                {{ data.item.date | date_time_long }}
            </template>
            <template #cell(details)="row">
                <b-button size="sm" @click="row.toggleDetails" class="mr-2" v-if="row.item.message || row.item.data">
                    {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                </b-button>
            </template>

            <template #row-details="row">
                <b-card>
                    <b-row class="mb-2" v-if="row.item.message">
                        <b-col sm="2" class="text-sm-right"><b>Message:</b></b-col>
                        <b-col>{{ row.item.message }}</b-col>
                    </b-row>

                    <b-table :items="JSON.parse(row.item.data)" v-if="row.item.data"></b-table>
                </b-card>
            </template>
        </b-table>
        <b-pagination v-model="filters.currentPage"
                      :per-page="filters.perPage"
                      :total-rows="data.totalRows"
                      v-on:change="onCurrentPageChange"
                      aria-controls="my-table"></b-pagination>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { myutils } from '@/services/myutils'
    import FieldAccountSearch from '@/components/Shared/FieldAccountSearch.vue'
    import { EventBus } from '@/components/Shared/EventBus'

    @Component({ components: { FieldAccountSearch } })
    export default class ActionLog extends Vue {
        public data: ActionLogOutput = { totalRows: 0, items: [] }

        created() {
            EventBus.$on('FieldAccountSearch_DisplayedText_Changed', this.setactionByText)
            this.loadFilterFromCookies()
            this.fetchData()
        }

        fetchData(): void {
            api
                .postExt<ActionLogInput, ActionLogOutput>('/api/actionLog', this.filters)
                .then((data) => {
                    this.data = data
                })
        }
        fields = ['date', 'actionBy', 'action', 'entity', 'actionOver', 'details']

        filters: ActionLogInput = { actionBy: null, endDate: null, entity: 0, entityId: null, startDate: null, actionByText: null, perPage: 20, currentPage: 1 } as ActionLogInput

        loadFilterFromCookies(): void {
            const cookieFilter = this.$cookies.get('ActionLog.filters') as ActionLogInput
            if (!cookieFilter) return
            Object.assign(this.filters, cookieFilter)
        }

        applyFilters(): void {
            this.$cookies.set('ActionLog.filters', this.filters)
            this.fetchData()
        }
        clearFilters(): void {
            myutils.setPropertiesToNullOrEmptyArray(this.filters)
            this.filters.actionBy = null
            this.filters.actionByText = null
            this.filters.entity = 0
            this.filters.perPage = 20
            this.filters.currentPage = 1
            this.fetchData()
        }
        setactionByText(value: string) {
            this.filters.actionByText = value
        }
        onCurrentPageChange(): void {
            this.fetchData()
        }
    }

    interface ActionLogInput {
        actionBy: number | null
        actionByText: string | null
        entity: number
        entityId: string | null
        startDate: string | null
        endDate: string | null
        currentPage: number
        perPage: number
    }
    interface ActionLogView {
    }
    interface ActionLogOutput {
        items: ActionLogView[]
        totalRows: number
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.no-left-padding {
  padding-left: 0;
}
</style>
