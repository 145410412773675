export default class LeadDto {
  id?: string | null
  source?: string
  forPurchase?: boolean | null
  forRent?: boolean | null
  forShortRent?: boolean | null
  purchaseBudgetRangeMin?: number | null
  purchaseBudgetRangeMax?: number | null
  rentBudgetRangeMin?: number | null
  rentBudgetRangeMax?: number | null
  shortRentBudgetRangeMin?: number | null
  shortRentBudgetRangeMax?: number | null
  startDate?: Date | null
  endDate?: Date | null
  priority?: string
  stage?: string
  accountId?: number | null
  public constructor(init?: Partial<LeadDto>) {
    Object.assign(this, init)
  }
}
