<template>
    <div class="container-md mt-2">
        <h1>{{ $t('Deal') }} <span v-if="loaded && id">#{{id}}</span></h1>
        <div id="loadingSpinner" v-if="!loaded" class="d-flex">
            <b-spinner small label="Busy"></b-spinner><span class="ml-1">{{ $t('Loading...') }}</span>
        </div>
        <b-form v-if="loaded">
            <b-form-row>
                <b-col class="col-4">
                    <b-form-group label-for="dealType" v-bind:label="$t('Type')">
                        <b-form-select v-model="data.dealType"
                                       :options="data.dealTypeOptions"
                                       :disabled="mode == MODE_VIEW"
                                       id="dealType"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col class="col-4">
                    <b-form-group label-for="dealStage" v-bind:label="$t('Stage')">
                        <b-form-select v-model="data.dealStage"
                                       :options="data.dealStageOptions"
                                       :disabled="mode == MODE_VIEW"
                                       id="dealStage"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <b-form-group label-for="dealType" v-bind:label="$t('Object')">
                        <field-object-search v-if="mode != MODE_VIEW" v-model="data.objectId" :initialText="data.objectReferenceNumber" />
                        <router-link v-if="mode == MODE_VIEW && data.objectReferenceNumber" :to="{ name: 'Objects/View', params: { id: data.objectId } }">
                            {{ data.objectReferenceNumber }}
                        </router-link>
                        <span v-if="mode == MODE_VIEW && !data.objectReferenceNumber">
                            {{ $t('not set') }}
                        </span>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-8">
                    <b-table hover :items="data.accounts" :fields="accountsFields" foot-clone>
                        <template #cell(action)="row">
                            <b-button v-if="mode != MODE_VIEW" variant="outline-danger" size="sm" @click="deleteRequestAccount(row.item, row.index)">
                                <font-awesome-icon icon="trash" />
                            </b-button>
                        </template>
                        <template #foot(account)>
                            <b-button v-if="mode != MODE_VIEW" variant="primary" @click="addContactRow()">{{ $t('Add contact') }}</b-button>
                            {{''}}
                        </template>
                        <template #foot()>
                            {{''}}
                        </template>
                        <template #cell(account)="row">
                            <field-account-search v-if="mode != MODE_VIEW" v-model="row.item.accountId" :initialText="row.item.account" />
                            <router-link v-if="mode == MODE_VIEW" :to="{ name: 'Accounts/View', params: { id: row.item.accountId } }">
                                {{ row.item.account }}
                            </router-link>
                        </template>
                        <template #cell(relation)="row">
                            <b-form-select v-model="row.item.relation"
                                           :options="data.dealAccountRelationOptions"
                                           :disabled="mode == MODE_VIEW"></b-form-select>
                        </template>
                    </b-table>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <label for="startDate">{{ $t('Start date') }}</label>
                    <b-form-datepicker no-flip id="startDate"
                                       v-model="data.startDate"
                                       :readonly="mode == MODE_VIEW"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col class="col-4">
                    <label for="endDate">{{ $t('End date') }}</label>
                    <b-form-datepicker no-flip id="endDate"
                                       v-model="data.endDate"
                                       :readonly="mode == MODE_VIEW"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <label for="askingPrice">{{ $t('Asking price') }}</label>
                    <b-form-input v-model.number="data.askingPrice" class="mb-2"
                                  id="askingPrice"
                                  :readonly="mode == MODE_VIEW"
                                  type="number"></b-form-input>
                </b-col>
                <b-col class="col-4">
                    <label for="price">{{ $t('Final Price') }}</label>
                    <b-form-input v-model.number="data.price"
                                  id="price"
                                  :readonly="mode == MODE_VIEW"
                                  type="number"></b-form-input>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <label for="dateOfDeposit">{{ $t('Date of deposit') }}</label>
                    <b-form-datepicker no-flip id="dateOfDeposit"
                                       v-model="data.dateOfDeposit"
                                       :readonly="mode == MODE_VIEW"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col class="col-4">
                    <label for="contractDurationMonths">{{ $t('Contract duration (months, only for sales)') }}</label>
                    <b-form-input v-model.number="data.contractDurationMonths"
                                  id="contractDurationMonths"
                                  :readonly="mode == MODE_VIEW"
                                  type="number"></b-form-input>
                </b-col>
            </b-form-row>
            <b-form-row v-if="data.dealType==1">
                <b-col class="col-4">
                    <label for="rentStart">{{ $t('Rent start') }}</label>
                    <b-form-datepicker no-flip id="rentStart"
                                       v-model="data.rentStart"
                                       :readonly="mode == MODE_VIEW"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col class="col-4">
                    <label for="rentExpiration">{{ $t('Rent expiration') }}</label>
                    <b-form-datepicker no-flip id="rentExpiration"
                                       v-model="data.rentExpiration"
                                       :readonly="mode == MODE_VIEW"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-8">
                    <label for="demand">{{ $t('Original lead') }}</label>
                    <field-lead-search v-if="mode != MODE_VIEW" v-model="data.demandId" :initialText="data.demandId" />
                    <router-link v-if="mode == MODE_VIEW && data.demandId" :to="{ name: 'Leads/View', params: { id: data.demandId } }">
                        {{ $t('Link') }}
                    </router-link>
                    <span v-if="mode == MODE_VIEW && !data.demandId">
                        {{ $t('not set') }}
                    </span>
                </b-col>
            </b-form-row>
            <b-form-row class="mt-4" v-if="mode != MODE_CREATE">
                {{ $t('Created') }}: {{ data.createdOn | date }} by {{ data.createdByAccountDisplayName }} |
                {{ $t('Modified') }}: {{ data.modifiedOn | date }} {{ data.modifiedByAccountDisplayName }}
            </b-form-row>
            <b-form-row class="mt-2">
                <b-button v-if="mode != MODE_VIEW" v-on:click="save" variant="success" class="mr-2">{{$t('Save')}}</b-button>
                <b-button v-if="mode != MODE_VIEW" v-on:click="back" class="mr-2">{{$t('Cancel')}}</b-button>
                <b-button v-if="mode == MODE_EDIT" v-on:click="deleteRequest" variant="danger">{{$t('Delete')}}</b-button>
                <b-button v-if="mode == MODE_VIEW" v-on:click="edit" variant="success" class="mr-2">{{$t('Edit')}}</b-button>
                <b-button v-if="mode == MODE_VIEW" v-on:click="back" class="mr-2">{{$t('Back')}}</b-button>
            </b-form-row>
            <div v-if="mode == MODE_VIEW" class="mt-4">
                <h1>{{ $t('Activities of the Deal') }}</h1>
                <ActivityList2 :parentEntity="entityType" :parentId="id"></ActivityList2>
            </div>
        </b-form>
        <b-modal id="deleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">{{$t('Delete the deal?')}}</p>
        </b-modal>
        <b-modal id="deleteAccountLinkConfirmation" title="Please confirm" @ok="deleteAccountConfirmed">
            <p class="my-4">{{$t('Delete account link to the deal?')}}</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Mixins } from 'vue-property-decorator'
    import BaseComponent from '@/components/Shared/BaseComponent.vue'
    import { Route } from 'vue-router'
    import { api } from '@/services/api'
    import FieldAccountSearch from '@/components/Shared/FieldAccountSearch.vue'
    import FieldObjectSearch from '@/components/Shared/FieldObjectSearch.vue'
    import FieldLeadSearch from '@/components/Shared/FieldLeadSearch.vue'
    import ActivityList2 from './../../components/Activity/ActivityList2.vue'
    import { EntityType } from '../../models/Enums'

    @Component({
        components: {
            ActivityList2,
            FieldAccountSearch,
            FieldObjectSearch,
            FieldLeadSearch
        }
    })
    export default class DealEdit extends Mixins(BaseComponent) {
        id: number | null = null
        data: Deal | null = null
        loaded = false

        readonly MODE_UNDEFINED = 0
        readonly MODE_CREATE = 1
        readonly MODE_EDIT = 2
        readonly MODE_VIEW = 3
        private mode = this.MODE_UNDEFINED

        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            this.id = Number(r.params.id)
            if (this.id) this.mode = this.MODE_VIEW
            var result =
                this.id
                    ? api.get<Deal>(`/api/Deal/edit/${this.id}`)
                    : api.get<Deal>('/api/Deal/add')

            result
                .then((data) => {
                    this.data = data
                    this.loaded = true
                    if (this.data?.accounts) {
                        this.data.accounts.sort((a, b) => a.relation > b.relation ? 1 : -1)
                    }
                })
        }
        save(): void {
            this.removeEmptyAccount()
            api.post<Deal>('/api/Deal/update', this.data as Deal)
                .then(() => { this.back() })
        }
        deleteRequest(): void {
            this.$bvModal.show('deleteConfirmation')
        }
        deleteConfirmed(): void {
            if (this.mode != this.MODE_EDIT) return

            api.del(`/api/Deal/${this.id}`)
                .then(() => {
                    this.back()
                })
        }
        get demandDecription(): string {
            return 'not defined'
        }

        accountsFields = [
            { key: 'account', label: this.$t('Account') },
            { key: 'relation', label: this.$t('Relation') },
            { key: 'action', label: '' }]

        addContactRow(): void {
            if (!this.data) return
            if (!this.data.accounts)
                this.data.accounts = []
            this.data.accounts.push({ relation: 0 } as DealAccount)
        }

        deleteIndex?: number
        deleteItem?: DealAccount
        deleteRequestAccount(item: DealAccount, index: number) {
            this.deleteItem = item
            this.deleteIndex = index
            this.$bvModal.show('deleteAccountLinkConfirmation')
        }

        deleteAccountConfirmed() {
            if (
                this.deleteIndex === undefined ||
                this.deleteItem === undefined ||
                this.deleteItem.accountId === undefined ||
                this.deleteItem.accountId === null
            ) {
                return
            }
            if (!this.data) return
            if (!this.data.accounts) return
            this.data.accounts.splice(this.deleteIndex, 1)
        }

        removeEmptyAccount() {
            if (!this.data?.accounts) return;
            for (var i = this.data.accounts.length - 1; i >= 0; --i) {
                if (!this.data.accounts[i].accountId) {
                    this.data.accounts.splice(i, 1);
                }
            }
        }
        edit(): void {
            this.mode = this.MODE_EDIT
        }
        view(): void {
            this.mode = this.MODE_VIEW
        }
        activityViewMode = 0
        entityType = EntityType.Deal
    }
    interface Deal {
        id?: number | null
        startDate?: Date | null
        endDate?: Date | null
        accounts: DealAccount[] | null

    }
    interface DealAccount {
        accountId: number
        account: string
        relation: number
    }
</script>

<style scoped></style>
