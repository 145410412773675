<template>
    <div>
        <b-form-textarea v-model="model.description"
                         id="description"
                         rows="3"
                         max-rows="16"
                         class="mt-2 mb-2"></b-form-textarea>
        <b-form inline class="mb-2">
            <label class="mr-2">Action:</label>
            <b-form-select v-model="model.done" :options="doneOptions" class="mr-2"></b-form-select>
            <label class="mr-2">Planned on:</label>
            <b-form-datepicker no-flip class="mr-2"
                               id="activityStartDate"
                               reset-button
                               v-model="model.date"></b-form-datepicker>
            <label class="mr-2">Assigned to: </label>
            <field-account-search v-model="assignedToId" :initialText="assignedToDescr" filter="1" />
        </b-form>
        <b-form inline class="mb-2">
            <!--<b-form-select v-model="type" :options="types" class="mr-2"></b-form-select>-->
            <label>Links:</label>
            <span v-for="(link, index) in model.links"
                  v-bind:key="link.id"
                  class="border border-secondary pl-1 rounded ml-1 mr-1">
                <span v-if="link.entity != parent && link.id != parentId">
                    {{ link.description }}
                    <b-button class="btn btn-light btn-sm" aria-label="Close" v-on:click="onLinkDelete(index)">
                        <span aria-hidden="true">&times;</span>
                    </b-button>
                </span>
            </span>
            <FieldSearch v-on:hit="onLinkAdd" v-bind:clearOnHit="true" placeholder="search to add new link" class="mr-2"></FieldSearch>
        </b-form>
        <div v-if="!id">
            <file-pond name="activityFiles"
                       ref="pond"
                       label-idle="Drop files here..."
                       v-bind:allow-multiple="true"
                       accepted-file-types="image/*, video/*, audio/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/rtf, application/vnd.visio, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xml, application/zip, application/x-7z-compressed"
                       server="/api/files"
                       v-bind:files="files"
                       v-on:processfile="fileAdded" />
        </div>
        <div v-if="id">
            <fileLinks :items="model.files"
                       :viewMode="1"
                       parent="activity"
                       :parentId="model.id"></fileLinks>
        </div>
        <b-button v-on:click="save" variant="success" class="mr-2">{{$t('Save')}}</b-button>
        <b-button v-on:click="cancel" class="mr-2">{{$t('Cancel')}}</b-button>
        <b-modal id="linkDeleteConfirmation" title="Please confirm" @ok="linkDeleteConfirmed">
            <p class="my-4">Delete a link?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { ActivityDto2, ActivityLinkDto } from '@/models/ActivityDto2'
    import FieldSearch from '../../components/Shared/FieldSearch.vue'
    import FileLinks from './../Shared/FileLinks.vue'
    import vueFilePond from 'vue-filepond'
    import 'filepond/dist/filepond.min.css'
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)
    import {
        SearchOutputItem,
        toEntityType,
        SearchQueryOutputItemType
    } from '../../models/SearchOutput'
    import { EntityType } from '@/models/Enums'
    import FieldAccountSearch from '../Shared/FieldAccountSearch.vue'
    import { EventBus } from '../Shared/EventBus'

    @Component({ components: { FilePond, FieldSearch, FileLinks, FieldAccountSearch } })
    export default class ActvitiyEdit extends Vue {
        @Prop() id!: number
        @Prop() parent!: EntityType | null
        @Prop() parentId!: string | number

        model: ActivityDto2 = { done: null}

        created() {
            this.initModel()
        }

        isAddMode(): boolean {
            return typeof this.id === 'undefined'
        }

        initModel() {
            if (this.isAddMode()) {
                this.addDefaultLink()
                return
            }
            api.get<ActivityDto2>(`/api/activity/${this.id}`)
                .then((data: ActivityDto2) => {
                    this.model = data
                })
        }

        save() {
            api.post('/api/activity/update', this.model)
                .then(() =>
                    EventBus.$emit('ActivityEditSaved'))
        }

        cancel(): void {
            EventBus.$emit('ActivityEditCancelled')
        }
        public types = ['category undefined', 'call', 'meeting', 'viewing']
        public type = 'category undefined'

        get files(): any[] | null {
            return this.model?.files == null
                ? null
                : this.model.files.map(function (fileLink) {
                    return { options: { type: 'limbo' }, source: fileLink.systemName }
                })
        }

        fileAdded(error: string, file: any) {
            if (error) return
            if (
                !this.model?.files ||
                !this.model.files.find(function (element) {
                    if (element.systemName == file.serverId) return true
                })
            ) {
                this.model.addedFiles =
                    this.model.addedFiles == null ? file.serverId : this.model.addedFiles + ',' + file.serverId
            }
        }

        addDefaultLink() {
            if (!this.model?.links) this.model.links = []
            const link = {} as ActivityLinkDto
            link.entity = this.parent
            link.id = this.parentId
            this.model.links.push(link)
        }

        onLinkAdd(searchedItem: SearchOutputItem) {
            if (!this.model?.links) this.model.links = []
            const link = {} as ActivityLinkDto
            link.entity = toEntityType(searchedItem.entity as SearchQueryOutputItemType)
            link.id = searchedItem.id
            link.description = searchedItem.description
            this.model.links.push(link)
        }

        link2DeleteIndex: number | null = null

        onLinkDelete(index: number) {
            this.link2DeleteIndex = index
            this.$bvModal.show('linkDeleteConfirmation')
        }

        linkDeleteConfirmed() {
            if (!this.link2DeleteIndex || !this.model.links) {
                //this.error = 'Cannot delete the only link'
                return
            }
            this.model.links.splice(this.link2DeleteIndex, 1)
        }

        doneOptions = [
            { value: null, text: 'not an action' },
            { value: false, text: 'to do' },
            { value: true, text: 'complete' }
        ]

        get assignedToDescr() { return this.model.assignedTo?.description  }
        get assignedToId() { return this.model.assignedTo?.id }
        set assignedToId(value) {
            if (this.model.assignedTo == null)
                this.model.assignedTo = {} as ActivityLinkDto
            this.model.assignedTo.id = value
        }
    }
</script>

<style scoped lang="scss"></style>
