<template>
    <tr v-if="value && value != 'Undefined'">
        <th>{{ label }}</th>
        <td>
            <field name="name"
                   :type="type"
                   :value="value"
                   :edit="edit"
                   v-on:click="click"
                   :suffix="suffix" />
        </td>
    </tr>
</template>

<script lang="ts">
    import field from './../Shared/Field.vue'
    import { Component, Vue, Prop } from 'vue-property-decorator'

    @Component({
        components: {
            field
        }
    })
    export default class FieldRow extends Vue {
        @Prop(String) readonly name!: string
        @Prop(String) readonly type!: string
        @Prop([String, Number, Boolean]) readonly value!: string | number | boolean
        @Prop(Boolean) readonly edit!: boolean
        @Prop(String) readonly suffix!: string

        get label(): string {
            return this.$t(this.name).toString()
        }
        click(): void {
            if (this.edit) this.$emit('click', this.name, this.type)
        }
    }
</script>
