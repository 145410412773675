import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import routes from './routes'
import routesPayments from './routesPayments'
import store from '@/store'

Vue.use(VueRouter)

const localhostIsPayments = 0

function routeSelector(): RouteConfig[] {
    const host = window.location.host

    if (host === 'crm.trustate.ru') return routes
    if (host === 'trustatepayments.com') return routesPayments

    return localhostIsPayments ? routesPayments : routes
}

const router = new VueRouter({
    mode: 'history',
    routes: routeSelector()
})

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
    if (requiresAuth) {
        const isAuthenticated = store.getters['authentication/isAuthenticated'] as boolean
        if (isAuthenticated) next()
        else router.push('/login')
    } else {
        next()
    }
})

export default router
