<template>
  <span
    v-bind:class="{ dottedUnderline: edit }"
    v-on:click="click"
    v-html="myValue"
    class="mr-1"
  ></span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class FieldRangeCurrency extends Vue {
  @Prop(String) name?: string
  @Prop(Number) min?: number
  @Prop(Number) max?: number
  @Prop(Boolean) edit?: boolean

  get myValue(): string {
    const v: any = Vue
    if (this.min && this.max)
      return `${v.options.filters.currency(this.min)} - ${v.options.filters.currency(this.max)}`
    else if (this.min) return `${this.$t('from')} ${v.options.filters.currency(this.min)}`
    else if (this.max) return `${this.$t('up to')} ${v.options.filters.currency(this.max)}`
    return ''
  }

  click() {
    if (this.edit) this.$emit('click', this.name, 'rangeCurrency')
  }
}
</script>

<style scoped>
.dottedUnderline {
  border-bottom: 1px dotted;
  cursor: pointer;
}
</style>
