<template>
  <div class="container-fluid" v-if="data">
    <div class="row justify-content-between">
      <div class="col-4">
        <h1 :class="isCurrentYear ? 'red' : ''">{{ $t('Reservations') }} {{ isCurrentYear ? (new Date().getFullYear()) : '' }}</h1>
      </div>
      <div class="col-4 text-right">
        <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{data.items.length}}</b-badge>
      </div>
    </div>
    <b-form class="mb-4">
      <b-form-row>
        <b-button variant="primary" to="/Reservations/Add" class="mr-4">{{ $t('Add new reservation') }}</b-button>
        <b-button :variant="hasFilters ? 'success' : 'primary'" v-b-toggle.collapse-3 class="mr-4">
          {{
        $t('Show / Hide Filters')
          }}
        </b-button>
        <b-button :variant="isCurrentYear ? 'success' : 'primary'" @click="clickCurrentYear" class="mr-4">{{ $t('Current Year') }}</b-button>
      </b-form-row>
    </b-form>
    <b-collapse id="collapse-3">
      <b-card title="Filters" style="background-color: lightyellow">
        <b-form>
          <b-form-row class="mb-2">
            <b-col sm="1">
              <label class="mr-sm-2" for="reservationNumber">Reservation Number:</label>
            </b-col>
            <b-col sm="1">
              <b-form-input class="mr-3"
                            id="reservationNumber"
                            v-model="filters.reservationNumber"></b-form-input>
            </b-col>
            <b-col sm="1">
              <label class="mr-sm-2" for="reservationNumberExternal">Source Reservation Number:</label>
            </b-col>
            <b-col sm="1">
              <b-form-input class="mr-3"
                            id="reservationNumberExternal"
                            v-model="filters.reservationNumberExternal"></b-form-input>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-col sm="1">
              <label class="mr-sm-2" for="objectWebsiteCode">Object reference number or title:</label>
            </b-col>
            <b-col sm="2">
              <field-object-search v-model="filters.objectId" options="STR" />
            </b-col>
            <b-col sm="1">
              <label class="mr-sm-2" for="objectWebsiteCode">Object website code:</label>
            </b-col>
            <b-col sm="1">
              <b-form-input class="mr-3"
                            id="objectWebsiteCode"
                            v-model="filters.objectWebsiteCode"></b-form-input>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-col sm="1">
              <label class="mr-sm-2" for="budgetMin">Stage</label>
            </b-col>
            <b-col sm="10">
              <multiselect v-model="filters.stages"
                           :options="data.options.stages"
                           label="text"
                           track-by="value"
                           :multiple="true"
                           :close-on-select="false"
                           :clear-on-select="false"
                           :preserve-search="true"
                           placeholder="All stages"></multiselect>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-col sm="1">
              <label class="mr-sm-2" for="startDate">Date range</label>
            </b-col>
            <b-col sm="3">
              <b-form-select v-model="filters.dateFilterType">
                <b-form-select-option value="0">by date of booking</b-form-select-option>
                <b-form-select-option value="1">by date of check in</b-form-select-option>
                <b-form-select-option value="2">by date of check out</b-form-select-option>
                <b-form-select-option value="3">by date of stay</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col sm="3">
              <b-form-datepicker no-flip class="mr-2"
                                 id="startDate"
                                 reset-button
                                 v-model="filters.startDate"></b-form-datepicker>
            </b-col>
            <b-col sm="3">
              <b-form-datepicker no-flip class="mr-2"
                                 id="endDate"
                                 reset-button
                                 v-model="filters.endDate"></b-form-datepicker>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-col sm="1">
              <label class="mr-sm-2" for="budgetMin">Sources</label>
            </b-col>
            <b-col sm="11">
              <multiselect v-model="filters.sources"
                           :options="data.options.sources"
                           label="text"
                           track-by="value"
                           :multiple="true"
                           :close-on-select="false"
                           :clear-on-select="false"
                           :preserve-search="true"
                           placeholder="All sources"></multiselect>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-col sm="1">
              <label class="mr-sm-2" for="clientName">Client</label>
            </b-col>
            <b-col sm="3">
              <b-form-input class="mr-3"
                            id="clientName"
                            v-model="filters.clientName"></b-form-input>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-button variant="primary" v-on:click="applyFilters" class="mr-4">
              {{
            $t('Apply Filters')
              }}
            </b-button>
            <b-button variant="primary" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
          </b-form-row>
        </b-form>
      </b-card>

    </b-collapse>
    <b-table :items="data.items" :fields="fields" primary-key="id" :tbody-tr-class="rowClass" :foot-clone="true"
             sticky-header="70vh"
             :per-page="perPage"
             :current-page="currentPage">
      <template #cell(reservationNumber)="data">
        <router-link :to="{ name: 'Reservations/Edit', params: { id: data.item.id } }">
          {{ data.item.reservationNumber }}
        </router-link>
        <b-icon-exclamation-octagon v-if="data.item.problem || data.item.awaiting"></b-icon-exclamation-octagon>
        <span v-if="data.item.problem">Problem</span>
        <span v-if="data.item.problem && data.item.awaiting">, </span>
        <span v-if="data.item.awaiting">Awaiting</span>

      </template>
      <template #cell(objectReferenceNumber)="data">
        <router-link :to="{ name: 'Objects/View', params: { id: data.item.objectId } }">
          {{ data.item.objectReferenceNumber }}
        </router-link>
      </template>
      <template #cell(externalLink)="data">
        <a v-if="data.item.externalLink" :href="data.item.externalLink" target=�_blank�>link</a>
      </template>
      <template #cell(bookingDate)="data">
        {{ data.item.bookingDate | date_short }}
      </template>
      <template #cell(checkIn)="data">
        {{ data.item.checkIn | date_short }}
      </template>
      <template #cell(checkOut)="data">
        {{ data.item.checkOut | date_short }}
      </template>
      <template #cell(clientDisplayName)="data">
        <router-link :to="{ name: 'Leads/View', params: { id: data.item.demandId } }">
          {{ data.item.clientDisplayName }}
        </router-link>
      </template>
      <template #cell(total)="data">
        <span :class="{ 'bold-text': data.item.totalDone }">{{ data.item.total | my_currency }}</span>
      </template>
      <template #cell(paymentToOwner)="data">
        {{ data.item.paymentToOwner | my_currency }}
      </template>
      <template #cell(comission)="data">
        {{ data.item.comission | my_currency }}
      </template>
      <template #cell(comission3rdParty)="data">
        {{ data.item.comission3rdParty | my_currency }}
      </template>
      <template #cell(finalComission)="data">
        {{ data.item.finalComission | my_currency }}
      </template>
      <template #cell(action)="data">
        <b-button :to="'/Reservations/'+data.item.id" variant="outline-primary" size="sm" class="mb-2">
          <font-awesome-icon icon="edit" />
        </b-button>
      </template>
      <template #foot(total)="data">
        <span>{{ sumOfTotal(data) | my_currency }}</span>
      </template>
      <template #foot(paymentToOwner)="data">
        {{ sumOfPaymentToOwner(data) | my_currency }}
      </template>
      <template #foot(comission)="data">
        {{ sumOfComission(data) | my_currency }}
      </template>
      <template #foot(comission3rdParty)="data">
        {{ sumOfComission3rdParty(data) | my_currency }}
      </template>
      <template #foot(finalComission)="data">
        {{ sumOfFinalComission(data) | my_currency }}
      </template>
      <template #foot()="data">
        <i>{{ data.label ? '' : data.label }}</i>
      </template>
    </b-table>
    <b-row>
      <b-col sm="2" md="3" class="my-1">
        <b-form-group label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0">
          <b-form-select id="per-page-select"
                         v-model="perPage"
                         :options="pageOptions"
                         size="sm" v-on:input="onPerPageChanged"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import Multiselect from 'vue-multiselect'
  import { api } from '@/services/api'
  import { ISelectListItem } from '@/models/ISelectListItem'
  import { myutils } from '@/services/myutils'
  import FieldObjectSearch from '@/components/Shared/FieldObjectSearch.vue'
  import { EventBus } from '@/components/Shared/EventBus'

  @Component({
    components: {
      FieldObjectSearch,
      Multiselect
    }
  })
  export default class ReservationList extends Vue {
    data: IData | null = null
    filters: IFilters = {
      objectId: null,
      objectWebsiteCode: null,
      reservationNumber: null,
      reservationNumberExternal: null,
      dateFilterType: 0,
      startDate: null,
      endDate: null,
      sources: [],
      stages: [],
      clientName: null,
    }

    get isCurrentYear(): boolean {
      if(!this.filters.startDate || !this.filters.endDate) return false;
      const currentYear = new Date().getFullYear();

      return this.filters.dateFilterType == 2 &&
        this.filters.startDate == `${currentYear}-01-01` &&
        this.filters.endDate == `${currentYear}-12-31`;
    }

    clickCurrentYear() {
      if (this.isCurrentYear) 
        this.clearCurrentYear()
      else 
        this.setCurrentYear()
    }

    clearCurrentYear() {
      this.filters.startDate = null;
      this.filters.endDate = null;
      this.filters.dateFilterType = 0;
      this.applyFilters();
    }
    setCurrentYear() {
      const currentYear = new Date().getFullYear();
      this.filters.startDate = `${currentYear}-01-01`;
      this.filters.endDate = `${currentYear}-12-31`;
      this.filters.dateFilterType = 2;
      this.applyFilters();
    }


    totalRows = 1
    currentPage = 1
    perPage = 10
    pageOptions = [5, 10, 20, 50, { value: 1000000, text: "Show all" }]

    created() {
      this.loadFilterFromCookies()
      this.fetchData()
      this.perPage = this.$cookies.get('ReservationList.perPage') as number ?? 20
    }

    loadFilterFromCookies(): void {
      const cookieFilter = this.$cookies.get('ReservationList.filters') as IFilters
      Object.assign(this.filters, cookieFilter)
      if (!this.hasFilters) {
        this.setCurrentYear()
      }
    }

    fields = [
      'objectTitle',
      { key: 'objectReferenceNumber', label: 'Object Reference #' },
      { key: 'reservationNumber', label: 'Reservation Number' },
      { key: 'reservationNumberExternal', label: 'Source Reservation Number' },
      'objectWebsiteCode',
      'bookingDate', 'checkIn', 'checkOut', 'duration', 'adults', 'children',
      { key: 'total', label: 'Total Amount', tdClass: 'text-right no-wrap', thClass: 'text-right no-wrap' },
      { key: 'paymentToOwner', label: 'To owner', tdClass: 'text-right no-wrap', thClass: 'text-right no-wrap' },
      { key: 'comission', label: 'Comission', tdClass: 'text-right no-wrap', thClass: 'text-right no-wrap' },
      { key: 'comission3rdParty', label: 'Comission 3rd party', tdClass: 'text-right no-wrap', thClass: 'text-right no-wrap' },
      { key: 'finalComission', label: 'Final comission', tdClass: 'text-right no-wrap', thClass: 'text-right no-wrap' },
      { key: 'clientDisplayName', label: 'Client' },
      'externalLink',
      { key: 'sourceDisplayName', label: 'Source' },
      { key: 'action', label: '' }
    ]

    fetchData(): void {
      api
        .postExt<IFilters | null, IData>('/api/Reservation/search', this.filters)
        .then((data) => {
          this.data = data
          this.totalRows = data.items.length
        })
    }

    get hasFilters(): boolean {
      if (this.isCurrentYear)
        return false;
      return this.filters.objectWebsiteCode != null ||
        this.filters.reservationNumber != null ||
        this.filters.reservationNumberExternal != null ||
        this.filters.dateFilterType != 0 ||
        this.filters.startDate != null ||
        this.filters.endDate != null ||
        this.filters.sources.length > 0 ||
        this.filters.stages.length > 0 ||
        this.filters.clientName != null ||
        this.filters.objectId != null
    }

    get reservationTypeOptions(): ISelectListItem[] {
      return [{ text: 'All types of reservations', value: null } as ISelectListItem, ...this.data?.options.types ?? []]
    }

    applyFilters(): void {
      this.$cookies.set('ReservationList.filters', this.filters)
      this.fetchData()
    }
    clearFilters(): void {
      myutils.setPropertiesToNullOrEmptyArray(this.filters)
      EventBus.$emit('FieldObjectSearch_reset')
      this.filters.dateFilterType = 0
      this.applyFilters()
    }

    rowClass(item: IDataItem) { return 'stage' + item.reservationStage?.replaceAll(' ', '').toLowerCase() }

    sumOfTotal(data: any) {
      if (!data) return
      return this.data?.items.reduce((a: any, b: any) => a + b.total, 0)
    }
    sumOfPaymentToOwner(data: any) {
      if (!data) return
      return this.data?.items.reduce((a: any, b: any) => a + b.paymentToOwner, 0)
    }
    sumOfComission(data: any) {
      if (!data) return
      return this.data?.items.reduce((a: any, b: any) => a + b.comission, 0)
    }
    sumOfComission3rdParty(data: any) {
      if (!data) return
      return this.data?.items.reduce((a: any, b: any) => a + b.comission3rdParty, 0)
    }
    sumOfFinalComission(data: any) {
      if (!data) return
      return this.data?.items.reduce((a: any, b: any) => a + b.finalComission, 0)
    }
    onPerPageChanged(): void {
      this.$cookies.set('ReservationList.perPage', this.perPage)
    }
  }
  interface IData {
    items: IDataItem[],
    options: IFiltersOptions
  }
  interface IDataItem {
    id: number
    reservationStage: string | null
    objectId?: number | null
    objectReferenceNumber: string | null
    objectTitle: string | null
    reservationNumber: string | null
    objectWebsiteCode: string | null
    bookingDate: DateOnly | null
    checkIn: DateOnly | null
    checkOut: DateOnly | null
    duration: number | null
    adults: number | null
    children: number | null
    total: number | null
    paymentToOwner: number | null
    comission: number | null
    comission3rdParty: number | null
    finalComission: number | null
    clientId: number | null
    clientDisplayName: string | null
    externalLink: string | null
    sourceId: number | null
    sourceDisplayName: string | null
    modifiedBy: number | null
    modifiedByAccountDisplayName: string | null
    modifiedOn: string | null
    problem: boolean
    awaiting: boolean
    prepaymentCashDone: boolean
    prepaymentBankDone: boolean
    restCashDone: boolean
    restBankDone: boolean
    totalDone: boolean
    demandId: string | null
  }

  type DateOnly = string | null; // Assuming DateOnly is a string representation of a date
  type ReservationStage = any; // Replace 'any' with the actual type for ReservationStage

  interface IFilters {
    objectWebsiteCode: string | null;
    reservationNumber: string | null;
    reservationNumberExternal: string | null;
    dateFilterType: number
    startDate: DateOnly;
    endDate: DateOnly;
    sources: ISelectListItem[]
    stages: ReservationStage[];
    clientName: string | null;
    objectId: number | null;
  }
  interface IFiltersOptions {
    types: ISelectListItem[]
    stages: ISelectListItem[]
    sources: ISelectListItem[]
  }
</script>
<style scoped>
  .bold-text {
    font-weight: bold;
  }
  .red{
    color: red;
  }
</style>
