<template>
    <div>
        <div class="text-center" v-if="!data">
            <b-spinner variant="primary" label="Text Centered"></b-spinner>
        </div>
        <div class="container-fluid" v-if="data">
            <div class="row justify-content-between">
                <div class="col-4">
                    <h1>{{ $t('Leads') }}</h1>
                </div>
                <div class="col-4 text-right">
                    <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{data.totalRows}}</b-badge>
                </div>
            </div>
            <b-form inline class="mb-4">
                <b-button variant="primary" to="/Leads/Add" class="mr-4">{{ $t('Add new lead') }}</b-button>
                <b-button :variant="hasFilters ? 'success' : 'primary'" v-b-toggle.collapse-3 class="mr-4">
                    {{
        $t('Show / Hide Filters')
                    }}
                </b-button>
            </b-form>
            <b-collapse id="collapse-3">
                <b-card title="Filters" style="background-color: lightyellow">
                    <b-form>
                        <b-form-row class="mb-2">
                            <b-col sm="1">
                                <label class="mr-sm-2" for="budgetMin">Services</label>
                            </b-col>
                            <b-col sm="2">
                                <b-form-select v-model="filters.service" :options="data.options.serviceList"></b-form-select>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                            <b-col sm="1">
                                <label class="mr-sm-2" for="budgetMin">Sources</label>
                            </b-col>
                            <b-col sm="11">
                                <multiselect v-model="filters.sources"
                                             :options="data.options.sourceList"
                                             label="text"
                                             track-by="value"
                                             :multiple="true"
                                             :close-on-select="false"
                                             :clear-on-select="false"
                                             :preserve-search="true"
                                             placeholder="All sources"></multiselect>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                            <b-col sm="1">
                                <label class="mr-sm-2" for="budgetMin">Stages</label>
                            </b-col>
                            <b-col sm="11">
                                <multiselect v-model="filters.stages"
                                             :options="data.options.stageList"
                                             label="text"
                                             track-by="value"
                                             :multiple="true"
                                             :close-on-select="false"
                                             :clear-on-select="false"
                                             :preserve-search="true"
                                             :searchable="false"
                                             placeholder="All stages"></multiselect>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                            <b-col sm="1">
                                <label class="mr-sm-2" for="budgetMin">Priorities</label>
                            </b-col>
                            <b-col sm="11">
                                <multiselect v-model="filters.priorities"
                                             :options="data.options.priorityList"
                                             label="text"
                                             track-by="value"
                                             :multiple="true"
                                             :close-on-select="false"
                                             :clear-on-select="false"
                                             :preserve-search="true"
                                             :searchable="false"
                                             placeholder="All priorities"></multiselect>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                            <b-col sm="1">
                                <label class="mr-sm-2" for="budgetMin">Budget range</label>
                            </b-col>
                            <b-col sm="1">
                                <b-form-input class="mr-2"
                                              v-model="filters.budgetRangeMin"
                                              placeholder="Min budget"
                                              id="budgetMin"></b-form-input>
                            </b-col>
                            <b-col sm="1">
                                <b-form-input class="mr-2"
                                              v-model="filters.budgetRangeMax"
                                              placeholder="Max budget"></b-form-input>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                            <b-col sm="1">
                                <label class="mr-sm-2" for="startDate">Time range</label>
                            </b-col>
                            <b-col sm="2">
                                <b-form-datepicker no-flip class="mr-2"
                                                   id="startDate"
                                                   v-model="filters.startDate"></b-form-datepicker>
                            </b-col>
                            <b-col sm="2">
                                <b-form-datepicker no-flip class="mr-2"
                                                   id="endDate"
                                                   v-model="filters.endDate"></b-form-datepicker>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                            <b-col sm="1">
                                <label class="mr-sm-2" for="lastNameFilter">Last name</label>
                            </b-col>
                            <b-col sm="2">
                                <b-form-input class="mr-3"
                                              placeholder="Last name"
                                              id="lastNameFilter"
                                              v-model="filters.lastName"></b-form-input>
                            </b-col>
                        </b-form-row>
                        <b-form-row class="mb-2">
                            <b-button variant="primary" v-on:click="applyFilters" class="mr-4">
                                {{
            $t('Apply Filters')
                                }}
                            </b-button>
                            <b-button variant="primary" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
                        </b-form-row>
                    </b-form>
                </b-card>
            </b-collapse>
            <b-table :items="data.items" :fields="fields" primary-key="id" hover :busy="isBusy"
                     sticky-header="70vh">
                <template #table-busy>
                    <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle mr-2"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <template #cell(createdOn)="data">
                    {{ data.item.createdOn | date_short }}
                </template>
                <template #cell(modifiedOn)="data">
                    {{ data.item.modifiedOn | date_short }}
                </template>
                <template #cell(name)="data">
                    <router-link :to="{ name: 'Leads/View', params: { id: data.item.id } }">
                        {{ data.item.name }}
                    </router-link>
                </template>
                <template #cell(type)="data">
                    {{ data.item.forPurchase ? $t('For purchase') : (data.item.forRent ? $t('For rent') : (data.item.forShortRent ? $t('For short rent') : (data.item.forSell ? $t('For sell') : (data.item.forRentOut ? $t('For rent out') : 'other')))) }}
                </template>
                <template #cell(startDate)="data">
                    {{ data.item.startDate | date_short }}
                </template>
                <template #cell(endDate)="data">
                    {{ data.item.endDate | date_short }}
                </template>
                <template #cell(budgetRange)="data">
                    <field-range-currency :min="data.item.budgetRangeMin"
                                          :max="data.item.budgetRangeMax"
                                          name="budgetRangeMin" />
                </template>
            </b-table>
            <b-row>
                <b-col sm="2" md="3" class="my-1">
                    <b-form-group label="Per page"
                                  label-for="per-page-select"
                                  label-cols-sm="6"
                                  label-cols-md="4"
                                  label-cols-lg="3"
                                  label-align-sm="right"
                                  label-size="sm"
                                  class="mb-0">
                        <b-form-select id="per-page-select"
                                       v-model="filters.perPage"
                                       :options="pageOptions"
                                       size="sm"></b-form-select>
                    </b-form-group>
                </b-col>

                <b-col sm="7" md="6" class="my-1">
                    <b-pagination v-model="filters.currentPage"
                                  :total-rows="data.totalRows"
                                  :per-page="filters.perPage"
                                  align="fill"
                                  size="sm"
                                  class="my-0"></b-pagination>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import NavItemLang from '@/components/NavItemLang.vue'
    import { api } from '@/services/api'
    import FieldRangeCurrency from '@/components/Shared/FieldRangeCurrency.vue'
    import Multiselect from 'vue-multiselect'
    import { ISelectListItem } from '../../models/ISelectListItem'
    import { myutils } from '@/services/myutils'

    @Component({
        components: {
            NavItemLang,
            FieldRangeCurrency,
            Multiselect
        }
    })
    export default class LeadList extends Vue {
        data: IData | null = null
        filters: IFilters = {
            service: 0,
            sources: [],
            stages: [],
            priorities: [],
            startDate: null,
            endDate: null,
            budgetRangeMin: null,
            budgetRangeMax: null,
            lastName: null,
            perPage: 20,
            currentPage: 1
        }
        isBusy = true

        created() {
            this.loadFilterFromCookies()
            this.fetchData()
        }

        loadFilterFromCookies(): void {
            const cookieFilter = this.$cookies.get('LeadList.filter') as IFilters
            if (!cookieFilter) return
            this.filters.currentPage = cookieFilter.currentPage ?? 1
            this.filters.perPage = cookieFilter.perPage ?? 20
            this.filters.service = cookieFilter.service ?? null
            this.filters.sources = cookieFilter.sources ?? []
            this.filters.stages = cookieFilter.stages ?? []
            this.filters.priorities = cookieFilter.priorities ?? []
            this.filters.startDate = cookieFilter.startDate ?? null
            this.filters.endDate = cookieFilter.endDate ?? null
            this.filters.budgetRangeMin = cookieFilter.budgetRangeMin ?? null
            this.filters.budgetRangeMax = cookieFilter.budgetRangeMax ?? null
            this.filters.lastName = cookieFilter.lastName ?? null
        }
        perPage = 10
        pageOptions = [5, 20, 100]

        fields = [
            { key: 'createdOn', label: 'Created on', sortable: true },
            { key: 'createdBy', label: 'Created by', sortable: true },
            { key: 'name', label: 'Name', sortable: true },
            { key: 'type', label: 'Type' },
            { key: 'stage', label: 'Stage', sortable: true },
            { key: 'priority', label: 'Priority', sortable: true },
            { key: 'startDate', label: 'Start date', sortable: true },
            { key: 'endDate', label: 'End Date', sortable: true },
            { key: 'budgetRange', label: 'Budget' },
            { key: 'modifiedOn', label: 'Modified on', sortable: true },
            { key: 'modifiedBy', label: 'Modified by', sortable: true },
            { key: '', label: '', sortable: true },
        ]

        fetchData(): void {
            this.isBusy = true
            api
                .postExt<IFilters, IData>('/api/lead/search', this.filters)
                .then((data) => {
                    this.data = data
                    this.isBusy = false
                })
        }
        applyFilters(): void {
            this.$cookies.set('LeadList.filter', this.filters)
            this.fetchData()
        }

        clearFilters(): void {
            myutils.setPropertiesToNullOrEmptyArray(this.filters)
            this.$cookies.set('LeadList.filter', this.filters)
            this.fetchData()
        }
        get hasFilters(): boolean {
            return this.filters.service != null ||
                this.filters.sources.length > 0 ||
                this.filters.stages.length > 0 ||
                this.filters.priorities.length > 0 ||
                this.filters.startDate != null ||
                this.filters.endDate != null ||
                this.filters.budgetRangeMin != null ||
                this.filters.budgetRangeMax != null ||
                this.filters.lastName != null
        }

        @Watch('filters.perPage')
        onPerPageChanged(): void {
            this.filters.currentPage = 1
            this.$cookies.set('LeadList.filter', this.filters)
            this.fetchData()
        }

        @Watch('filters.currentPage')
        onPageChanged(): void {
            Vue.nextTick(() => {
                this.$cookies.set('LeadList.filter', this.filters)
                this.fetchData()
            })
        }
    }
    interface IFilters {
        service: number | null
        sources: ISelectListItem[]
        stages: ISelectListItem[]
        priorities: ISelectListItem[]
        startDate: Date | null
        endDate: Date | null
        budgetRangeMin: number | null
        budgetRangeMax: number | null
        lastName: string | null
        perPage: number
        currentPage: number
    }
    interface IData {
        items: IDataItem[]
        options?: IFiltersOptions | null
        totalRows: number
    }
    interface IFiltersOptions {
        serviceList: ISelectListItem[]
        sourceList: ISelectListItem[]
        priorityList: ISelectListItem[]
        stageList: ISelectListItem[]
    }
    interface IDataItem {
        id?: string
        name?: string
        forPurchase?: boolean | null
        forSell?: boolean | null
        forRent?: boolean | null
        forShortRent?: boolean | null
        forRentOut?: boolean | null
        stage?: string
        priority?: string
        startDate?: Date | null
        endDate?: Date | null
        budgetRangeMin?: number | null
        budgetRangeMax?: number | null
        createdBy?: string | null
        createdOn?: Date | null
        modifiedBy?: string | null
        modifiedOn?: Date | null
    }
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
.no-left-padding {
  padding-left: 0;
}
</style>
