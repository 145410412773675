<template>
    <div>
        <h6 class="mb-2">{{ $t('Activities') }}</h6>
        <b-container class="mb-4">
            <b-row>
                <!--<activity-add2 :parentEntity="parentEntity" :parentId="parentId"></activity-add2>-->
                <b-button variant="primary" v-on:click="onNewActivityClick" class="mr-2">
                    {{$t('New activity')}}
                </b-button>

                <b-button :variant="isEditMode ? 'success' : 'primary'"
                          v-on:click="isEditMode = !isEditMode"
                          class="mr-2">{{ $t('Edit Activities') }}</b-button>
                <div>
                    <b-input-group :prepend="$t('Search for activity')+':'" v-if="list.items.length">
                        <b-form-input v-model="searchText" type="search"></b-form-input>
                    </b-input-group>
                </div>
            </b-row>
        </b-container>
        <div v-if="!dataLoaded">
            <div>{{ $t('Loading...') }}</div>
            <b-spinner class="m-5" label="Busy"></b-spinner>
        </div>
        <activityEdit2 :parent="parentEntity" :parentId="parentId" v-if="displayNewActivity"></activityEdit2>
        
        <div v-for="(item, index) in items" v-bind:key="item.id">
            <div v-if="index != editIndex">
                <div class="font-italic">
                    {{ item.createdOn | date }} {{ item.createdBy }}
                    <span class="mr-2 ml-2">
                        <font-awesome-icon icon="exclamation-circle" v-if="item.done === false" :style="{ color: 'red' }" title="To do action" />
                        <font-awesome-icon icon="check-square" v-if="item.done === true" title="Completed action" />
                    </span>
                    <span v-if="item.assignedTo">Assigned to: {{ item.assignedTo.description}}</span>
                    <span v-if="item.assignedTo && item.doneDate">, </span>
                    <span v-if="item.doneDate">Completed: {{ item.doneDate | date }}</span>
                    <b-button v-if="isEditMode"
                              variant="outline-primary"
                              size="sm"
                              @click="editRequest(item, index)"
                              class="mr-2">
                        <font-awesome-icon icon="edit" />
                    </b-button>
                    <b-button v-if="isEditMode"
                              variant="outline-danger"
                              size="sm"
                              @click="deleteRequest(item, index)">
                        <font-awesome-icon icon="trash" />
                    </b-button>
                </div>
                <field name="description"
                       type="textarea"
                       :value="item.description"
                       :edit="isEditMode"
                       v-on:click="edit"
                       :entityId="item.id" />
                <fileLinks :items="item.files"
                           :viewMode="isEditMode ? 1 : 0"
                           parent="activity"
                           :parentId="item.id"></fileLinks>
                <activityLinks :items="getLlinksWOParent(item.links)"></activityLinks>
            </div>
            <div v-if="index == editIndex">
                <activityEdit2 :id="item.id"></activityEdit2>
            </div>
            <hr />
        </div>
        <b-modal id="deleteActivityConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete activity?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import ActivityAdd2 from './ActivityAdd2.vue'
    import ActivityEdit2 from './ActivityEdit2.vue'
    import FileLinks from './../Shared/FileLinks.vue'
    import ActivityLinks from './ActivityLinks.vue'
    import { EventBus } from './../Shared/EventBus'
    import field from './../Shared/Field.vue'
    import { ActivityLinkDto } from '@/models/ActivityDto2'
    import FileLinkDto2 from '@/models/FileLinkDto2'
    import { EntityType } from '../../models/Enums'

    @Component({ components: { ActivityAdd2, ActivityEdit2, FileLinks, field, ActivityLinks } })
    export default class ActivityList2 extends Vue {
        @Prop({ type: Number, required: true }) parentEntity!: EntityType | null
        @Prop({ required: true }) parentId!: number | string | null

        isEditMode = false
        dataLoaded = false

        list = { items: [] } as ActivityListQueryOutput

        created(): void {
            EventBus.$on('viewUpdated', this.updateItem)
            EventBus.$on('ActivityEditCancelled', this.onActivityEditCancelled)
            EventBus.$on('ActivityEditSaved', this.onActivityEditSaved)
            this.fetchData()
        }

        @Watch('parentId')
        onIdChange(): void {
            this.fetchData()
        }

        fetchData(): void {
            if (this.parentId === undefined) return
            api.get<ActivityListQueryOutput>('/api/activity',
                { parent: this.parentEntity, parentId: this.parentId }
            ).then((data: ActivityListQueryOutput) => {
                this.list = data
                this.dataLoaded = true
            })
        }

        edit(fieldName: string, type: string, id: number, value: string): void {
            this.showFieldEditor(fieldName, type, id, value)
        }

        editIndex: number | null = null
        editItem: ActivityListQueryOutputItem | null = null

        editRequest(item: ActivityListQueryOutputItem, index: number): void {
            this.editItem = item
            this.editIndex = index
        }

        onActivityEditSaved(): void {
            this.displayNewActivity = false
            this.editIndex = null
            this.fetchData()
        }

        onActivityEditCancelled(): void {
            this.displayNewActivity = false
            this.editIndex = null
        }

        displayNewActivity: boolean = false
        onNewActivityClick(): void {
            this.displayNewActivity = true
        }


        showFieldEditor(fieldName: string, type: string, id: number, value: string): void {
            EventBus.$emit('fieldEdit', {
                entity: 'activity',
                id: id,
                label: this.$t(fieldName),
                name: fieldName,
                type: type,
                value: value
            })
        }

        updateItem(item: ActivityListQueryOutputItem, entity: string): void {
            if (entity == 'activity') {
                const itemFromList = this.list.items.find(function (element) {
                    if (element.id == item.id) return element
                })
                if (itemFromList) itemFromList.description = item.description
            }
        }

        getLlinksWOParent(allLinks: ActivityLinkDto[]): ActivityLinkDto[] {
            return (
                allLinks?.filter((i) => i.entity != this.parentEntity || i.id != this.parentId) ?? []
            )
        }

        searchText = ''

        get items(): ActivityListQueryOutputItem[] {
            return this.searchText === '' ?
                this.list.items :
                this.list.items.filter((el) => el.description?.toLowerCase().includes(this.searchText.toLowerCase()))
        }

        deleteIndex?: number
        deleteItem?: ActivityListQueryOutputItem

        deleteRequest(item: ActivityListQueryOutputItem, index: number): void {
            this.deleteItem = item
            this.deleteIndex = index
            this.$bvModal.show('deleteActivityConfirmation')
        }

        deleteConfirmed(): void {
            if (
                this.deleteIndex === undefined ||
                this.deleteItem === undefined ||
                this.deleteItem.id === undefined ||
                this.deleteItem.id === null
            ) {
                return
            }
            api.del(`/api/activity/${this.deleteItem.id}`)
                .then(() => {
                    if (this.list !== null && this.deleteIndex !== undefined)
                        this.list.items.splice(this.deleteIndex, 1)
                });
        }
    }
    interface ActivityListQueryOutput {
        items: ActivityListQueryOutputItem[]
    }
    interface ActivityListQueryOutputItem {
        id?: number
        description?: string
        createdBy?: string
        date?: Date
        files?: FileLinkDto2[] | null
        links?: ActivityLinkDto[]
        assignedTo?: ActivityLinkDto | null
        doneDate?: Date
    }

</script>

<style scoped lang="scss"></style>
