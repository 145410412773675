<template>
    <div class="container-md mt-2">
        <h1>{{ $t('Reservation') }} <span v-if="data && data.reservationNumber">#{{data.reservationNumber}}</span></h1>
        <div id="loadingSpinner" v-if="!loaded" class="d-flex">
            <b-spinner small label="Busy"></b-spinner><span class="ml-1">{{ $t('Loading...') }}</span>
        </div>
        <b-form v-if="loaded">
            <b-form-row>
                <b-col class="col-2">
                    <field-form-bool v-model="data.problem" v-bind:label="$t('Problem')" />
                </b-col>
                <b-col class="col-2">
                    <field-form-bool v-model="data.awaiting" v-bind:label="$t('Awaiting')" />
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <b-form-group label-for="reservationStage" v-bind:label="$t('Stage')">
                        <b-form-select v-model="data.reservationStage"
                                       :options="data.reservationStageOptions"
                                       :disabled="mode == MODE_VIEW"
                                       id="reservationStage"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col class="col-4">
                    <label for="checkIn">{{ $t('Booking date') }}</label>
                    <b-form-datepicker no-flip id="bookingDate"
                                       v-model="data.bookingDate"
                                       :readonly="mode == MODE_VIEW"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <b-form-group v-bind:label="$t('Object')">
                        <field-object-search v-if="mode != MODE_VIEW" v-model="data.object.id" 
                                             :initialText="data.object.referenceNumber + ' ' + data.object.title" options="STR" />
                        <span v-if="mode == MODE_VIEW">
                            <router-link v-if="data.object.referenceNumber" :to="{ name: 'Objects/View', params: { id: data.object.id } }">
                                {{ data.object.referenceNumber }} {{data.object.title}}
                            </router-link>
                            <span v-if="mode == MODE_VIEW && !data.object">
                                {{ $t('not set') }}
                            </span>
                        </span>
                    </b-form-group>
                </b-col>
                <!--<b-col class="col-4">
                    <label for="objectTitle">{{ $t('Object title') }}</label>
                    <b-form-input v-model.number="data.object.title"
                                  id="objectTitle"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>-->
                <b-col class="col-4">
                    <label for="websiteCode">{{ $t('Object website code') }}</label>
                    <b-form-input v-model.number="data.object.websiteCode"
                                  id="websiteCode"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <label for="checkIn">{{ $t('Check in') }}</label>
                    <b-form-datepicker no-flip id="checkIn"
                                       v-model="data.checkIn"
                                       :readonly="mode == MODE_VIEW"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col class="col-2">
                    <label for="duration">{{ $t('Duration') }}</label>
                    <b-form-input v-model.number="data.duration" class="mb-2"
                                  id="duration"
                                  :readonly="mode == MODE_VIEW"
                                  type="number"></b-form-input>
                </b-col>
                <b-col class="col-4">
                    <label for="endDate">{{ $t('Check out') }}</label>
                    <b-form-datepicker no-flip id="endDate"
                                       v-model="checkout"
                                       readonly
                                       class="mb-2"></b-form-datepicker>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-2">
                    <label for="adults">{{ $t('Adults') }}</label>
                    <b-form-input v-model.number="data.adults" class="mb-2"
                                  id="adults"
                                  :readonly="mode == MODE_VIEW"
                                  type="number"></b-form-input>
                </b-col>
                <b-col class="col-2">
                    <label for="children">{{ $t('Children') }}</label>
                    <b-form-input v-model.number="data.children"
                                  id="children"
                                  :readonly="mode == MODE_VIEW"
                                  type="number"></b-form-input>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-8">
                    <label for="demand">{{ $t('Original lead') }}</label>
                    <field-lead-search v-if="mode != MODE_VIEW" v-model="data.demandId" :initialText="data.demandId" />
                    <router-link v-if="mode == MODE_VIEW && data.demandId" :to="{ name: 'Leads/View', params: { id: data.demandId } }">
                        {{ $t('Link') }}
                    </router-link>
                    <span v-if="mode == MODE_VIEW && !data.demandId">
                        {{ $t('not set') }}
                    </span>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-2">
                    <label for="lastName">{{ $t('Client Last name') }}</label>
                    <b-form-input v-model.number="data.client.lastName" class="mb-2"
                                  id="lastName"
                                  v-validate="'required'"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
                <b-col class="col-2">
                    <label for="firstName">{{ $t('First name') }}</label>
                    <b-form-input v-model.number="data.client.firstName"
                                  id="firstName"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
                <b-col class="col-2">
                    <label for="taxNumber">{{ $t('Tax number') }}</label>
                    <b-form-input v-model.number="data.client.taxNumber"
                                  id="taxNumber"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
                <b-col class="col-2">
                    <label for="internalPassportNumber">{{ $t('Passport number') }}</label>
                    <b-form-input v-model.number="data.client.internalPassportNumber"
                                  id="internalPassportNumber"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
                <b-col class="col-2">
                    <label for="email">{{ $t('Email') }}</label>
                    <b-form-input v-model.number="data.client.email"
                                  id="email"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
                <b-col class="col-2">
                    <label for="phone">{{ $t('Phone') }}</label>
                    <b-form-input v-model.number="data.client.phone"
                                  id="phone"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
            </b-form-row>

            <b-form-row>
                <b-button class="ml-1 mb-4 mt-4" v-b-toggle.collapse-1 variant="primary">Payment details</b-button>
            </b-form-row>

            <b-collapse id="collapse-1" class="mt-2">
                <b-form-row>
                    <b-col class="col-2">
                        <label for="total">{{ $t('Total') }}</label>
                        <b-form-input v-model.number="data.total" class="mb-2"
                                      id="total"
                                      :readonly="mode == MODE_VIEW"></b-form-input>
                    </b-col>
                    <b-col class="col-2">
                        <field-form-bool v-model="data.totalDone" v-bind:label="$t('Total done')" />
                    </b-col>
                </b-form-row>
                <span>
                    <b-form-row>
                        <b-col class="col-2">
                            <label for="prepaymentCash">{{ $t('Prepayment cash') }}</label>
                            <b-form-input v-model.number="data.prepaymentCash" class="mb-2"
                                          id="prepaymentCash"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                        <b-col class="col-2">
                            <label for="prepaymentBank">{{ $t('Prepayment bank') }}</label>
                            <b-form-input v-model.number="data.prepaymentBank" class="mb-2"
                                          id="prepaymentBank"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                        <b-col class="col-2">
                            <field-form-bool v-model="data.prepaymentInvoice" v-bind:label="$t('Invoice')" />
                        </b-col>
                        <b-col class="col-2">
                            <field-form-bool v-model="data.prepaymentCashDone" v-bind:label="$t('Prepayment cash done')" />
                        </b-col>
                        <b-col class="col-2">
                            <field-form-bool v-model="data.prepaymentBankDone" v-bind:label="$t('Prepayment bank done')" />
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col class="col-2">
                            <label for="restCash">
                                {{
 $t('Rest cash')
                                }}
                            </label>
                            <b-form-input v-model.number="data.restCash" class="mb-2"
                                          id="restCash"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                        <b-col class="col-2">
                            <label for="restBank">{{ $t('Rest bank') }}</label>
                            <b-form-input v-model.number="data.restBank" class="mb-2"
                                          id="restBank"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                        <b-col class="col-2">
                            <field-form-bool v-model="data.restInvoice" v-bind:label="$t('Invoice')" />
                        </b-col>
                        <b-col class="col-2">
                            <field-form-bool v-model="data.restCashDone" v-bind:label="$t('Rest cash done')" />
                        </b-col>
                        <b-col class="col-2">
                            <field-form-bool v-model="data.restBankDone" v-bind:label="$t('Rest bank done')" />
                        </b-col>
                    </b-form-row>
                    <b-form-row>
                        <b-col class="col-2">
                            <label for="paymentToOwner">{{ $t('Payment to owner') }}</label>
                            <b-form-input v-model.number="data.paymentToOwner" class="mb-2"
                                          id="paymentToOwner"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                        <b-col class="col-2">
                            <label for="comission">{{ $t('Comission') }}</label>
                            <b-form-input v-model.number="data.comission" class="mb-2"
                                          id="comission"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                        <b-col class="col-2">
                            <label for="comission3rdParty">{{ $t('Comission 3rd party') }}</label>
                            <b-form-input v-model.number="data.comission3rdParty" class="mb-2"
                                          id="comission3rdParty"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                        <b-col class="col-2">
                            <label for="finalComission">{{ $t('Final comission') }}</label>
                            <b-form-input v-model.number="data.finalComission" class="mb-2"
                                          id="finalComission"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                        <b-col class="col-2">
                            <label for="totalPaymentDeposit">{{ $t('Total payment deposit') }}</label>
                            <b-form-input v-model.number="data.totalPaymentDeposit" class="mb-2"
                                          id="totalPaymentDeposit"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-col>
                    </b-form-row>
                </span>
                <b-form-row>
                    <b-col class="col-4">
                        <b-form-group label-for="sourceId" v-bind:label="$t('Source')">
                            <b-form-select v-model="data.sourceId"
                                           :options="data.sourceOptions"
                                           :disabled="mode == MODE_VIEW"
                                           id="sourceId"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-8">
                        <b-form-group label-for="externalLink" v-bind:label="$t('Source link')">
                            <b-form-input v-model="data.externalLink"
                                          id="externalLink"
                                          v-if="mode != MODE_VIEW"></b-form-input>
                            <a v-if="data.externalLink && mode == MODE_VIEW" :href="data.externalLink" target=�_blank�>link</a>
                            <span v-if="!data.externalLink && mode == MODE_VIEW">Not set</span>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col class="col-4">
                        <b-form-group label-for="reservationNumberExternal" v-bind:label="$t('Source Reservation Number')">
                            <b-form-input v-model="data.reservationNumberExternal"
                                          id="reservationNumberExternal"
                                          :readonly="mode == MODE_VIEW"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-form-row>
                <b-form-row>
                    <b-col class="col-2">
                        <b-form-group label-for="ownerPayments" v-bind:label="$t('Owner payments')">
                            <b-form-select v-model="data.ownerPayments"
                                           :options="data.ownerPaymentsOptions"
                                           :disabled="mode == MODE_VIEW"
                                           id="ownerPayments"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-2">
                        <b-form-group label-for="paymentType" v-bind:label="$t('Payment type')">
                            <b-form-select v-model="data.paymentType"
                                           :options="data.paymentTypeOptions"
                                           :disabled="mode == MODE_VIEW"
                                           id="paymentType"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col class="col-4">
                        <label for="ownerPaymentDate">{{ $t('Owner payment') }}</label>
                        <b-form-datepicker no-flip id="ownerPaymentDate"
                                           v-model="data.ownerPaymentDate"
                                           :readonly="mode == MODE_VIEW"
                                           class="mb-2"></b-form-datepicker>
                    </b-col>
                    <b-col class="col-4">
                        <label for="ownerPaymentNotes">{{ $t('Owner notes') }}</label>
                        <b-form-input v-model.number="data.ownerPaymentNotes"
                                      id="ownerPaymentNotes"
                                      :readonly="mode == MODE_VIEW"></b-form-input>
                    </b-col>
                </b-form-row>
            </b-collapse>
            <b-form-row>
                <b-col class="col-3">
                    <b-form-group label-for="arrivalTimePlanned" v-bind:label="$t('Arrival time planned')">
                        <b-form-timepicker v-model="data.arrivalTimePlanned"
                                           locale="gr"
                                           :disabled="mode == MODE_VIEW"
                                           id="arrivalTimePlanned"></b-form-timepicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-3">
                    <b-form-group label-for="arrivalTimeActual" v-bind:label="$t('Arrival time actual')">
                        <b-form-timepicker v-model="data.arrivalTimeActual"
                                           locale="gr"
                                           :disabled="mode == MODE_VIEW"
                                           id="arrivalTimeActual"></b-form-timepicker>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row class="mt-4" v-if="mode != MODE_CREATE">
                {{ $t('Created') }}: {{ data.createdOn | date }} by {{ data.createdByAccountDisplayName }} |
                {{ $t('Modified') }}: {{ data.modifiedOn | date }} {{ data.modifiedByAccountDisplayName }}
            </b-form-row>
            <b-form-row class="mt-2">
                <b-col class="col-12">
                    <b-button v-if="mode != MODE_VIEW" v-on:click="save" variant="success" class="mr-2">{{$t('Save')}}</b-button>
                    <b-button v-if="mode != MODE_VIEW" v-on:click="back" class="mr-2">{{$t('Cancel')}}</b-button>
                    <b-button v-if="mode == MODE_EDIT" v-on:click="deleteRequest" variant="danger">{{$t('Delete')}}</b-button>
                    <b-button v-if="mode == MODE_VIEW" v-on:click="edit" variant="success" class="mr-2">{{$t('Edit')}}</b-button>
                    <b-button v-if="mode == MODE_VIEW" v-on:click="back" class="mr-2">{{$t('Back')}}</b-button>
                </b-col>
            </b-form-row>
            <div v-if="mode == MODE_VIEW" class="mt-4">
                <h1>{{ $t('Activities of the Reservation') }}</h1>
                <ActivityList2 :parentEntity="entityType" :parentId="id"></ActivityList2>
            </div>
        </b-form>
        <b-modal id="deleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">{{$t('Delete the reservation?')}}</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Mixins } from 'vue-property-decorator'
    import BaseComponent from '@/components/Shared/BaseComponent.vue'
    import fieldFormBool from '@/components/Shared/FieldFormBool.vue'
    import { Route } from 'vue-router'
    import { api } from '@/services/api'
    import FieldAccountSearch from '@/components/Shared/FieldAccountSearch.vue'
    import FieldObjectSearch from '@/components/Shared/FieldObjectSearch.vue'
    import FieldLeadSearch from '@/components/Shared/FieldLeadSearch.vue'
    import ActivityList2 from './../../components/Activity/ActivityList2.vue'
    import { EntityType } from '../../models/Enums'

    @Component({
        components: {
            ActivityList2,
            fieldFormBool,
            FieldAccountSearch,
            FieldObjectSearch,
            FieldLeadSearch
        }
    })
    export default class ReservationEdit extends Mixins(BaseComponent) {
        id: number | null = null
        data: Reservation | null = null
        loaded = false

        readonly MODE_UNDEFINED = 0
        readonly MODE_CREATE = 1
        readonly MODE_EDIT = 2
        readonly MODE_VIEW = 3
        private mode = this.MODE_UNDEFINED

        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            this.id = Number(r.params.id)
            this.mode = this.id ? this.MODE_VIEW : this.MODE_CREATE
            var result =
                this.mode != this.MODE_CREATE
                    ? api.get<Reservation>(`/api/Reservation/edit/${this.id}`)
                    : api.get<Reservation>('/api/Reservation/add')

            result
                .then((data) => {
                    this.data = data
                    this.loaded = true
                })
        }
        save(): void {
            api.post<Reservation>('/api/Reservation/update', this.data as Reservation)
                .then(() => { this.back() })
        }
        deleteRequest(): void {
            this.$bvModal.show('deleteConfirmation')
        }
        deleteConfirmed(): void {
            if (this.mode != this.MODE_EDIT) return

            api.del(`/api/Reservation/${this.id}`)
                .then(() => {
                    this.back()
                })
        }
        get demandDecription(): string {
            return 'not defined'
        }

        edit(): void {
            this.mode = this.MODE_EDIT
        }
        view(): void {
            this.mode = this.MODE_VIEW
        }
        activityViewMode = 0
        entityType = EntityType.Reservation

        get checkout(): string {
            if (!this.data) return ''
            var date = new Date(this.data.checkIn)
            date.setDate(date.getDate() + this.data.duration)
            return date.toISOString().substr(0, 10)
        }
    }
    interface Reservation {
        id: number
        createdByAccountDisplayName: string
        createdOn: string
        modifiedByAccountDisplayName: string
        modifiedOn: string
        objectId: number | null
        reservationStage: number
        reservationNumber: string
        reservationNumberExternal: string
        bookingDate: string
        checkIn: string
        duration: number
        adults: number
        children: number
        prepaymentCash: number
        prepaymentBank: number
        prepaymentInvoice: boolean
        restCash: number
        restBank: number
        restInvoice: boolean
        total: number
        paymentToOwner: number
        comission: number
        comission3rdParty: number
        finalComission: number
        totalPaymentDeposit: number
        sourceId: number
        sourceDisplayName: string
        clientId: number
        clientDisplayName: string
        externalLink: string
        internalNotes: string
        ownerPayments: string
        ownerPaymentsTransactionId: string
        paymentType: number
        ownerPaymentDate: string
        ownerPaymentNotes: string
        arrivalTimePlanned: string
        arrivalTimeActual: string
        client: Account4ReservationDto // Assuming you have Account4ReservationDto class defined
        object: Object4ReservationDTO
        problem: boolean
        awaiting: boolean
        prepaymentCashDone: boolean
        prepaymentBankDone: boolean
        restCashDone: boolean
        restBankDone: boolean
        totalDone: boolean
    }
    interface Account4ReservationDto {
        accountId: number
        account: string
        relation: number
    }
    interface Object4ReservationDTO {

    }
</script>

<style scoped></style>
