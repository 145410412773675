<template>
  <div>
    <gmap-map
      :center="mapCircleLatitude ? positionC : positionM"
      :zoom="zoomCalc"
      style="width: 100%; height: 400px"
    >
      <gmap-marker v-if="latitude" :position="positionM"></gmap-marker>

      <gmap-circle
        v-if="mapCircleLatitude"
        :center="positionC"
        :radius="mapCircleRadius"
        :options="{
          fillColor: 'blue',
          fillOpacity: 0.35,
          strokeColor: 'blue',
          strokeOpacity: 0.8,
          strokeWeight: 1
        }"
      >
      </gmap-circle>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: 'ObjectEditMap',
  props: [
    'latitude',
    'longitude',
    'mapZoom',
    'mapCircleLatitude',
    'mapCircleLongitude',
    'mapCircleRadius'
  ],
  data() {
    return {
      defaultCenter: {
        lat: 40.63471386411826,
        lng: 22.93874616154762
      },
      defaultZoom: 12
    }
  },
  computed: {
    positionM() {
      if (!this.latitude) return this.defaultCenter
      return {
        lat: this.latitude,
        lng: this.longitude
      }
    },
    positionC() {
      if (!this.mapCircleLatitude) return this.defaultCenter
      return {
        lat: this.mapCircleLatitude,
        lng: this.mapCircleLongitude
      }
    },
    mapCenter() {
      if (!this.lat) return this.defaultCenter
      return this.position
    },
    zoomCalc() {
      return this.mapZoom ? this.mapZoom : this.defaultZoom
    }
  }
}
</script>
