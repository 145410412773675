<template>
  <div class="ml-3 payment-account-view">
    <h1 class="mt-2">
      {{$t('Account')}}:
    </h1>
    <h2>
      <span v-if="isAdmin">
        <router-link :to="{ name: 'Accounts/View', params: { id: data.id } }">
          {{ data.accountDisplayName }}
        </router-link>
      </span>
      <span v-if="!isAdmin">
        {{ data.accountDisplayName }}
      </span>
      | <a :href="'mailto:' + email">{{ email }}</a>
    </h2>
    <h1>{{$t('Created on')}}:</h1><h2>{{ data.accountCreatedOn | date_short }}</h2>
    <h1>{{$t('Balance')}}:</h1><h2>{{ data.balance | currency_2 }}</h2>
    <h1>{{$t('Total Credit')}}:</h1><h2>{{ data.totalCredit | currency_2 }}</h2>
    <h1>{{$t('Total Debit')}}:</h1><h2>{{ data.totalDebit | currency_2 }}</h2>
    <h1>{{$t('Number of Unpaid Transactions')}}:</h1><h2>{{ unpaidRows }}</h2>
    <div id="adminBar" v-if="isAdmin">
      <b-button variant="primary"
                :to="{ name: 'Payments/AddIncome', params: { accountId: input.accountId } }"
                class="mr-2 mb-2">{{ $t('Add income') }}</b-button>
      <b-button variant="primary"
                :to="{ name: 'Payments/AddExpense', params: { accountId: input.accountId } }"
                class="mr-4 mb-2">{{ $t('Add expense') }}</b-button>
      <b-dropdown text="Administration" class="mr-2 mb-2" variant="primary">
        <b-dropdown-item v-on:click="sendInvitation()">
          {{$t('Send email invitation')}}
        </b-dropdown-item>
        <b-dropdown-item v-on:click="setPassword()">
          {{ $t('Set password') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="form-inline">
      <b-form-select v-model="filter.unpaid" class="mr-2 mb-2">
        <b-form-select-option value="1">{{$t('Show unpaid')}}</b-form-select-option>
        <b-form-select-option value="0">{{$t('Show all')}}</b-form-select-option>
      </b-form-select>
      <b-dropdown id="dropdown-form"
                  :text="$t('Filters')"
                  ref="dropdown"
                  class="mr-2 mb-2"
                  :variant="getFilterButtonVariant()">
        <b-dropdown-item-button>{{$t('Type') }}</b-dropdown-item-button>
        <b-dropdown-form>
          <b-form-checkbox class="mb-3" v-model="filter.income">{{$t('Income') }}</b-form-checkbox>
          <b-form-checkbox class="mb-3" v-model="filter.expense">{{$t('Expense') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-item-button>{{$t('Payment Method') }}</b-dropdown-item-button>
        <b-dropdown-form>
          <b-form-checkbox class="mb-3" v-model="filter.banking">{{$t('Banking') }}</b-form-checkbox>
          <b-form-checkbox class="mb-3" v-model="filter.cash">{{$t('Cash') }}</b-form-checkbox>
        </b-dropdown-form>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item-button>{{$t('Category') }}</b-dropdown-item-button>
        <b-dropdown-form>
          <b-form-checkbox v-for="category in filter.categories"
                           v-bind:key="category.name"
                           class="mb-3"
                           v-model="category.filtered">{{ category.name }}</b-form-checkbox>
          <!--<b-button variant="outline-danger" size="sm" @click="clearFilters">clear</b-button>-->
        </b-dropdown-form>
      </b-dropdown>
      <b-button variant="primary" class="mr-2 mb-2" v-on:click="download">{{ $t('Export') }}</b-button>

    </div>
    <b-table stripped
             hover
             :items="filteredData"
             :fields="fields"
             :per-page="perPage"
             :current-page="currentPage">

      <template #head(transactionDate)>
        <span>{{ $t('Date') }}</span>
      </template>
      <template #head(categoryName)>
        <span>{{ $t('Category') }}</span>
      </template>
      <template #head(debit)>
        <span>{{ $t('Debit') }}</span>
      </template>
      <template #head(credit)>
        <span>{{ $t('Credit') }}</span>
      </template>
      <template #head(balance)>
        <span>{{ $t('Balance') }}</span>
      </template>
      <template #head(status)>
        <span>{{ $t('Status') }}</span>
      </template>
      <template #head(confirmedByClientDate)>
        <span>{{ $t('Confirmed by Client Date') }}</span>
      </template>
      <template #head(expirationDate)>
        <span>{{ $t('Expiration Date') }}</span>
      </template>
      <template #head(startDate)>
        <span>{{ $t('Billing period start') }}</span>
      </template>
      <template #head(endDate)>
        <span>{{ $t('Billing period end') }}</span>
      </template>
      <template #head(paymentDate)>
        <span>{{ $t('Payment Date') }}</span>
      </template>
      <template #head(attachments)>
        <span>{{ $t('Attachments') }}</span>
      </template>
      <template #head(paymentMethod)>
        <span>{{ $t('Payment Method') }}</span>
      </template>

      <template #cell(transactionDate)="data">
        {{ data.item.transactionDate | date }}
      </template>
      <template #cell(debit)="data">
        <span class="text-nowrap">{{ data.item.debit | my_currency }}</span>
      </template>
      <template #cell(credit)="data">
        <span class="text-nowrap">{{ data.item.credit | my_currency }}</span>
      </template>
      <template #cell(balance)="data">
        <span class="text-nowrap">{{ data.item.balance | currency_2 }}</span>
      </template>
      <template #cell(expirationDate)="data">
        {{ data.item.expirationDate | date_short }}
      </template>
      <template #cell(confirmedByClientDate)="data">
        {{ data.item.confirmedByClientDate | date_short }}
      </template>
      <template #cell(startDate)="data">
        {{ data.item.startDate | date_short }}
      </template>
      <template #cell(endDate)="data">
        {{ data.item.endDate | date_short }}
      </template>
      <template #cell(paymentDate)="data">
        {{ data.item.paymentDate | date_short }}
      </template>
      <template #cell(attachments)="data">
        <span v-for="file in data.item.attachments" v-bind:key="file.id" class="mr-2">
          <a :href="'/api/transactions/files/' + file.id" target="_blank">
            <font-awesome-icon class="mr-1" icon="file" />
          </a>
        </span>
      </template>
      <template #cell(actions)="data" v-if="isAdmin">
        <b-button v-if="data.item.isDebit"
                  variant="outline-primary"
                  size="sm"
                  :to="{ name: 'Payments/EditExpense', params: { id: data.item.id } }">
          <font-awesome-icon icon="pen" />
        </b-button>
        <b-button v-if="!data.item.isDebit"
                  variant="outline-primary"
                  size="sm"
                  :to="{ name: 'Payments/EditIncome', params: { id: data.item.id } }">
          <font-awesome-icon icon="pen" />
        </b-button>
      </template>
    </b-table>
    <div class="d-flex flex-row align-items-baseline">
      <div class="p-10">
        <b-pagination v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      aria-controls="my-table"></b-pagination>
      </div>
      <div class="p-2">
        <b-form inline>
          <label for="perPage" class="ml-4 mr-2">{{ $t('Records per page')}}: </label>
          <b-form-input id="perPage" v-model="perPage" type="number"></b-form-input>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  /* tslint:disable */
  import { Component, Watch, Mixins } from 'vue-property-decorator'
  import { Route } from 'vue-router'
  import { Getter } from 'vuex-class'
  import NavItemLang from './../../components/NavItemLang.vue'
  import { api } from '@/services/api'
  import { AccountViewOutput, AccountViewOutputItem } from '@/models/Transactions/AccountViewOutput'
  import { AccountViewInput } from '@/models/Transactions/AccountViewInput'
  import SortableColumns from '@/components/Shared/SortableColumns.vue'
  import SortableList from '@/views/Shared/SortableList.vue'
  import store from '@/store'
  import { EventBus } from '@/components/Shared/EventBus'

  @Component({
    components: {
      NavItemLang,
      SortableColumns
    }
  })
  export default class TransactionAccountView extends Mixins(SortableList) {
    public data: AccountViewOutput = new AccountViewOutput()
    public filteredData: AccountViewOutputItem[] = []
    private input: AccountViewInput = {
      accountId: 0
    }

    @Getter('authentication/userRoles')
    private userRoles!: string

    get isAdmin(): boolean {
      return this.userRoles.indexOf('admin') != -1
    }

    fields = [
      { key: 'referenceNumber', label: '#' },
      { key: 'transactionDate' },
      { key: 'categoryName' },
      { key: 'debit', tdClass: 'text-right', thClass: 'text-right' },
      { key: 'credit', tdClass: 'text-right', thClass: 'text-right' },
      { key: 'balance', tdClass: 'text-right', thClass: 'text-right' },
      { key: 'status' },
      { key: 'confirmedByClientDate' },
      { key: 'expirationDate' },
      { key: 'startDate' },
      { key: 'endDate' },
      { key: 'paymentDate' },
      { key: 'attachments' },
      { key: 'paymentMethod' },
      { key: 'actions', label: '' }
    ]

    perPage = 25
    currentPage = 1

    get rows(): number {
      return this.data.items.length
    }
    get unpaidRows(): number {
      return this.data.items.filter((v) => v.status == this.$t('NotPaid')).length
    }

    filter = {
      categories: [] as FilterItem[],
      income: false,
      expense: false,
      banking: false,
      cash: false,
      unpaid: "0"
    }
    createFilter(): void {
      this.filter.categories = this.data.items
        .map((i: AccountViewOutputItem) => i.categoryName as string)
        .filter((v, i, a) => a.indexOf(v) === i)
        .sort()
        .map((i: string) => {
          return { name: i, filtered: false } as FilterItem
        })
    }

    clearFilters(): void {
      alert('!')
    }

    @Watch('filter', { immediate: true, deep: true })
    applyFilters(): void {
      this.$cookies.set('PaymentView.filters.account' + this.input.accountId, this.filter)

      this.filteredData = this.data.items

      if (this.filter.unpaid == "1") {
        this.filteredData = this.filteredData.filter((v) => v.statusId === 0)
      }

      const categories = this.filter.categories.filter((v) => v.filtered).map((i) => i.name)
      if (categories.length > 0)
        this.filteredData = this.filteredData.filter(
          (v) => categories.indexOf(v.categoryName as string) >= 0
        )

      if (this.filter.banking || this.filter.cash) {
        if (this.filter.cash && !this.filter.banking)
          this.filteredData = this.filteredData.filter((v) => v.paymentMethod == 'Cash')
        if (this.filter.banking && !this.filter.cash)
          this.filteredData = this.filteredData.filter((v) => v.paymentMethod == 'Banking')
      }

      if (this.filter.income || this.filter.expense) {
        if (this.filter.expense && !this.filter.income)
          this.filteredData = this.filteredData.filter((v) => v.debit && v.debit > 0)
        if (this.filter.income && !this.filter.expense)
          this.filteredData = this.filteredData.filter((v) => v.credit && v.credit > 0)
      }
    }

    loadFilterFromCookies(): void {
      const cookieFilter = this.$cookies.get('PaymentView.filters.account' + this.input.accountId) 
      Object.assign(this.filter, cookieFilter)
    }

    constructor() {
      super()
      EventBus.$on('langChanged', this.fetchData)
    }

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(r: Route): void {
      this.input.accountId = isNaN(Number(r.params.id)) ? -1 : Number(r.params.id)
      this.fetchData()
    }

    fetchData(): void {
      if (!this.input.accountId) return;
      api
        .get<AccountViewOutput>('/api/transactions', this.input)
        .then((data) => {
          this.data = data
          this.createFilter()
          this.loadFilterFromCookies()
        })
    }

    sendInvitation(): void {
      api
        .get<string>(`/api/transactions/sendInvitation/${this.input.accountId}`)
        .then(() => {
          store.dispatch('alert/success', 'Invitation sent');
        })
    }
    setPassword(): void {
      const email = this.data.accountEmail?.split(';')[0]
      window.open('/ResetPassword?email=' + email, '_blank')?.focus()
    }
    getFilterButtonVariant(): string {
      return this.filter.banking
        || this.filter.cash
        || this.filter.expense
        || this.filter.income
        || this.filter.categories.filter((v) => v.filtered).length
        ? "success" : "primary"
    }
    download(): void {
      api.download("/api/transactions/download/" + this.input.accountId, 'Transactions.xlsx')
    }

    get email(): string {
      return this.data.accountEmail?.split(';')[0] ?? ''
    }
  }

  interface FilterItem {
    name: string
    filtered: boolean
  }
</script>

<style scoped lang="scss"></style>
