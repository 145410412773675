<template>
    <div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import accountEdit from './../../components/Accounts/AccountEdit.vue'
    import { api } from '@/services/api'
    import AccountDto from './../../models/AccountDto'
    import AccountAddCommandInput from '../../models/AccountAddCommandInput'

    @Component({
        components: {
            accountEdit
        }
    })
    export default class AccountAdd extends Vue {
        @Prop(Object) input?: AccountAddCommandInput

        constructor() {
            super()
            this.fetchData()
        }

        fetchData(): void {
            if (this.input === undefined) return;
            api
                .postExt<AccountAddCommandInput, AccountDto>('/api/account/add', this.input)
                .then(data => {
                    this.$router.push('/Accounts/View/' + data.id + '/2')
                })
        }
    }
</script>

<style scoped></style>
