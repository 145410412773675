import { render, staticRenderFns } from "./ChangeEmployeRole.vue?vue&type=template&id=a49ce790&scoped=true"
import script from "./ChangeEmployeRole.vue?vue&type=script&lang=ts"
export * from "./ChangeEmployeRole.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a49ce790",
  null
  
)

export default component.exports