<template>
    <div>
        <error-box :error="error" />
        <object-edit v-if="dto" :dto="dto" />
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { ObjectEditQueryOutput } from '@/models/ObjectEditQueryOutput'
    import { api } from '@/services/api'
    import ObjectEdit from '@/components/Objects/ObjectEdit.vue'

    @Component({
        components: {
            ObjectEdit
        }
    })
    export default class ObjectEditRequest extends Vue {
        @Prop({ type: Number, required: true }) id!: number

        public dto: ObjectEditQueryOutput = new ObjectEditQueryOutput()
        private error: Error | null = null

        constructor() {
            super()
            this.fetchData()
        }

        fetchData(): void {
            api.get<ObjectEditQueryOutput>(`/api/object/edit/${this.id}`)
                .then((data: ObjectEditQueryOutput) => this.dto = data)
        }
    }
</script>

<style scoped></style>
