import { render, staticRenderFns } from "./ObjectEdit.vue?vue&type=template&id=08f91318&scoped=true"
import script from "./ObjectEdit.vue?vue&type=script&lang=ts"
export * from "./ObjectEdit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08f91318",
  null
  
)

export default component.exports