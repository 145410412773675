<template>
    <div>
        <h3>
            {{ $t('Contacts') }}
        </h3>
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <b-table striped hover :items="list2Display" :fields="fields" thead-class="d-none">
            <template #cell(account)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.accountId } }">
                    {{ data.item.account }}
                </router-link>
                <span v-if="data.item.relation==RelationTypeTenantShort || data.item.relation==RelationTypeTenantLong">
                    <span v-if="data.item.startDate">
                        {{ $t('Start:') }}{{data.item.startDate | date_short}}
                    </span>
                    <span v-if="data.item.endDate">
                        {{ $t('Finish:') }}{{data.item.endDate | date_short}}
                    </span>
                    <span v-if="data.item.price">
                        {{ $t('Price:') }}{{data.item.price}}
                    </span>
                </span>
            </template>
            <template #cell(edit)="row">
                <b-button v-if="row.item.relation==RelationTypeTenantShort || row.item.relation==RelationTypeTenantLong" @click="row.toggleDetails" variant="outline-primary" size="sm" class="mb-2">
                    <font-awesome-icon icon="edit" />
                </b-button>
            </template>
            <template #cell(delete)="row">
                <b-button variant="outline-danger" size="sm" @click="deleteRequest(row.item, row.index)">
                    <font-awesome-icon icon="trash" />
                </b-button>
            </template>
            <template #row-details="row">
                <b-form-group label-for="startDate" v-bind:label="$t('Start')">
                    <b-form-datepicker no-flip id="startDate" reset-button
                                       v-model="row.item.startDate"
                                       class="mb-2"></b-form-datepicker>
                </b-form-group>
                <b-form-group label-for="endDate" v-bind:label="$t('Finish')">
                    <b-form-datepicker no-flip id="endDate" reset-button
                                       v-model="row.item.endDate"
                                       class="mb-2"
                                       :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                </b-form-group>
                <my-input id="price"
                          label="Price"
                          v-model="row.item.price"
                          type="number"></my-input>
                <b-button variant="success" @click="edit(row)">{{ $t('Save') }}</b-button>
            </template>
        </b-table>

        <div>
            <b-button v-b-toggle.collapse-1 variant="primary">{{ $t('Add contact') }}</b-button>
            <b-collapse id="collapse-1" class="mt-2" v-model="showAddAccountBlock">
                <b-card>
                    <b-form-group label-for="relationType" v-bind:label="$t('Relation')">
                        <b-form-select v-model="newItem.relation"
                                       :options="relationTypes"
                                       id="relationType"></b-form-select>
                    </b-form-group>

                    <b-form-group label-for="objectType" v-bind:label="$tc('Contact')">
                        <field-account-search v-model="newItem.accountId" :initialText="newItem.account" />
                    </b-form-group>

                    <template v-if="newItem.relation==RelationTypeTenantShort || newItem.relation==RelationTypeTenantLong">
                        <b-form-group label-for="startDate" v-bind:label="$t('Start')">
                            <b-form-datepicker no-flip id="startDate" reset-button
                                               v-model="newItem.startDate"
                                               class="mb-2"></b-form-datepicker>
                        </b-form-group>
                        <b-form-group label-for="endDate" v-bind:label="$t('Finish')">
                            <b-form-datepicker no-flip id="endDate" reset-button
                                               v-model="newItem.endDate"
                                               class="mb-2"
                                               :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                        </b-form-group>
                        <my-input id="price"
                                  label="Price"
                                  v-model="newItem.price"
                                  type="number"></my-input>
                    </template>

                    <b-button variant="success" @click="add">{{ $t('Add contact') }}</b-button>
                </b-card>
            </b-collapse>
        </div>
        <b-modal id="deleteAccountLinkConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete account link to the object?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { ObjectAccount } from '@/models/ObjectAccount'
    import SelectListItem from '@/models/SelectListItem'
    import FieldAccountSearch from '../Shared/FieldAccountSearch.vue'
    import { api } from '@/services/api'
    import { EventBus } from '../Shared/EventBus'
    import MyInput from '@/components/Shared/MyInput.vue'

    @Component({
        components: {
            FieldAccountSearch,
            MyInput
        }
    })
    export default class ObjectEditAccount extends Vue {
        @Prop({ type: Array, required: true })
        relationTypes!: Array<SelectListItem>

        @Prop(Number) objectId!: number | null

        @Prop({ type: Array, required: true })
        initialList!: Array<ObjectAccount>

        fields = ['relationName', 'account', 'edit', 'delete']

        list: Array<ObjectAccount> | null = null

        get list2Display(): Array<ObjectAccount> {
            return this.list === null ? this.initialList : this.list
        }

        showAddAccountBlock = false
        readonly RelationTypeTenantShort = 4
        readonly RelationTypeTenantLong = 7

        error: string | null = null

        newItem = {} as ObjectAccount
        add() {
            this.ensureListCreated()
            this.newItem.objectId = this.objectId
            api.post<ObjectAccount>('/api/objectAccount/add', this.newItem)
            this.showAddAccountBlock = false
            this.newItem.relationName = this.relationTypes[this.newItem.relation as number].text ?? null
            if (this.list !== null) this.list.push(this.newItem)
            this.newItem = {} as ObjectAccount
        }

        edit(row: any) {
            api.post<ObjectAccount>('/api/objectAccount/update', row.item)
                .then(() => row.toggleDetails())
        }

        deleteIndex?: number
        deleteItem?: ObjectAccount
        deleteRequest(item: ObjectAccount, index: number) {
            this.ensureListCreated()
            this.deleteItem = item
            this.deleteIndex = index
            this.$bvModal.show('deleteAccountLinkConfirmation')
        }

        ensureListCreated() {
            if (this.list === null) this.list = Object.assign([], this.initialList)
        }

        deleteConfirmed() {
            if (
                this.deleteIndex === undefined ||
                this.deleteItem === undefined ||
                this.deleteItem.accountId === undefined ||
                this.deleteItem.accountId === null
            ) {
                return
            }
            api.delExt('/api/objectAccount', {
                accountId: this.deleteItem.accountId,
                objectId: this.objectId
            }).then(() => {
                if (this.list !== null && this.deleteIndex !== undefined)
                    this.list.splice(this.deleteIndex, 1)
            })
        }

        mounted() {
            EventBus.$on(
                'FieldAccountSearch_DisplayedText_Changed',
                (newValue: string) => (this.newItem.account = newValue)
            )
        }
    }
</script>
