<template>
    <div class="container-md">
        <h1>{{ $t('Accounts') }}</h1>
        <b-button variant="primary" to="/Accounts/Add" class="mr-2 mb-2">{{ $t('Add') }}</b-button>
        <b-button variant="primary" v-on:click="merge" class="mr-2 mb-2">
            {{ mergeButtonTitle }}
        </b-button>
        <b-alert :show="selectMode">Select 2 accounts and click Merge button</b-alert>
        <table class="table table-hover">
            <sortable-columns :headers="headers"
                              :sortColumnIndex="sortColumnIndex"
                              :sortDescending="sortDescending"
                              v-on:onColumnClick="sortByColumn">
                <template v-if="selectMode" v-slot:firstColumns>
                    <th></th>
                </template>
            </sortable-columns>
            <tbody>
                <tr v-for="item in list.items" v-bind:key="item.id">
                    <td v-if="selectMode">
                        <b-form-checkbox v-model="item.selectedInListView"></b-form-checkbox>
                    </td>
                    <td>{{ item.createdOn | date_short }}</td>
                    <td>{{ item.createdBy }}</td>
                    <td>
                        <router-link :to="{ name: 'Accounts/View', params: { id: item.id } }">
                            {{ item.name }}
                        </router-link>
                    </td>
                    <td>{{ item.modifiedOn | date_short }}</td>
                    <td>{{ item.modifiedBy }}</td>
                </tr>
            </tbody>
        </table>
        <b-modal id="wrongMergeSelect" ok-only :title="mergeButtonTitle">
            <p>{{ wrongMergeSelectMsg }}</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Mixins } from 'vue-property-decorator'
    import NavItemLang from './../../components/NavItemLang.vue'
    import { api } from '@/services/api'
    import { AccountListQueryOutput } from '@/models/AccountListQueryOutput'
    import SortableColumns from '@/components/Shared/SortableColumns.vue'
    import SortableList from '@/views/Shared/SortableList.vue'

    @Component({
        components: {
            NavItemLang,
            SortableColumns
        }
    })
    export default class AccountList extends Mixins(SortableList) {
        public list: AccountListQueryOutput = new AccountListQueryOutput()
        constructor() {
            super()
            this.fetchData()
        }
        async fetchData(): Promise<void> {
            api.get<AccountListQueryOutput>('/api/account')
                .then(data => this.list = data)
        }
        headers = ['Created on', 'Created by', 'Name', 'Modified on', 'Modified by']
        columns = ['createdOn', 'createdBy', 'name', 'modifiedOn', 'modifiedBy']

        selectMode = false
        mergeButtonTitle = 'Merge'
        wrongMergeSelectMsg = ''
        merge(): void {
            if (this.selectMode) {
                let selectedItems = this.list.items.filter((i) => (i as ICanBeSelected).selectedInListView)
                if (selectedItems.length != 2) {
                    this.wrongMergeSelectMsg =
                        'You selected ' + selectedItems.length + ' accounts. Please select 2 accounts to merge!'
                    this.$bvModal.show('wrongMergeSelect')
                    return
                }
                let a1: string = selectedItems[0].id?.toString() ?? ''
                let a2: string = selectedItems[1].id?.toString() ?? ''
                this.$router.push({ name: 'Accounts/Merge', params: { a1: a1, a2: a2 } })
            }
            this.selectMode = !this.selectMode
        }
    }

    export interface ICanBeSelected {
        selectedInListView: boolean
    }
</script>

<style scoped lang="scss"></style>
