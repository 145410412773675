import { format } from 'date-fns'
import { i18n } from '@/i18n'

export function dateDefault(value: Date): string {
    if (!value) return ''
    return (
        new Date(value).toLocaleDateString(i18n.locale, {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric'
        }) +
        ' ' +
        new Date(value).toLocaleTimeString(i18n.locale, {
            hour: '2-digit',
            minute: 'numeric'
        })
    )
}

export function dateShort(value: Date): string {
    if (!value) return ''
    return new Date(value).toLocaleDateString(i18n.locale, {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
    })
}

export function dateX(date: Date): string {
    return format(new Date(date), 'eeee, dd MMMM')
}
export function dateTimeLong(value: Date): string {
    if (!value) return ''
    return (
        new Date(value).toLocaleDateString(i18n.locale, {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric'
        }) +
        ' ' +
        new Date(value).toLocaleTimeString(i18n.locale, {
            hour: '2-digit',
            minute: 'numeric',
            second: 'numeric'
        })
    )
}

