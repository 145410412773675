<template>
  <b-form-group v-bind:label="$t(label)">
    <b-form-radio-group
      v-bind:checked="value"
      v-on:change="$emit('input', $event)"
      :options="options"
    >
    </b-form-radio-group>
  </b-form-group>
</template>

<script>
export default {
  name: 'FieldFormBoolNullable',
  props: ['label', 'value'],
  computed: {
    options() {
      return [
        { text: this.$t('yes'), value: true },
        { text: this.$t('no'), value: false },
        { text: this.$t('undefined'), value: null }
      ]
    }
  }
}
</script>
