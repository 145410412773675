<template>
    <b-form class="my-form" @submit="send">
        <logo></logo>
        <div v-if="!requestSent">
            <div v-if="emailFromUrl">
                <p>Emal</p>
                <p>{{emailFromUrl}}</p>
            </div>
            <b-form-group v-else id="input-group-1"
                          label="Email"
                          label-for="reset-form-username">
                <b-form-input id="reset-form-username"
                              v-model="email"
                              type="email"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" label="Password" label-for="reset-form-password">
                <b-form-input id="reset-form-password"
                              type="password"
                              v-model="password"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-3" label="Confirm password" label-for="reset-form-confirm-password">
                <b-form-input id="reset-form-confirm-password"
                              type="password"
                              v-model="confirmPassword"
                              required></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-4">
                <b-form-checkbox id="checkbox-acceptTerms"
                                 v-model="acceptTerms"
                                 name="checkbox-acceptTerms"
                                 value="accepted"
                                 unchecked-value="not_accepted">
                    Accept <a href="/terms.htm" target="_blank">Terms and Conditions</a>
                </b-form-checkbox>
            </b-form-group>
            <b-alert v-if="alert.message" show :variant="alert.type" class="mb-2" v-html="alert.message"></b-alert>

            <b-button type="submit" variant="primary" :disabled="acceptTerms == 'not_accepted'">Submit</b-button>
        </div>
        <div v-if="requestSent" class="row justify-content-center align-items-center">
            Password Updated!<br>
        </div>
        <div v-if="requestSent" class="row justify-content-center align-items-center">
            <router-link to="/login">Login</router-link>
        </div>
    </b-form>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { api } from '@/services/api'
    import store from '@/store'
    import Logo from '@/components/Shared/Logo.vue'
    import AlertState from '@/models/AlertState'

    @Component({
        components: {
            Logo
        }
    })
    export default class ResetPassword extends Vue {
        email: string | null = null
        password: string | null = null
        confirmPassword: string | null = null
        acceptTerms = 'not_accepted'
        requestSent = false

        get code(): string {
            return this.$route.query.code as string;
        }
        get emailFromUrl(): string {
            return this.$route.query.email as string;
        }

        send(event: Event): void {
            event.preventDefault()
            store.dispatch('alert/clear');
            if (this.password != this.confirmPassword) {
                store.dispatch('alert/error', 'Passwords should match');
                return;
            }
            if (this.password) {
                api.post('/api/authenticate/resetPassword',
                    {
                        email: this.emailFromUrl ?? this.email,
                        password: this.password,
                        confirmPassword: this.confirmPassword,
                        code: this.code
                    })
                    .then(() => {
                        this.requestSent = true
                    })
            }
        }
        get alert(): AlertState {
            return this.$store.state.alert
        }
    }
</script>
<style>
    .my-form {
        margin-top: 60px;
        background-color: white;
        opacity: 0.8;
        padding: 10px;
        border-color: #1861ac;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
    }
</style>
