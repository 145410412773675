<template>
    <div>
        <b-alert v-if="error" show variant="danger">{{ $t(error) }}</b-alert>
        <b-form-textarea v-model="item.description"
                         id="description"
                         rows="3"
                         max-rows="16"
                         class="mt-2 mb-2"></b-form-textarea>
        <b-form inline>
            <b-form-select v-model="type" :options="types" class="mr-2"></b-form-select>
            <FieldSearch v-on:hit="onLinkAdd" v-bind:clearOnHit="true" placeholder="search for link" class="mr-2"></FieldSearch>
            <span
              v-for="(link, index) in item.links"
              v-bind:key="link.id"
              class="border border-secondary pl-1 rounded mr-2"
            >
              {{ link.description }}
              <b-button class="btn btn-light btn-sm" aria-label="Close" v-on:click="onLinkDelete(index)">
                <span aria-hidden="true">&times;</span>
              </b-button>
            </span>
        </b-form>
        <file-pond name="activityFiles"
                   ref="pond"
                   label-idle="Drop files here..."
                   v-bind:allow-multiple="true"
                   accepted-file-types="image/*, video/*, audio/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/rtf, application/vnd.visio, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xml, application/zip, application/x-7z-compressed"
                   server="/api/files"
                   v-bind:files="files"
                   v-on:processfile="fileAdded" />
        <b-modal id="linkDeleteConfirmation" title="Please confirm" @ok="linkDeleteConfirmed">
            <p class="my-4">Delete a link?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { ActivityDto2, ActivityLinkDto } from '@/models/ActivityDto2'
    import { Debounce } from 'vue-debounce-decorator'
    import FieldSearch from '../../components/Shared/FieldSearch.vue'

    import vueFilePond from 'vue-filepond'
    import 'filepond/dist/filepond.min.css'
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

    import {
        SearchOutputItem,
        toEntityType,
        SearchQueryOutputItemType
    } from '../../models/SearchOutput'

    @Component({ components: { FilePond, FieldSearch } })
    export default class ActvitiyEdit extends Vue {
        @Prop({ type: Object, required: true }) activity!: ActivityDto2

        get item(): ActivityDto2 {
            return this.activity
        }
        public types = ['category undefined', 'call', 'meeting', 'viewing']
        public type = 'category undefined'

        error: string | null = null
        get files(): any[] | null {
            return this.item.files == null
                ? null
                : this.item.files.map(function (fileLink) {
                    return { options: { type: 'limbo' }, source: fileLink.systemName }
                })
        }

        @Debounce(400)
        @Watch('item', { deep: true })
        save() {
            api.post('/api/activity/update', this.item)
        }

        fileAdded(error: string, file: any) {
            console.log(error)
            console.log(file)
            if (error) this.error = error
            else {
                if (
                    !this.item.files ||
                    !this.item.files.find(function (element) {
                        if (element.systemName == file.serverId) return true
                    })
                ) {
                    this.item.addedFiles =
                        this.item.addedFiles == null ? file.serverId : this.item.addedFiles + ',' + file.serverId
                }
            }
        }

        onLinkAdd(searchedItem: SearchOutputItem) {
            if (!this.item.links) this.item.links = []
            const link = {} as ActivityLinkDto
            link.entity = toEntityType(searchedItem.entity as SearchQueryOutputItemType)
            link.id = searchedItem.id
            link.description = searchedItem.description
            this.item.links.push(link)
        }

        link2DeleteIndex: number | null = null

        onLinkDelete(index: number) {
            this.link2DeleteIndex = index
            this.$bvModal.show('linkDeleteConfirmation')
        }

        linkDeleteConfirmed() {
            if (!this.link2DeleteIndex || !this.item.links) {
                this.error = 'Cannot delete the only link'
                return
            }
            this.item.links.splice(this.link2DeleteIndex, 1)
        }
    }
</script>

<style scoped lang="scss"></style>
