import { EntityType } from './Enums'
export class SearchOutput {
  items: SearchOutputItem[] = []
}

export class SearchOutputItem {
  id?: string
  description?: string
  entity?: SearchQueryOutputItemType | null
  public constructor(init?: Partial<SearchOutputItem>) {
    Object.assign(this, init)
  }
}

export enum SearchQueryOutputItemType {
  Account,
  Object,
  Owner,
  Client,
  Lead,
  Professional,
  Source,
  Employe
}

export function toEntityType(src: SearchQueryOutputItemType): EntityType {
  switch (src) {
    case SearchQueryOutputItemType.Lead:
      return EntityType.Demand
    case SearchQueryOutputItemType.Object:
      return EntityType.Object
    default:
      return EntityType.Account
  }
}
