<template>
  <thead>
    <tr>
      <slot name="firstColumns"></slot>
      <th
        v-for="(header, i) in headers"
        :key="i"
        v-on:click="onColumnClick(i)"
        title="click to sort"
      >
        {{ header
        }}<span v-if="i == sortColumnIndex"
          ><span v-if="sortDescending">&#x25B4;</span><span v-else>&#x25BE;</span></span
        >
      </th>
      <slot name="lastColumns"></slot>
    </tr>
  </thead>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class SortableColumns extends Vue {
  @Prop() headers?: Array<string>
  @Prop() sortColumnIndex?: boolean
  @Prop() sortDescending?: boolean

  onColumnClick(columnIndex: number): void {
    this.$emit('onColumnClick', columnIndex)
  }
}
</script>

<style scoped lang="scss">
th {
  cursor: pointer;
}
</style>
