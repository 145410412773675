<template>
    <div class="container-md mt-2">
        <div id="loadingSpinner" v-if="!loaded" class="d-flex">
            <b-spinner small label="Busy"></b-spinner><span class="ml-1">{{ $t('Loading...') }}</span>
        </div>
        <b-form v-if="loaded">
            <error-box :error="error" />
            <b-table-simple small responsive caption-top>
                <caption>{{caption}}</caption>
                <b-tbody>
                    <b-tr>
                        <b-td class="text-right">{{$t('Amount')}}:</b-td>
                        <b-td>{{expense.amount | currency_2}}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td class="text-right">{{$t('Category')}}:</b-td>
                        <b-td>{{expense.categoryName}}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td class="text-right">{{$t('Date')}}:</b-td>
                        <b-td>{{expense.transactionDate | date_short}}</b-td>
                    </b-tr>
                    <b-tr>
                        <b-td class="text-right">{{$t('Attachments')}}:</b-td>
                        <b-td>
                            <span v-for="file in expense.attachments" v-bind:key="file.id" class="mr-4">
                                <a :href="'/api/transactions/files/' + file.id"
                                   target="_blank">
                                    <font-awesome-icon class="mr-1" icon="file" />
                                    {{ file.name }}
                                </a>
                            </span>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <b-form-row class="mt-4">
                <b-button v-if="!expense.confirmedByClientDate" v-on:click="approve" variant="success" class="mr-2">{{$t('Approve')}}</b-button>
            </b-form-row>
            <b-form-row class="mt-4">
                <b-alert variant="success" show v-if="expense.confirmedByClientDate">{{$t('Expense has been approved. You can close this window.')}}</b-alert>
            </b-form-row>
            <b-form-row class="mt-4">
                <b-button v-if="expense.canRevertApproval" v-on:click="revertApproval" variant="danger" class="mr-2">{{$t('Revert approval')}}</b-button>
            </b-form-row>
        </b-form>
    </div>
</template>

<script lang="ts">
    import { Component, Mixins, Watch } from 'vue-property-decorator'
    import { Route } from 'vue-router'
    import BaseComponent from '@/components/Shared/BaseComponent.vue'
    import { api } from '@/services/api'
    import { TransactionViewExpenseQueryOutput } from '@/models/Transactions/TransactionViewExpenseQueryOutput'

    @Component
    export default class TransactionExpenseView extends Mixins(BaseComponent) {
        expense: TransactionViewExpenseQueryOutput = {} as TransactionViewExpenseQueryOutput
        error: Error | null = null
        loaded = false
        id: string = ''
        get caption(): string {
            return 'Please review and approve the expense'
        }

        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            this.id = r.params.id
            this.fetchData()
        }

        fetchData(): void {
            if(!this.id) return
            api.get<TransactionViewExpenseQueryOutput>(`/api/transactions/viewExpense/${this.id}`)
                .then((data) => {
                    this.expense = data
                    this.loaded = true
                })
                .catch((error: Error) => {
                    this.error = error
                    this.loaded = true
                })
        }
        approve(): void {
            if (!this.id) return
            api.postOut<TransactionViewExpenseQueryOutput>(`/api/transactions/approveExpense/${this.id}`)
                .then((data) => {
                    this.expense = data
                    this.loaded = true
                })
                .catch((error: Error) => {
                    this.error = error
                    this.loaded = true
                })
        }
        revertApproval(): void {
            if (!this.id) return
            api.postOut<TransactionViewExpenseQueryOutput>(`/api/transactions/revertApprovalExpense/${this.id}`)
                .then((data) => {
                    this.expense = data
                    this.loaded = true
                })
                .catch((error: Error) => {
                    this.error = error
                    this.loaded = true
                })
        }
        get transactionDate(): string {
            return !this.expense ||
                this.expense.transactionDate == undefined ||
                this.expense.transactionDate.length < 19
                ? ''
                : this.expense.transactionDate.substr(0, 10)
        }
        set transactionDate(value: string) {
            if (
                !this.expense ||
                this.expense.transactionDate == undefined ||
                this.expense.transactionDate.length < 19
            )
                return
            this.expense.transactionDate =
                value + this.expense.transactionDate.substr(10, 25)
        }

        get transactionTime(): string {
            return !this.expense ||
                this.expense.transactionDate == undefined ||
                this.expense.transactionDate.length < 19
                ? ''
                : this.expense.transactionDate.substr(11, 8)
        }
        set transactionTime(value: string) {
            if (
                !this.expense ||
                this.expense.transactionDate == undefined ||
                this.expense.transactionDate.length < 19
            )
                return
            this.expense.transactionDate =
                this.expense.transactionDate.substr(0, 11) +
                value +
                this.expense.transactionDate.substr(19, 14)
        }
    }
</script>
