<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import SelectListItem from '@/models/SelectListItem'

@Component
export default class BaseComponent extends Vue {
  public translateSelectList(src: SelectListItem[]): SelectListItem[] {
    //const self = this
    if (!src) return []
    return src.map((v: SelectListItem) => {
      return new SelectListItem({
        parent: v.parent,
        value: v.value,
        text: this.$tc(v.text as string)
      })
    })
  }
  public back(): void {
    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
  }
}
</script>
