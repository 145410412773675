<template>
    <div class="container-md mt-2">
        <h1>{{ $t('Document') }}</h1>
        <div id="loadingSpinner" v-if="!loaded" class="d-flex">
            <b-spinner small label="Busy"></b-spinner><span class="ml-1">{{ $t('Loading...') }}</span>
        </div>
        <b-form v-if="loaded">
            <b-form-row class="mb-2">
                <b-col class="col-12">
                    <label for="description">{{ $t('Description') }}</label>
                    <b-form-input v-model.number="data.description"
                                  id="description"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
                <b-col class="col-12">
                    <label for="name">{{ $t('File name') }}</label>
                    <b-form-input v-model.number="data.name"
                                  id="name"
                                  :readonly="mode == MODE_VIEW"></b-form-input>
                </b-col>
            </b-form-row>
            <b-form-row class="mb-2">
                <b-col class="col-12">
                    <b-form-group v-bind:label="$t('File')">
                        <b-form-file v-model="data.file"
                                     multiple
                                     placeholder="Choose a file or drop it here..."
                                     drop-placeholder="Drop file here..."></b-form-file>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <!--<b-form-row class="mt-4" v-if="mode != MODE_CREATE">
                {{ $t('Created') }}: {{ data.createdOn | date }} by {{ data.createdByAccountDisplayName }} |
                {{ $t('Modified') }}: {{ data.modifiedOn | date }} {{ data.modifiedByAccountDisplayName }}
            </b-form-row>-->
            <b-form-row class="mt-2">
                <b-col class="col-12">
                    <b-button v-if="mode != MODE_VIEW" v-on:click="save" variant="success" class="mr-2">{{$t('Save')}}</b-button>
                    <b-button v-if="mode != MODE_VIEW" v-on:click="back" class="mr-2">{{$t('Cancel')}}</b-button>
                    <b-button v-if="mode == MODE_EDIT" v-on:click="deleteRequest" variant="danger">{{$t('Delete')}}</b-button>
                    <b-button v-if="mode == MODE_VIEW" v-on:click="edit" variant="success" class="mr-2">{{$t('Edit')}}</b-button>
                    <b-button v-if="mode == MODE_VIEW" v-on:click="back" class="mr-2">{{$t('Back')}}</b-button>
                </b-col>
            </b-form-row>
        </b-form>
        <b-modal id="deleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">{{$t('Delete the document?')}}</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
import { Component, Watch, Mixins } from 'vue-property-decorator'
import { Route } from 'vue-router'
import BaseComponent from '@/components/Shared/BaseComponent.vue'
import { api } from '@/services/api'
import { DocumentDto } from '@/models/DocumentDto'

@Component
export default class DocumentEdit extends Mixins(BaseComponent) {
        id: string | null = null
        data: DocumentDto | null = null
        loaded = false

        readonly MODE_UNDEFINED = 0
        readonly MODE_CREATE = 1
        readonly MODE_EDIT = 2
        readonly MODE_VIEW = 3
        private mode = this.MODE_UNDEFINED

        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            console.log('hiiiiiiiiiii')
            console.log(r.params.id)
            this.id = r.params.id == 'Add' ? null : r.params.id
            this.mode = this.id ? this.MODE_VIEW : this.MODE_CREATE
            if (this.mode == this.MODE_CREATE) {
                this.data = {} as DocumentDto
                this.loaded = true
                return
            }
            api.get<DocumentDto>(`/api/Documents/edit/${this.id}`)
                .then((data) => {
                    this.data = data
                    this.loaded = true
                })
        }
        save(): void {
            api.postFormData<DocumentDto>('/api/Documents/update', this.data as DocumentDto)
                .then(() => { this.back() })
        }
        deleteRequest(): void {
            this.$bvModal.show('deleteConfirmation')
        }
        deleteConfirmed(): void {
            if (this.mode != this.MODE_EDIT) return

            api.del(`/api/Documents/${this.id}`)
                .then(() => {
                    this.back()
                })
        }
        edit(): void {
            this.mode = this.MODE_EDIT
        }
        view(): void {
            this.mode = this.MODE_VIEW
        }
}
</script>

<style scoped></style>
