<template>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Specialists') }}</h1>
            </div>
            <div class="col-4 text-right">
                <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{list.items.length}}</b-badge>
            </div>
        </div>
        <b-button variant="primary" to="/Accounts/AddProfessional">{{ $t('Add') }}</b-button>
        <b-table :items="list.items" :fields="fields" primary-key="id" hover :busy="isBusy"
                 sticky-header="70vh"
                 :per-page="perPage"
                 :current-page="currentPage">
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(createdOn)="data">
                {{ data.item.createdOn | date_short }}
            </template>
            <template #cell(modifiedOn)="data">
                {{ data.item.modifiedOn | date_short }}
            </template>
            <template #cell(name)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.id } }">
                    {{ data.item.name }}
                </router-link>
            </template>
            <template #cell(speciality)="data">
                {{ data.item.isNotary ? $t('Notary') : '' }}
                {{ data.item.isAttorney ? $t('Attorney') : '' }}
                {{ data.item.isLawyer ? $t('Lawyer') : '' }}
                {{ data.item.isWorker ? $t('Worker') : '' }}
                {{ data.item.isElectrician ? $t('Electrician') : '' }}
                {{ data.item.isConstructionEngineer ? $t('ConstructionEngineer') : '' }}
                {{ data.item.isCleaner ? $t('Cleaner') : '' }}
                {{ data.item.isInteriorDesigner ? $t('InteriorDesigner') : '' }}
                {{ data.item.isTranslator ? $t('Translator') : '' }}
                {{ data.item.isAccountant ? $t('Accountant') : '' }}
                {{ data.item.isEconomist ? $t('Economist') : '' }}
                {{ data.item.isLandscapeDesigner ? $t('LandscapeDesigner') : '' }}
                {{ data.item.isSecurityService ? $t('SecurityService') : '' }}
                {{ data.item.isPlumber ? $t('Plumber') : '' }}
                {{ data.item.isLocksmith ? $t('Locksmith') : '' }}
                {{ data.item.isIT ? $t('IT') : '' }}
                {{ data.item.isWebDesigner ? $t('WebDesigner') : '' }}
                {{ data.item.isEnergySupplyCompany ? $t('EnergySupplyCompany') : '' }}
                {{ data.item.isElectricalStore ? $t('ElectricalStore') : '' }}
                {{ data.item.isTechnician ? $t('Technician') : '' }}
                {{ data.item.isRealEstateAgencyOrBroker ? $t('RealEstateAgencyOrBroker') : '' }}
            </template>
        </b-table>
        <b-row>
            <b-col sm="2" md="3" class="my-1">
                <b-form-group label="Per page"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-form-select id="per-page-select"
                                   v-model="perPage"
                                   :options="pageOptions"
                                   size="sm" v-on:input="onPerPageChanged"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="fill"
                              size="sm"
                              class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { ProfessionalListQueryOutput } from '@/models/ProfessionalListQueryOutput'

    @Component
    export default class ProfessionalList extends Vue {
        public list: ProfessionalListQueryOutput = new ProfessionalListQueryOutput()
        isBusy = true

        totalRows = 1
        currentPage = 1
        perPage = 10
        pageOptions = [5, 10, 20, 50, { value: 1000000, text: "Show all" }]

        fields = [
            { key: 'createdOn', label: 'Created on', sortable: true },
            { key: 'createdBy', label: 'Created by', sortable: true },
            { key: 'name', label: 'Name', sortable: true },
            { key: 'speciality', label: 'Speciality', sortable: false },
            { key: 'modifiedOn', label: 'Modified on', sortable: true },
            { key: 'modifiedBy', label: 'Modified by', sortable: true },
        ]

        created() {
            this.fetchData()
            this.perPage = this.$cookies.get('ProfessionalList.perPage') as number ?? 20
        }
        fetchData(): void {
            api.get<ProfessionalListQueryOutput>('/api/professional')
                .then(data => {
                    this.list = data
                    this.totalRows = data.items.length
                    this.isBusy = false
                })
        }
        onPerPageChanged(): void {
            this.$cookies.set('ProfessionalList.perPage', this.perPage)
        }
    }
</script>

<style scoped lang="scss"></style>
