<template>
    <div align="center">
        <template v-if="isCrm">
            <img src="../../assets/logo.png" />
            <h5>Odysseus</h5>
            <p>Όμοιος ομοίω αεί πελάζει</p>
        </template>
        <template v-if="!isCrm">
            <img src="../../assets/logo_payments.png" />
        </template>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { Hosts, myHost } from '@/services/hosts'

    @Component
    export default class Logo extends Vue {
        get isCrm(): boolean {
            return myHost.is() == Hosts.Crm;
        }
    }
</script>

<style scoped>
</style>
