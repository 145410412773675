var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.lat)?_c('gmap-map',{staticStyle:{"width":"100%","height":"400px"},attrs:{"center":_vm.mapCenter,"zoom":_vm.zoomCalc}},[_c('gmap-marker',{attrs:{"position":_vm.position}}),(_vm.approxRadius != 0)?_c('gmap-circle',{attrs:{"center":_vm.approxPosition,"radius":_vm.approxRadius,"options":{
        fillColor: 'blue',
        fillOpacity: 0.35,
        strokeColor: 'blue',
        strokeOpacity: 0.8,
        strokeWeight: 1
      }}}):_vm._e()],1):_c('p',[_vm._v(_vm._s(_vm.$t('Map location not set')))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }