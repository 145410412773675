<template>
    <div>
        <navbar :showBackButton="true" :header="header">
            <template v-slot:visibleButtons>
                <b-nav-text class="mr-2">
                    <toggle-button :width="25 + $tc('Edit').length * 6"
                                   :value="false"
                                   :labels="{ checked: $tc('Edit'), unchecked: $tc('Edit') }"
                                   v-on:change="viewMode = viewMode === VIEW_MODE_VIEW ? VIEW_MODE_EDIT : VIEW_MODE_VIEW" />
                </b-nav-text>
                <b-nav-text v-if="viewMode != VIEW_MODE_VIEW">
                    <b-button class="mr-2"
                              v-on:click="
              viewMode =
                viewMode === VIEW_MODE_EDIT_EXPANDED ? VIEW_MODE_EDIT : VIEW_MODE_EDIT_EXPANDED
            "
                              size="sm">{{ editModeLabel }}</b-button>
                </b-nav-text>
            </template>
            <template v-slot:hiddenButtons>
                <b-dropdown-item v-on:click="deleteRequest()">
                    <font-awesome-icon size="lg" icon="trash" /> {{ $t('Delete object') }}
                </b-dropdown-item>
            </template>
        </navbar>
        <div class="container mt-3">
            <div>
                <objectViewFields v-if="viewMode != VIEW_MODE_EDIT_EXPANDED"
                                  v-on:referenceNumberReceived="referenceNumber = $event"
                                  v-on:idReceived="id = $event"
                                  :view-mode="viewMode"></objectViewFields>
                <objectEditRequest :id="id" v-else></objectEditRequest>
            </div>
        </div>
        <b-modal id="deleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete object #{{ referenceNumber }}?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import navbar from '@/components/Navbar.vue'
    import ObjectEditRequest from '@/components/Objects/ObjectEditRequest.vue'
    import ObjectViewFields from '@/components/Objects/ObjectViewFields.vue'
    import { api } from '@/services/api'
    import { VIEW_MODE_VIEW, VIEW_MODE_EDIT, VIEW_MODE_EDIT_EXPANDED } from '@/misc/viewConst'

    @Component({
        components: {
            navbar,
            ObjectViewFields,
            ObjectEditRequest
        }
    })
    export default class ObjectView extends Vue {
        id: number | null = null
        referenceNumber = ''
        buttons: string[] = ['edit']
        viewMode = 0 //this.VIEW_MODE_VIEW

        created(): void {
            const routeId = this.$route.params.id
            console.log(routeId)
            console.log(routeId.substring(3, 7))
            if (this.$route.params.viewMode) {
                this.viewMode = parseInt(this.$route.params.viewMode)
            }
            if (routeId.toString().substring(0, 3) === 'ref') this.referenceNumber = routeId.substring(3, 7)
            else this.id = Number(routeId)
        }

        // workaround for [Vue warn]: Property or method "VIEW_MODE_EDIT_EXPANDED" is not defined on the instance but referenced during render. Make sure that this property is reactive, either in the data option, or for class-based components, by initializing the property. See: https://vuejs.org/v2/guide/reactivity.html#Declaring-Reactive-Properties.
        public VIEW_MODE_VIEW = VIEW_MODE_VIEW
        public VIEW_MODE_EDIT = VIEW_MODE_EDIT
        public VIEW_MODE_EDIT_EXPANDED = VIEW_MODE_EDIT_EXPANDED

        get header(): string {
            return this.$tc('Object') + ' #' + this.referenceNumber
        }

        get editModeLabel(): string {
            return this.viewMode === VIEW_MODE_EDIT_EXPANDED ? this.$tc('Collapse') : this.$tc('More')
        }

        deleteRequest(): void {
            this.$bvModal.show('deleteConfirmation')
        }

        deleteConfirmed(): void {
            if (this.id === null) return
            api
                .del(`/api/object/${this.id}`)
                .then(() => {
                    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
                })
        }
    }
</script>

<style scoped></style>
