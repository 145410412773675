import { render, staticRenderFns } from "./LeadList.vue?vue&type=template&id=88fbee34&scoped=true"
import script from "./LeadList.vue?vue&type=script&lang=ts"
export * from "./LeadList.vue?vue&type=script&lang=ts"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./LeadList.vue?vue&type=style&index=1&id=88fbee34&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88fbee34",
  null
  
)

export default component.exports