<template>
  <div class="container-fluid" v-if="alert">
    <b-card>
      <b-card-header>
        <b-button @click="backToList" variant="secondary" class="mr-2">Back to List</b-button>
        <b-button @click="markAsUnread" variant="warning" class="mr-2">Mark as Unread</b-button>
        <b-button @click="saveNotes" variant="primary" class="mr-2">Save Notes</b-button>
        <b-button @click="deleteAlert" variant="danger">Delete</b-button>
      </b-card-header>
      <b-card-body>
        <h5>{{ alert.content }}</h5>
        <p><strong>Source:</strong> {{ alert.source }}</p>
        <p><strong>Date:</strong> {{ alert.date | date }}</p>
        <b-form-textarea v-model="alert.notes" rows="5" placeholder="Enter notes here..."></b-form-textarea>
      </b-card-body>
    </b-card>
  </div>
</template>

<script lang="ts">
  import { Component, Watch, Vue } from 'vue-property-decorator'
  import { Route } from 'vue-router'
  import { api } from '@/services/api';

  @Component
  export default class AlertDetail extends Vue {
    alert: Alert | null = null;

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(r: Route): void {
      let id = Number(r.params.id)
      api.get<Alert>(`/api/alert/${id}`)
        .then((data) => {
          this.alert = data;
        });
    }

    markAsUnread() {
      if (!this.alert) return;
      api.post(`/api/alert/mark-as-unread/${this.alert.id}`, {})
        .then(() => {
          this.$router.push({ name: 'AlertList' });
        });
    }

    saveNotes() {
      if (!this.alert) return;
      api.post(`/api/alert/update-notes/${this.alert.id}`, { notes: this.alert.notes });
    }

    deleteAlert() {
      if (!this.alert) return;
      api.del(`/api/alert/${this.alert.id}`)
        .then(() => {
          this.$router.push({ name: 'AlertList' });
        });
    }

    backToList() {
      this.$router.push({ name: 'AlertList' });
    }
  }

  interface Alert {
    id: number;
    content: string;
    source: string;
    date: string;
    readedOn: string | null;
    notes?: string;
  }
</script>
