import { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Test from '../views/Test.vue'
import FullScreen from '../views/FullScreen.vue'
import Crm from '../views/CRM.vue'
import MainPage from '../views/Misc/MainPage.vue'
import OwnerList from '../views/Accounts/OwnerList.vue'
import ClientList from '../views/Accounts/ClientList.vue'
import ProfessionalList from '../views/Accounts/ProfessionalList.vue'
import PartnerList from '../views/Accounts/PartnerList.vue'
import SourceList from '../views/Accounts/SourceList.vue'
import EmployeList from '../views/Accounts/EmployeList.vue'
import AccountAdd from '../views/Accounts/AccountAdd.vue'
import AccountView from '../views/Accounts/AccountView.vue'
import AccountList from '../views/Accounts/AccountList.vue'
import TransactionAccountList from '../views/Transactions/TransactionAccountList.vue'
import TransactionAccountView from '../views/Transactions/TransactionAccountView.vue'
import TransactionIncome from '../views/Transactions/TransactionIncome.vue'
import TransactionExpense from '../views/Transactions/TransactionExpense.vue'
import TransactionAccountAdd from '../views/Transactions/TransactionAccountAdd.vue'
import AccountMerge from '../views/Accounts/AccountMerge.vue'
import ObjectList from '../views/Objects/ObjectList.vue'
import ObjectView from '../views/Objects/ObjectView.vue'
import ObjectAdd from '../views/Objects/ObjectAdd.vue'
import ObjectSearch from '../views/Objects/ObjectSearch.vue'
import LeadList from '../views/Leads/LeadList.vue'
import LeadAdd from '../views/Leads/LeadAdd.vue'
import LeadView from '../views/Leads/LeadView.vue'
import Login from '@/components/Auth/Login.vue'
import ForgotPassword from '@/components/Auth/ForgotPassword.vue'
import ResetPassword from '@/components/Auth/ResetPassword.vue'
import AdminSettings from '../views/Misc/AdminSettings.vue'
import ActionLog from '../views/Misc/ActionLog.vue'
import ChangeEmployeRole from '../views/Accounts/ChangeEmployeRole.vue'
import SetPassword from '../views/Accounts/SetPassword.vue'
import DealList from '../views/Deals/DealList.vue'
import DealEdit from '../views/Deals/DealEdit.vue'
import ReservationList from '../views/Reservations/ReservationList.vue'
import ReservationEdit from '../views/Reservations/ReservationEdit.vue'
import ViewingList from '../views/Viewing/ViewingList.vue'
import ViewingEdit from '../views/Viewing/ViewingEdit.vue'
import ObjectKeyList from '../views/ObjectKeys/ObjectKeyList.vue'
import ObjectKeyEdit from '../views/ObjectKeys/ObjectKeyEdit.vue'
import DocumentList from '../views/Misc/DocumentList.vue'
import DocumentEdit from '../views/Misc/DocumentEdit.vue'
import AlertList from '../views/Alerts/AlertList.vue';
import AlertDetail from '../views/Alerts/AlertDetail.vue';

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/Test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/FullScreen',
    name: 'FullScreen',
    component: FullScreen,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      {
        path: '/ForgotPassword',
        name: 'ForgotPassword',
        component: ForgotPassword
      },
      {
        path: '/ResetPassword',
        name: 'ResetPassword',
        component: ResetPassword
      }
    ]
  },
  {
    path: '/CRM',
    name: 'CRM',
    component: Crm,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'mainPage',
        component: MainPage,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Objects',
        name: 'objects',
        component: ObjectList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Objects/View/:id',
        name: 'Objects/View',
        component: ObjectView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Objects/View/:id/:viewMode',
        name: 'Objects/View',
        component: ObjectView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Objects/Add',
        component: ObjectAdd,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Objects/Search',
        component: ObjectSearch,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/AddClient',
        component: AccountAdd,
        props: { input: { isClient: true } },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/AddPayment',
        component: AccountAdd,
        props: { input: { hasTransactions: true } },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/AddProfessional',
        component: AccountAdd,
        props: { input: { isProfessional: true } },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/AddPartner',
        component: AccountAdd,
        props: { input: { isPartnerRealEstate: true } },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/AddOwner',
        component: AccountAdd,
        props: { input: { isOwner: true } },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/AddSource',
        component: AccountAdd,
        props: { input: { isSource: true } },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/AddEmploye',
        component: AccountAdd,
        props: { input: { isEmploye: true } },
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/View/:id',
        name: 'Accounts/View',
        component: AccountView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Accounts/View/:id/:viewMode',
        name: 'Accounts/View',
        component: AccountView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/AllAccounts',
        name: 'allAccounts',
        component: AccountList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Owners',
        name: 'owners',
        component: OwnerList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Clients',
        name: 'clients',
        component: ClientList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Partners',
        name: 'partners',
        component: PartnerList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Professionals',
        name: 'professionals',
        component: ProfessionalList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Employes',
        name: 'employes',
        component: EmployeList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Sources',
        name: 'sources',
        component: SourceList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Payments',
        name: 'payments',
        component: TransactionAccountList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Payments/View/:id',
        name: 'Payments/View',
        component: TransactionAccountView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Payments/AddIncome/:accountId',
        name: 'Payments/AddIncome',
        component: TransactionIncome,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Payments/EditIncome/:id',
        name: 'Payments/EditIncome',
        component: TransactionIncome,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Payments/AddExpense/:accountId',
        name: 'Payments/AddExpense',
        component: TransactionExpense,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Payments/EditExpense/:id',
        name: 'Payments/EditExpense',
        component: TransactionExpense,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Payments/View/Income/:id',
        name: 'Payments/View/Income',
        component: TransactionIncome,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Payments/AccountAdd',
        name: 'Payments/AccountAdd',
        component: TransactionAccountAdd,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Leads',
        name: 'leads',
        component: LeadList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Leads/Add',
        name: 'Leads/Add',
        component: LeadAdd,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Leads/Add/:id',
        component: LeadAdd,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Leads/View/:id',
        name: 'Leads/View',
        component: LeadView,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Leads/View/:id/:viewMode',
        name: 'Leads/View',
        component: LeadView,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'Accounts/Merge',
        path: '/Accounts/Merge/a1/:a1/a2/:a2',
        component: AccountMerge,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'Settings',
        path: '/Settings',
        component: AdminSettings,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'ActionLog',
        path: '/ActionLog',
        component: ActionLog,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'ChangeEmployeRole',
        path: '/ChangeEmployeRole/:id',
        component: ChangeEmployeRole,
        meta: {
          requiresAuth: true
        }
      },
      {
        name: 'SetPassword',
        path: '/SetPassword/:id',
        component: SetPassword,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Deals',
        name: 'Deals',
        component: DealList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Deals/:id',
        name: 'Deals/Edit',
        component: DealEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Deads/Add',
        component: DealEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Reservations',
        name: 'Reservations',
        component: ReservationList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Reservations/:id',
        name: 'Reservations/Edit',
        component: ReservationEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Reservations/Add',
        component: ReservationEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Viewing',
        name: 'Viewing',
        component: ViewingList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Viewing/:id',
        name: 'Viewing/Edit',
        component: ViewingEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Viewing/Add',
        component: ViewingEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ObjectKeys',
        name: 'ObjectKeys',
        component: ObjectKeyList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/ObjectKeys/:id',
        name: 'ObjectKeys/Edit',
        component: ObjectKeyEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/DocumentList',
        name: 'DocumentList',
        component: DocumentList,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Document/:id',
        name: 'Document/Edit',
        component: DocumentEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Document/Add',
        component: DocumentEdit,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/Alerts',
        name: 'AlertList',
        component: AlertList
      },
      {
        path: '/Alerts/:id',
        name: 'AlertDetail',
        component: AlertDetail
      },
    ]
  }
]

export default routes
