<template>
  <span v-if="type == 'url'"
        v-bind:class="{ dottedUnderline: edit }"
        v-on:click="click"
        class="mr-3">
    <a :href="value" target="_blank">{{value}}</a>
  </span>
  <span v-else-if="type == 'boolNullable'"
        v-bind:class="{ dottedUnderline: edit }"
        v-on:click="click"
        class="mr-3">
    <b-icon :icon="value ? 'check' : 'stop'" :variant="value ? 'success' : 'danger'"></b-icon>
    {{ $t(name) }}
  </span>
  <span v-else
        v-bind:class="{ dottedUnderline: edit }"
        v-on:click="click"
        v-html="formattedValue"
        class="mr-1"></span>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Field extends Vue {
  @Prop(String) readonly name?: string
  @Prop(String) readonly type?: string
  @Prop([String, Number, Boolean]) readonly value?: string | number | boolean
  @Prop(Boolean) readonly edit?: boolean
  @Prop(String) readonly suffix?: string
  @Prop([String, Number]) readonly entityId?: string | number
  get myValue(): string {
    if (!this.value) return ''

    var result = this.value.toString()

    if (this.type == 'area') {
      result = this.value.toString() + ' ' + this.$t('m<sup>2</sup>')
    }
    if (this.type == 'currency') {
      result = Vue.filter('currency')(result)
    }
    if (this.type == 'distance') {
      result =
        this.value >= 1000
          ? (Math.round(((this.value as number) / 1000) * 10) / 10).toString() +
            ' ' +
            this.$t('km.')
          : this.value.toString() + ' ' + this.$t('m.')
    }
    if (this.type == 'distance-km') {
      result = this.value.toString() + ' ' + this.$t('km.')
    }
    if (this.type == 'flag-enum') {
      var values = (this.value as string).split(', ')
      var translatedValues = values.map((v) => this.$t(v))
      result = translatedValues.join(', ')
    }
    if (this.type == 'date') {
      let d = new Date(this.value as string)
      result = d.toLocaleDateString()
    } else if (typeof this.value === 'string') {
      result = this.$t(this.value).toString()
    }

    return result + this.getTranslatedSuffix()
  }

  click(): void {
    if (this.edit) this.$emit('click', this.name, this.type, this.entityId, this.value)
  }

  get formattedValue(): string {
    return this.myValue.replace(/\n/g, '<br>')
  }

  getTranslatedSuffix(): string {
    if (!this.suffix) return ''
    return this.suffix.replace(this.suffix.trim(), this.$t(this.suffix.trim()).toString())
  }
}
</script>

<style scoped>
.dottedUnderline {
  border-bottom: 1px dotted;
  cursor: pointer;
}
</style>
