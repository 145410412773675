<template>
    <div class="container-md mt-2">
        <h1>{{ $t('Keys for ') }} <span v-if="data && data.objectReferenceNumber">#{{data.objectReferenceNumber}}</span></h1>
        <b-form v-if="data">
            <b-form-row>
                <b-col class="col-4">
                    <label>{{ $t('Given to') }}</label>
                    <field-account-search v-model="data.accountId" :initialText="data.account" reset_button="true" />
                </b-col>
                <b-col class="col-4">
                    <label for="Received">{{ $t('Received') }}</label>
                    <b-form-datepicker no-flip id="Received" reset-button
                                       v-model="data.received"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col class="col-4">
                    <label for="GivenAway">{{ $t('Given away') }}</label>
                    <b-form-datepicker no-flip id="GivenAway" reset-button
                                       v-model="data.givenAway"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                </b-col>
                <b-col class="col-8">
                    <b-alert show variant="warning">Please reset one of the date above if they are the same</b-alert>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-12">
                    <b-form-textarea v-model="data.description"
                                     id="notes"
                                     rows="4"
                                     max-rows="16"
                                     class="mb-2"></b-form-textarea>
                </b-col>
            </b-form-row>
            <b-form-row class="mt-2">
                <b-col class="col-12">
                    <b-button v-on:click="save" variant="success" class="mr-2">{{$t('Save')}}</b-button>
                    <b-button v-on:click="back" class="mr-2">{{$t('Cancel')}}</b-button>
                </b-col>
            </b-form-row>
        </b-form>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Mixins } from 'vue-property-decorator'
    import BaseComponent from '@/components/Shared/BaseComponent.vue'
    import { Route } from 'vue-router'
    import { api } from '@/services/api'
    import FieldAccountSearch from '@/components/Shared/FieldAccountSearch.vue'

    @Component({
        components: {
            FieldAccountSearch
        }
    })
    export default class ObjectKeyEdit extends Mixins(BaseComponent) {
        data: Data | null = null

        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            var id = Number(r.params.id)
            api.get<Data>(`/api/ObjectKeys/edit/${id}`)
                .then((data) => {
                    this.data = data
                })
        }
        save(): void {
            api.post<Data>('/api/ObjectKeys/edit', this.data as Data)
                .then(() => { this.back() })
        }
        fields = [
            { key: 'objectReferenceNumber', label: 'Object' }, 'account', 'received', 'givenAway'
        ]
    }
    interface Data {
        id: number
        objectReferenceNumber: string | null
        received: string | null
        givenAway: string | null
        description: string | null
    }
</script>

<style scoped></style>
