<template>
    <div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import ObjectEdit from '@/components/Objects/ObjectEdit.vue'
    import { api } from '@/services/api'
    import { ObjectEditQueryOutput } from '@/models/ObjectEditQueryOutput'

    @Component({ components: { ObjectEdit } })
    export default class ObjectAdd extends Vue {

        constructor() {
            super()
            this.addObject()
        }

        addObject(): void {
            api
                .get<ObjectEditQueryOutput>('/api/object/add')
                .then((data) => {
                    this.$router.push('/Objects/View/' + data.object.id + '/2')
                })
        }
    }
</script>

<style scoped></style>
