<template>
    <vue-bootstrap-autocomplete :data="searchResults"
                             v-model="displayedText"
                             :maxMatches="20"
                             :serializer="(s) => s.description"
                             @hit="$emit('input', $event.id)" />
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { Debounce } from 'vue-debounce-decorator'
    import { EventBus } from './../Shared/EventBus'

    // eslint-disable-next-line
    export enum LeadSearchFilter { None, OnlyEmploye, OnlyEmployeAndProfessional }

    @Component
    export default class FieldLeadSearch extends Vue {
        @Prop(Number) id?: number
        @Prop(String) initialText?: string

        selectedText: string | null = null
        searchResults: LeadSearchOutputItem[] = []

        get displayedText() {
            return this.selectedText != null
                ? this.selectedText
                : this.initialText != null
                    ? this.initialText
                    : ''
        }
        set displayedText(newValue: string) {
            this.selectedText = newValue
            EventBus.$emit('FieldLeadSearch_DisplayedText_Changed', newValue)
        }

        @Debounce(400)
        async search(searchStr?: string) {
            if (searchStr == null || searchStr == '') {
                this.searchResults = []
                return
            }
            try {
                api.get<LeadSearchOutput>('/api/lead/searchQuick', {
                    searchStr: searchStr
                })
                    .then((output: LeadSearchOutput) => this.searchResults = output.items)
            } catch (e) {
                console.log(e)
                EventBus.$emit('Error', e)
            }
        }

        @Watch('displayedText')
        onDisplayedTextChanged(val: string) {
            this.search(val)
        }
    }
interface LeadSearchOutput {
  items: LeadSearchOutputItem[]
}

interface LeadSearchOutputItem {
  id?: number
  description?: string
}
</script>

<style scoped></style>
