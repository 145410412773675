<template>
    <div class="container-md">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Employes') }}</h1>
            </div>
            <div class="col-4 text-right">
                <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{list.items.length}}</b-badge>
            </div>
        </div>
        <b-button variant="primary" to="/Accounts/AddEmploye">{{ $t('Add') }}</b-button>
        <table class="table table-hover">
            <thead>
                <tr>
                    <th>{{ $t('Name') }}</th>
                    <th>{{ $t('Role') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in list.items" v-bind:key="item.id">
                    <td>
                        <router-link :to="{ name: 'Accounts/View', params: { id: item.id } }">
                            {{ item.name }}
                        </router-link>
                    </td>
                    <td>{{item.role}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
    /* tslint:disable */
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { EmployeListQueryOutput } from '@/models/EmployeListQueryOutput'

    @Component
    export default class EmployeList extends Vue {
        public list: EmployeListQueryOutput = new EmployeListQueryOutput()
        constructor() {
            super()
            this.fetchData()
        }

        fetchData(): void {
            api.get<EmployeListQueryOutput>('/api/employe')
                .then(data => this.list = data)
        }
    }
</script>

<style scoped lang="scss"></style>
