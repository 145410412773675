<template>
  <b-form-group :label="$t(label)" :label-for="label">
    <b-form-input
      :id="myId"
      :type="type"
      :value="value"
      v-on:input="$emit('input', $event)"
      class="mr-2"
    >
    </b-form-input>
  </b-form-group>
</template>

<script lang="ts">
import { Component, Vue, Prop, Model } from 'vue-property-decorator'

@Component({})
export default class MyInput extends Vue {
  @Prop(String) label?: string
  @Prop(String) id?: string
  @Prop({ default: 'text' }) type!: string
  @Model('input') readonly value!: string | number
  get myId(): string | undefined {
    return this.id == null ? this.label : this.id
  }
}
</script>

<style scoped></style>
