<template>
  <div>
    <div class="container-lg" v-if="viewMode == viewModeInput">
      <b-card title="Search for Objects" style="background-color: lightyellow">
        <b-form>
          <b-form-row>
            <b-col sm="3">
              <field-form-bool :label="$t('forSale')" v-model="input.forSale" />
            </b-col>
            <b-col sm="3">
              <field-form-bool :label="$t('forRent')" v-model="input.forRent" />
            </b-col>
            <b-col sm="3">
              <field-form-bool :label="$t('forShortRent')" v-model="input.forShortRent" />
            </b-col>
          </b-form-row>
          <b-form-group label-for="addressSearch" v-bind:label="$t('location') | capitalize">
            <b-form-row>
              <b-col sm="4">
                <b-form-select v-model="input.locationId" :options="locationOptions"></b-form-select>
              </b-col>
              <b-col sm="1">
                <b-button v-if="input.locationId"
                          variant="outline-primary"
                          v-on:click="clearLocation">{{ $t('Clear') }}</b-button>
              </b-col>
            </b-form-row>
          </b-form-group>
          <b-form-group label-for="priceMin" v-bind:label="$t('price') | capitalize">
            <b-form-row>
              <b-col sm="2">
                <b-form-input v-model.number="input.priceMin"
                              id="priceMin"
                              type="number"></b-form-input>
              </b-col>
              <b-col sm="2">
                <b-form-input v-model.number="input.priceMax"
                              id="priceMax"
                              type="number"></b-form-input>
              </b-col>
            </b-form-row>
          </b-form-group>
          <b-form-group label-for="areaMin" v-bind:label="$t('area') | capitalize">
            <b-form-row>
              <b-col sm="2">
                <b-form-input v-model.number="input.areaMin" id="areaMin" type="number"></b-form-input>
              </b-col>
              <b-col sm="2">
                <b-form-input v-model.number="input.areaMax" id="areaMax" type="number"></b-form-input>
              </b-col>
            </b-form-row>
          </b-form-group>
          <b-form-row>
            <b-col sm="3">
              <field-form-bool :label="$t('Show on map')" v-model="input.showOnMap" />
            </b-col>
            <b-col sm="3"> </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-col sm="3">
              <label class="mr-sm-2" for="createdBy">Created by:</label>
            </b-col>
            <b-col sm="6">
              <field-account-search v-model="input.createdBy" filter="2" :initialText="input.createdByText" />
            </b-col>
          </b-form-row>

          <b-form-row>
            <b-button variant="primary" v-on:click="search" class="mr-4">{{ $t('Search') }}</b-button>
            <b-button variant="outline-danger" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
          </b-form-row>
        </b-form>
      </b-card>
    </div>

    <div class="container-fluid" v-if="viewMode == viewModeOutput">
      <b-card>
        <div class="row justify-content-between">
          <div class="col-4">
            <h5 class="mt-4 mb-4">Search for Objects</h5>
          </div>
          <div class="col-4 text-right">
            <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{output == null ? 0 : output.items.length}}</b-badge>
          </div>
        </div>
        <div>
          <b-button class="mt-4 mb-4" variant="primary" v-on:click="changeInput">
            {{
        $t('New search')
            }}
          </b-button>
          <ObjectsOnMap v-if="input.showOnMap" :markers="markers"></ObjectsOnMap>
          <table class="table table-hover">
            <thead>
              <tr>
                <th v-if="input.showOnMap">{{ $t('# on map') }}</th>
                <th>{{ $t('#') }}</th>
                <th>{{ $t('Type') }}</th>
                <th>{{ $t('Location') }}</th>
                <th>{{ $t('Area') }}</th>
                <th>{{ $t('ForSale') }}</th>
                <th>{{ $t('SalePrice') }}</th>
                <th>{{ $t('ForRent') }}</th>
                <th>{{ $t('RentPrice') }}</th>
                <th>{{ $t('ForShortRent') }}</th>
                <th>{{ $t('ShortRentPrice') }}</th>
                <th>{{ $t('Owner') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in output.items" v-bind:key="item.id">
                <td v-if="input.showOnMap">{{ item.longitude ? (index + 1).toString() : '-' }}</td>

                <td>
                  <router-link :to="{ name: 'Objects/View', params: { id: item.id } }">
                    {{ item.referenceNumber }}
                  </router-link>
                </td>
                <td>{{ $t(item.type) }}</td>
                <td>{{ item.location }}</td>
                <td>{{ item.area }}</td>
                <td>{{ item.forSale | bool }}</td>
                <td>{{ item.salePrice }}</td>
                <td>{{ item.forRent | bool }}</td>
                <td>{{ item.rentPrice }}</td>
                <td>{{ item.forShortRent | bool }}</td>
                <td>{{ item.shortRentPrice }}</td>
                <td>
                  <router-link :to="{ name: 'Accounts/View', params: { id: item.ownerId } }">
                    {{ item.owner }}
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
          <b-button class="mt-4" variant="primary" v-on:click="changeInput">
            {{
        $t('New search')
            }}
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import fieldFormBool from '@/components/Shared/FieldFormBool.vue'
  import fieldLocation from '@/components/Shared/FieldLocation.vue'
  import { api } from '@/services/api'
  import SelectListItem from '@/models/SelectListItem'
  import ObjectsOnMap from '@/components/Objects/ObjectsOnMap.vue'
  import IGMapMarker from '@/models/IGMapMarker'
  import { EventBus } from '@/components/Shared/EventBus'
  import FieldAccountSearch from '@/components/Shared/FieldAccountSearch.vue'

  @Component({
    components: {
      fieldLocation,
      fieldFormBool,
      ObjectsOnMap,
      FieldAccountSearch
    }
  })
  export default class ObjectSearch extends Vue {
    public input: IObjectSearchInput = {}
    public output: IObjectSearchOutput | null = null

    viewModeInput = 0
    viewModeOutput = 1
    viewMode = 0

    locationOptions: SelectListItem[] | null = null

    constructor() {
      super()
      this.fetchLocations()
      EventBus.$on('FieldAccountSearch_DisplayedText_Changed', this.setEmployeeText)
      const cookieFilter = this.$cookies.get('ObjectSearch.filter') as IObjectSearchInput
      if (cookieFilter) {
        this.input.areaMax = cookieFilter.areaMax
        this.input.areaMin = cookieFilter.areaMin
        this.input.forRent = cookieFilter.forRent
        this.input.forSale = cookieFilter.forSale
        this.input.forShortRent = cookieFilter.forShortRent
        this.input.locationId = cookieFilter.locationId
        this.input.priceMax = cookieFilter.priceMax
        this.input.priceMin = cookieFilter.priceMin
        this.input.showOnMap = cookieFilter.showOnMap
        this.input.createdBy = cookieFilter.createdBy
        this.input.createdByText = cookieFilter.createdByText
      }
    }

    setEmployeeText(value: string) {
      if(!this.input) return
      this.input.createdByText = value
    }

    fetchLocations(): void {
      api
        .get<SelectListItem[]>('/api/object/getTopLocations')
        .then((data) => {
          this.locationOptions = data
        })
    }

    clearLocation(): void {
      this.input.locationId = null
    }

    search(): void {
      this.$cookies.set('ObjectSearch.filter', this.input)
      api
        .postExt<IObjectSearchInput, IObjectSearchOutput>('/api/object/search', this.input)
        .then((data) => {
          this.output = data
          this.viewMode = this.viewModeOutput
        })
    }

    clearFilters(): void {
      this.input = {}
    }

    changeInput(): void {
      this.viewMode = this.viewModeInput
    }

    get markers(): IGMapMarker[] {
      const result = this.output ? this.output?.items.map((x) => ({
        lat: x.latitude, lng: x.longitude, id: x.id, label: x.referenceNumber
      } as IGMapMarker)) : []

      return result.filter(x => x.lat)
    }
  }
  interface IObjectSearchInput {
    forSale?: boolean | null
    forRent?: boolean | null
    forShortRent?: boolean | null
    priceMin?: number | null
    priceMax?: number | null
    areaMin?: number | null
    areaMax?: number | null
    locationId?: number | null
    showOnMap?: boolean | null
    createdBy?: number | null
    createdByText?: string | null
  }
  interface IObjectSearchOutput {
    items: IObjectSearchOutputItem[]
  }
  interface IObjectSearchOutputItem {
    id?: number | null
    referenceNumber?: string | null
    type?: string | null
    location?: string | null
    forSale?: boolean | null
    salePrice?: number | null
    forRent?: boolean | null
    rentPrice?: number | null
    shortRentPrice?: number | null
    area?: number | null
    owner?: string | null
    ownerId?: number | null
    longitude?: number | null
    latitude?: number | null
  }
</script>

<style scoped></style>
