<template>
    <b-modal id="field-editor" :title="$t('Edit')" @ok="handleOk" :cancel-title="$t('Cancel')">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <field-form-bool-nullable v-if="type == 'boolNullable'" :label="label" v-model="value">
            </field-form-bool-nullable>
            <b-form-group v-else label-for="control">
                <template slot="label">
                    <span v-html="label"></span>
                </template>
                <b-form-input v-if="type == 'text'"
                              v-model="value"
                              id="control"
                              type="search"></b-form-input>
                <b-form-textarea v-if="type == 'textarea'"
                                 v-model="value"
                                 id="control"
                                 rows="8"
                                 max-rows="16"></b-form-textarea>
                <b-form-input v-if="
            type == 'number' ||
            type == 'distance' ||
            type == 'distance-km' ||
            type == 'currency' ||
            type == 'area'
          "
                              v-model="value"
                              id="control"
                              type="number"></b-form-input>
                <b-form-select v-if="type == 'select' || type == 'enum'"
                               v-model="value"
                               :options="selectList"
                               id="control"></b-form-select>
                <b-form-input v-if="type == 'date'" v-model="value" id="control" type="date"></b-form-input>

                <!--todo add <fieldLocation :placeholder="item.location" v-model="item.locationId" />-->
                <vue-bootstrap-autocomplete v-if="type == 'location'"
                                         :data="addresses"
                                         v-model="value"
                                         :maxMatches="20"
                                         :serializer="(s) => s.text"
                                         :placeholder="value"
                                         @hit="item.locationId = $event.id" />
                <b-form-checkbox-group v-if="type == 'flag-enum'"
                                       id="control"
                                       v-model="value"
                                       :options="selectList"
                                       name="control"></b-form-checkbox-group>
            </b-form-group>
        </form>
    </b-modal>
</template>

<script lang="ts">
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { EventBus } from './EventBus'
    import fieldFormBoolNullable from './../Shared/FieldFormBoolNullable.vue'
    import SelectListEditQueryOutput from '@/models/SelectListEditQueryOutput'

    @Component({ components: { fieldFormBoolNullable } })
    export default class FieldEditor extends Vue {
        addresses = []
        entity = ''
        id: string | number = ''
        label = ''
        name = ''
        dtoFieldName?= ''
        type = ''
        value: string | number | string[] | null = null
        oldValue: string | number | string[] | null = null
        selectList: any
        nameState: any

        mounted(): void {
            EventBus.$on('fieldEdit', this.show)
        }

        /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
        show(event: any): void {
            this.entity = event.entity
            this.id = event.id
            this.label = event.label
            this.name = event.name
            this.dtoFieldName = event.name
            this.type = event.type
            this.value = event.value
            this.oldValue = this.value

            if (this.type == 'select' || this.type == 'enum') {
                this.editRequestSelectList(this.entity, this.id, this.name).then((response) => {
                    this.value = response.value
                    this.oldValue = this.value
                    this.selectList = response.selectList.map((el: any) => {
                        return { parent: el.parent, value: el.value, text: this.$t(el.text) }
                    })
                    this.dtoFieldName = response.dtoFieldName
                    this.$bvModal.show('field-editor')
                })
            } else if (this.type == 'flag-enum')
                this.editRequestSelectList(this.entity, this.id, this.name).then((response) => {
                    this.value = response.valueArray
                    this.oldValue = this.value
                    this.selectList = response.selectListText.map((el: any) => {
                        return { value: el.value, text: this.$t(el.text) }
                    })
                    this.dtoFieldName = response.dtoFieldName
                    this.$bvModal.show('field-editor')
                })
            else this.$bvModal.show('field-editor')
            this.setMeasurementUnitsToLabel()
        }

        editRequestSelectList(
            entity: string,
            id: number | string,
            name: string
        ): Promise<SelectListEditQueryOutput> {
            return api.get<SelectListEditQueryOutput>(
                `/api/${entity}/editRequestSelectList`,
                { id: id, fieldName: name }
            )
        }

        setMeasurementUnitsToLabel(): void {
            if (this.type == 'distance') this.label += ' (' + this.$t('in meters') + ')'
            if (this.type == 'distance-km') this.label += ' (' + this.$t('in kilometers') + ')'
            if (this.type == 'currency') this.label += ' (' + this.$t('in euro') + ')'
            if (this.type == 'area') this.label += ' (' + this.$t('in m<sup>2</sup>') + ')'
        }

        checkFormValidity(): boolean {
            const valid = (this.$refs.form as any).checkValidity()
            this.nameState = valid
            return valid
        }

        /* eslint-disable @typescript-eslint/explicit-module-boundary-types */
        handleOk(bvModalEvt: any): void {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        }

        handleSubmit(): void {
            if (!this.checkFormValidity()) {
                return
            }

            if (this.value === this.oldValue) {
                this.$nextTick(() => {
                    this.$bvModal.hide('field-editor')
                })
                return
            }

            api.patch(this.entity, this.id, this.dtoFieldName as string, this.value).then((entityView) => {
                EventBus.$emit('viewUpdated', entityView, this.entity)
                this.$nextTick(() => {
                    this.$bvModal.hide('field-editor')
                })
            })
        }
    }
</script>

<style scoped></style>
