<template>
    <div class="container mt-3">
        <b-form>
            <h6>{{data.name}}</h6>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-group label-for="ChangeEmployeRoleRole" v-bind:label="$t('Role')">
                        <b-form-select v-model="data.role"
                                       :options="data.roleList"
                                       id="ChangeEmployeRoleRole"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-form-row>
        </b-form>

        <b-form-row class="mt-4">
            <b-button v-on:click="save" variant="success" class="mr-2">Save</b-button>
            <b-button v-on:click="back" class="mr-2">Cancel</b-button>
        </b-form-row>
    </div>
</template>

<script lang="ts">
    import { Component, Mixins } from 'vue-property-decorator'
    import navbar from './../../components/Navbar.vue'
    import { api } from '@/services/api'
    import BaseComponent from '@/components/Shared/BaseComponent.vue'

    @Component({
        components: {
            navbar
        }
    })
    export default class ChangeEmployeRole extends Mixins(BaseComponent) {
        id: number | null = null
        error: string | null = null
        data = { name: null, role: null, roleList: [] } as UserRoleServiceOutput

        created(): void {
            this.id = Number(this.$route.params.id)
            api.get<UserRoleServiceOutput>(`/api/UserRole/${this.id}`)
                .then(data => this.data = data)
        }

        save(): void {
            api.post(`/api/UserRole/${this.id}`, { role: this.data.role })
                .then(() => {
                    this.back()
                })
        }
    }
    interface UserRoleServiceOutput {
        name: string | null
        role: string | null
        roleList: string[]
    }
</script>

<style scoped></style>
