import { RouteConfig } from 'vue-router'
import HomePayments from '@/hostPayments/HomePayments.vue'
import FullScreen from '../views/FullScreen.vue'
import Login from '@/components/Auth/Login.vue'
import ForgotPassword from '@/components/Auth/ForgotPassword.vue'
import ResetPassword from '@/components/Auth/ResetPassword.vue'
import TransactionAccountList from '../views/Transactions/TransactionAccountList.vue'
import TransactionAccountView from '../views/Transactions/TransactionAccountView.vue'
import TransactionIncome from '../views/Transactions/TransactionIncome.vue'
import TransactionExpense from '../views/Transactions/TransactionExpense.vue'
import TransactionAccountAdd from '../views/Transactions/TransactionAccountAdd.vue'
import TransactionExpenseView from '../views/Transactions/TransactionExpenseView.vue'

const routesPayments: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: HomePayments,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/Payments',
                name: 'payments',
                component: TransactionAccountList,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Payments/View/:id',
                name: 'Payments/View',
                component: TransactionAccountView,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Payments/AddIncome/:accountId',
                name: 'Payments/AddIncome',
                component: TransactionIncome,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Payments/EditIncome/:id',
                name: 'Payments/EditIncome',
                component: TransactionIncome,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Payments/AddExpense/:accountId',
                name: 'Payments/AddExpense',
                component: TransactionExpense,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Payments/EditExpense/:id',
                name: 'Payments/EditExpense',
                component: TransactionExpense,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Payments/ApproveExpense/:id',
                name: 'Payments/ApproveExpense',
                component: TransactionExpenseView,
            },
            {
                path: '/Payments/View/Income/:id',
                name: 'Payments/View/Income',
                component: TransactionIncome,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: '/Payments/AccountAdd',
                name: 'Payments/AccountAdd',
                component: TransactionAccountAdd,
                meta: {
                    requiresAuth: true
                }
            }
        ]
    },
    {
        path: '/FullScreen',
        name: 'FullScreen',
        component: FullScreen,
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login
            },
            {
                path: '/ForgotPassword',
                name: 'ForgotPassword',
                component: ForgotPassword
            },
            {
                path: '/ResetPassword',
                name: 'ResetPassword',
                component: ResetPassword
            }
        ]
    },
]

export default routesPayments
