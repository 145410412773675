<template>
    <div class="container-fluid" v-if="data">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Deals') }}</h1>
            </div>
            <div class="col-4 text-right">
                <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{data.items.length}}</b-badge>
            </div>
        </div>
        <b-form class="mb-4">
            <b-form-row>
                <b-button variant="primary" to="/Deals/Add" class="mr-4">{{ $t('Add new deal') }}</b-button>
                <b-button :variant="hasFilters ? 'success' : 'primary'" v-b-toggle.collapse-3 class="mr-4">
                    {{
        $t('Show / Hide Filters')
                    }}
                </b-button>
            </b-form-row>
        </b-form>
        <b-collapse id="collapse-3">
            <b-card title="Filters" style="background-color: lightyellow">
                <b-form>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="budgetMin">Deal Type</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-select v-model="filters.dealType" :options="dealTypeOptions"></b-form-select>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="budgetMin">Stage</label>
                        </b-col>
                        <b-col sm="10">
                            <multiselect v-model="filters.stages"
                                         :options="data.options.stages"
                                         label="text"
                                         track-by="value"
                                         :multiple="true"
                                         :close-on-select="false"
                                         :clear-on-select="false"
                                         :preserve-search="true"
                                         placeholder="All stages"></multiselect>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="startDate">Depost</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="depositFrom"
                                               reset-button
                                               v-model="filters.depositFrom"></b-form-datepicker>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="depositTill"
                                               reset-button
                                               v-model="filters.depositTill"></b-form-datepicker>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="startDate">End Date</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="endDateFrom"
                                               reset-button
                                               v-model="filters.endDateFrom"></b-form-datepicker>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="endDateTill"
                                               reset-button
                                               v-model="filters.endDateTill"></b-form-datepicker>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="lastNameFilter">Last name</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-input class="mr-3"
                                          placeholder="Last name"
                                          id="lastNameFilter"
                                          v-model="filters.lastName"></b-form-input>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="1">
                            <label class="mr-sm-2" for="objectFilter">Object</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-input class="mr-3"
                                          placeholder="Object"
                                          id="objectFilter"
                                          v-model="filters.object"></b-form-input>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-button variant="primary" v-on:click="applyFilters" class="mr-4">
                            {{
            $t('Apply Filters')
                            }}
                        </b-button>
                        <b-button variant="primary" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
                    </b-form-row>
                </b-form>
            </b-card>

        </b-collapse>
        <b-table :items="data.items" :fields="fields" primary-key="id" :tbody-tr-class="rowClass"
                 sticky-header="70vh"
                 :per-page="perPage"
                 :current-page="currentPage">
            >
            <template #cell(id)="data">
                <router-link :to="{ name: 'Deals/Edit', params: { id: data.item.id } }">
                    {{ data.item.id }}
                </router-link>
            </template>
            <template #cell(modifiedOn)="data">
                {{ data.item.modifiedOn | date_short }}
            </template>
            <template #cell(modifiedBy)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.modifiedBy } }">
                    {{ data.item.modifiedByAccountDisplayName }}
                </router-link>
            </template>
            <template #cell(seller)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.sellerId } }">
                    {{ data.item.seller }}
                </router-link>
            </template>
            <template #cell(buyer)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.buyerId } }">
                    {{ data.item.buyer }}
                </router-link>
            </template>
            <template #cell(object)="data">
                <router-link :to="{ name: 'Objects/View', params: { id: data.item.objectId } }">
                    {{ data.item.objectReferenceNumber }}
                </router-link>
            </template>
            <template #cell(lawer)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.lawerId } }">
                    {{ data.item.lawer }}
                </router-link>
            </template>
            <template #cell(notary)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.notaryId } }">
                    {{ data.item.notary }}
                </router-link>
            </template>
            <template #cell(civilEngeneer)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.civilEngeneerId } }">
                    {{ data.item.civilEngeneer }}
                </router-link>
            </template>
            <template #cell(assigned)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.assignedId } }">
                    {{ data.item.assigned }}
                </router-link>
            </template>
            <template #cell(price)="data">
                {{ data.item.price | my_currency }}
            </template>
            <template #cell(startDate)="data">
                {{ data.item.startDate | date_short }}
            </template>
            <template #cell(endDate)="data">
                {{ data.item.endDate | date_short }}
            </template>
            <template #cell(action)="data">
                <b-button :to="'/Deals/'+data.item.id" variant="outline-primary" size="sm" class="mb-2">
                    <font-awesome-icon icon="edit" />
                </b-button>
            </template>
        </b-table>
        <b-row>
            <b-col sm="2" md="3" class="my-1">
                <b-form-group label="Per page"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-form-select id="per-page-select"
                                   v-model="perPage"
                                   :options="pageOptions"
                                   size="sm" v-on:input="onPerPageChanged"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="fill"
                              size="sm"
                              class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import Multiselect from 'vue-multiselect'
    import { api } from '@/services/api'
    import { ISelectListItem } from '@/models/ISelectListItem'
    import { myutils } from '@/services/myutils'

    @Component({ components: { Multiselect } })
    export default class DealList extends Vue {
        data: IData | null = null
        filters: IFilters = { dealType: null, stages: [], depositFrom: null, depositTill: null, endDateFrom: null, endDateTill: null, lastName: null, object: null }

        totalRows = 1
        currentPage = 1
        perPage = 10
        pageOptions = [5, 10, 20, 50, { value: 1000000, text: "Show all" }]

        created() {
            this.loadFilterFromCookies()
            this.fetchData()
            this.perPage = this.$cookies.get('DealList.perPage') as number ?? 20
        }

        loadFilterFromCookies(): void {
            const cookieFilter = this.$cookies.get('DealList.filters') as IFilters
            if (!cookieFilter) return
            Object.assign(this.filters, cookieFilter)
        }

        fields = [
            'id',
            'modifiedOn',
            'modifiedBy',
            'dealType',
            'dealStage',
            'seller',
            'buyer',
            'object',
            'lawer',
            'notary',
            'civilEngeneer',
            'assigned',
            { key: 'price', label: 'Price', tdClass: 'text-right no-wrap' },
            'startDate',
            'endDate',
            { key: 'action', label: '' }
        ]

        fetchData(): void {
            api
                .postExt<IFilters | null, IData>('/api/Deal/search', this.filters)
                .then((data) => {
                    this.data = data
                    this.totalRows = data.items.length
                })
        }
        onPerPageChanged(): void {
            this.$cookies.set('DealList.perPage', this.perPage)
        }

        rowClass(item: IDataItem) { return 'stage' + item.dealStage.replaceAll(' ', '').toLowerCase() }

        get hasFilters(): boolean {
            return !myutils.hasAnyValue(this.filters)
        }

        get dealTypeOptions(): ISelectListItem[] {
            return [{ text: 'All types of deals', value: null } as ISelectListItem, ...this.data?.options.types ?? []]
        }

        applyFilters(): void {
            this.$cookies.set('DealList.filters', this.filters)
            this.fetchData()
        }
        clearFilters(): void {
            myutils.setPropertiesToNullOrEmptyArray(this.filters)
            this.applyFilters()
        }
    }
    interface IData {
        items: IDataItem[],
        options: IFiltersOptions
    }
    interface IDataItem {
        id: number
        seller: string | null
        buyer: string | null
        dealType: string
        dealStage: string
        modifiedBy: string
        modifiedOn: string
    }
    interface IFilters {
        endDateFrom: string | null
        endDateTill: string | null
        depositFrom: string | null
        depositTill: string | null
        dealType: string | null
        stages: ISelectListItem[]
        lastName: string | null
        object: string | null
    }
    interface IFiltersOptions {
        types: ISelectListItem[]
        stages: ISelectListItem[]
    }
</script>
<style scoped>
</style>
