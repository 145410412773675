<template>
    <div>
        
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import ILeadAddCommandInput from '@/models/LeadAddCommandInput'
    import LeadAddCommandOutput from '@/models/LeadAddCommandOutput'

    @Component
    export default class LeadAdd extends Vue {
        created() {
            this.fetchData()
        }

        fetchData(): void {
            let input = {} as ILeadAddCommandInput
            if (this.$route.params.id)
                input.accountId = +this.$route.params.id
            api.postExt<ILeadAddCommandInput, LeadAddCommandOutput>('/api/lead/add', input)
                .then(data => {
                    this.$router.push('/Leads/View/'+data.lead.id+'/2')
                })
        }
    }
</script>

<style scoped></style>
