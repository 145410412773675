<template>
    <div class="container-fluid" v-if="data">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Keys') }}</h1>
                <b-input-group prepend="Filter by property reference number" class="mt-3 mb-3">
                    <b-form-input v-model="filter"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="outline-danger" v-on:click="onClearFilter">X</b-button>
                    </b-input-group-append>
                </b-input-group>
            </div>
        </div>
        <b-table :items="filteredItems" :fields="fields" primary-key="objectId"
                 sticky-header="70vh"
                 :per-page="perPage"
                 :current-page="currentPage">
            <template #cell(objectReferenceNumber)="data">
                <router-link :to="{ name: 'Objects/View', params: { id: data.item.objectId } }">
                    #{{ data.item.objectReferenceNumber }}
                </router-link>
            </template>
            <template #cell(received)="data">
                {{ data.item.received | date_short }}
            </template>
            <template #cell(givenAway)="data">
                {{ data.item.givenAway | date_short }}
            </template>
            <template #cell(account)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.accountId } }">
                    {{ data.item.account }}
                </router-link>
            </template>
            <template #cell(action)="data">
                <b-button :to="'/ObjectKeys/'+data.item.objectId" variant="outline-primary" size="sm" class="mb-2">
                    <font-awesome-icon icon="edit" />
                </b-button>
            </template>
        </b-table>
        <b-row>
            <b-col sm="2" md="3" class="my-1">
                <b-form-group label="Per page"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-form-select id="per-page-select"
                                   v-model="perPage"
                                   :options="pageOptions"
                                   size="sm" v-on:input="onPerPageChanged"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="fill"
                              size="sm"
                              class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'

    @Component({})
    export default class ObjectKeyList extends Vue {
        data: IData | null = null

        totalRows = 1
        currentPage = 1
        perPage = 10
        pageOptions = [5, 10, 20, 50, { value: 1000000, text: "Show all" }]

        created() {
            this.fetchData()
            this.perPage = this.$cookies.get('ObjectKeyList.perPage') as number ?? 20
        }

        fields = [
            { key: 'objectReferenceNumber', label: 'Property reference number' },
            { key: 'account', label: 'Given to'},
            'received', 'givenAway', 'description',
            { key: 'action', label: '' }
        ]

        fetchData(): void {
            api
                .get<IData>('/api/ObjectKeys')
                .then((data) => {
                    this.data = data
                    this.totalRows = data.items.length
                })
        }
        filter = ''
        get filteredItems(): IDataItem[] {
            return this.data ? this.data?.items.filter((el)=>el.objectReferenceNumber?.startsWith(this.filter)) : []
        }
        onClearFilter(): void {
            this.filter = ''
        }
        onPerPageChanged(): void {
            this.$cookies.set('ObjectKeyList.perPage', this.perPage)
        }
    }
    interface IData {
        items: IDataItem[],
    }
    interface IDataItem {
        objectId: number
        objectReferenceNumber: string | null
        received: Date | null
        description: string | null
        accountId: string | null
        account: string | null
        givenAway: Date | null
    }
</script>
<style scoped>
</style>
