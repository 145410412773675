<template>
    <div class="container-md">
        <h1 class="mb-4">{{ $t('Settings') }}</h1>
        <b-card :title="$t('Sources')" class="mb-4">
            <b-card-text>
                <router-link to="/Sources">{{ $t('Sources') }}</router-link>
            </b-card-text>
        </b-card>
        <b-card :title="$t('Language')" class="mb-4">
            <b-card-text>
                Export
            </b-card-text>
            <b-card-text>
                <b-button variant="primary" href="/api/LocalizationExport">{{ $t('Download') }}</b-button>
            </b-card-text>
            <b-card-text>
                Import
            </b-card-text>
            <b-card-text>
                <b-form-file v-model="localizationFile"
                             placeholder="Choose a file or drop it here..."
                             drop-placeholder="Drop file here..."
                             v-on:change="successUpload=false"></b-form-file>
            </b-card-text>
            <b-card-text>
                <b-button variant="primary" @click="upload">{{ $t('Upload') }}</b-button>
            </b-card-text>
            <b-card-text>
                <b-alert v-if="successUpload" show variant="success">{{ $t('File sucessfully upladed!') }}</b-alert>
            </b-card-text>
            <b-button to="/ActionLog">Action Log</b-button>
        </b-card>
        <b-card :title="$t('Data Export')" class="mb-4">
            <b-card-text>
                Emails
            </b-card-text>
            <b-card-text>
                <b-button variant="primary" v-on:click="downloadRu" class="mr-4">{{ $t('Russian emails') }}</b-button>
                <b-button variant="primary" v-on:click="download" class="mr-4">{{ $t('Other emails') }}</b-button>
            </b-card-text>
        </b-card>
        <b-card :title="$t('User management')" class="mb-4">
            <b-card-text>
                <router-link :to="{ name: 'SetPassword' }">{{ $t('Set password') }}</router-link>
            </b-card-text>
        </b-card>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'

    @Component
    export default class AdminSettings extends Vue {
        localizationFile: Blob | null = null
        successUpload = false

        upload(): void {
            if (!this.localizationFile) return;
            const formData = new FormData();
            formData.append('file', this.localizationFile as Blob)
            api.upload('api/LocalizationExport', formData)
                .then(()=> this.successUpload = true)
        }
        downloadRu(): void {
            api.download("/api/DataExport?language=russian&except=false", 'Russian emails for newsletter.xlsx')
        }
        download(): void {
            api.download("/api/DataExport?language=russian&except=true", 'Non russian emails for newsletter.xlsx')
        }
    }
</script>

<style scoped lang="scss"></style>
