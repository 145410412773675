<template>
    <b-navbar id="navbar" fixed="top" toggleable="xl" type="dark" variant="mybg">
        <div class="container">
            <b-navbar-brand to="/CRM">
                <img src="../assets/logo_white_small.png" />
            </b-navbar-brand>
            <b-nav-text class="mx-auto h5">
                {{ header }}
            </b-nav-text>
            <slot name="visibleButtons"></slot>
            <b-navbar-nav>
                <b-button class="mr-2" v-on:click="goBack()" size="sm">{{ $tc('Back') }}</b-button>
            </b-navbar-nav>
            <b-navbar-nav>
                <b-dropdown no-caret size="sm">
                    <template v-slot:button-content>
                        <font-awesome-icon size="lg" icon="bars" />
                    </template>
                    <b-dropdown-item>
                        <form class="form-inline my-2 my-lg-0">
                            <a class="my-2 my-sm-0" @click.prevent="Logout">
                                <font-awesome-icon size="lg" icon="sign-out-alt" />
                                {{ $t('Logout') }}
                            </a>
                        </form>
                    </b-dropdown-item>
                    <slot name="hiddenButtons"></slot>
                </b-dropdown>
            </b-navbar-nav>
        </div>
    </b-navbar>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from 'vue-property-decorator'

    @Component
    export default class Navbar extends Vue {
        @Prop({ default: '' }) readonly header!: string

        Logout(): void {
            this.$store.dispatch('authentication/logout');
            this.$router.push('/');
        }

        goBack(): void {
            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
        }
    }

    //export default {
    //    name: 'nav-bar',
    //    props: ['header', 'showBackButton'],
    //    data() {
    //        return {
    //            test: 'BEFORE SUBMIT'
    //        }
    //    },
    //    //computed: {
    //    //  ...mapState('user', [
    //    //  'profile', 'applicationSettings'
    //    //  ]),
    //    //  ...mapGetters('user', [
    //    //    'isAuthenticated'
    //    //  ])
    //    //},
    //    methods: {
    //        //...mapActions('user', [
    //        //  'logout'
    //        //]),
    //        onSubmit() {
    //            this.test = 'AFTER SUBMIT'
    //        },
    //        goBack() {
    //            window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    //        },
    //        setLocale(locale) {
    //            this.$root.$i18n.locale = locale
    //        }
    //    }
    //}
</script>

<style scoped>
    label {
        margin-bottom: 0 !important;
    }
</style>
