<template>
    <div>
        <b-form>
            <b-form-row>
                <b-col sm="4">
                    <my-input label="lastName" v-model="item.lastName"></my-input>
                </b-col>
                <b-col sm="4">
                    <my-input label="firstName" v-model="item.firstName"></my-input>
                </b-col>
                <b-col sm="4">
                    <my-input label="middleName" v-model="item.middleName"></my-input>
                </b-col>
            </b-form-row>


            <div v-if="item.isClient">
                <h6 class="mt-2">{{ $t('Request') }}</h6>
                <b-form-row>
                    <b-col sm="2">
                        <my-input label="from" v-model="item.purchaseBudgetRangeMin"></my-input>
                    </b-col>
                    <b-col sm="2">
                        <my-input label="up to" v-model="item.purchaseBudgetRangeMax"></my-input>
                    </b-col>
                </b-form-row>
            </div>

            <div v-if="item.isSource | item.isProfessional | item.isPartnerRealEstate  | item.isPartnerTourism">
                <h6 class="mt-2">{{ $t('Company') }}</h6>
                <b-form-row>
                    <b-col sm="4">
                        <field-form-bool-nullable :label="$t('isCompany')" v-model="item.isCompany" />
                    </b-col>
                    <b-col sm="4">
                        <field-form-bool-nullable :label="$t('isIndividual')" v-model="item.isIndividual" />
                    </b-col>
                </b-form-row>
                <div v-if="item.isCompany">
                    <h6 class="mt-2">{{ $t('Company') }}</h6>
                    <hr class="mt-0 mb-0" />
                    <b-form-row>
                        <b-col sm="4">
                            <my-input label="Company name" v-model="item.name" id="name"></my-input>
                        </b-col>
                        <b-col sm="4">
                            <my-input label="onEng" v-model="item.nameEng" id="nameEng"></my-input>
                        </b-col>
                        <b-col sm="4">
                            <my-input label="onLocal" v-model="item.nameLocal" id="nameLocal"></my-input>
                        </b-col>
                    </b-form-row>
                </div>
            </div>

            <div v-if="item.isProfessional">
                <h6 class="mt-2">{{ $t('Specialty') }}</h6>
                <b-form-row>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isAttorney" v-bind:label="$t('isAttorney')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isNotary" v-bind:label="$t('isNotary')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isAccountant" v-bind:label="$t('isAccountant')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isEconomist" v-bind:label="$t('isEconomist')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isTranslator" v-bind:label="$t('isTranslator')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isWorker" v-bind:label="$t('isWorker')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isCleaner" v-bind:label="$t('isCleaner')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isElectrician" v-bind:label="$t('isElectrician')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isPlumber" v-bind:label="$t('isPlumber')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isLocksmith" v-bind:label="$t('isLocksmith')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isIT" v-bind:label="$t('isIT')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isEnergySupplyCompany" v-bind:label="$t('isEnergySupplyCompany')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isElectricalStore" v-bind:label="$t('isElectricalStore')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isTechnician" v-bind:label="$t('isTechnician')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isRealEstateAgencyOrBroker" v-bind:label="$t('isRealEstateAgencyOrBroker')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isConstructionEngineer"
                                         v-bind:label="$t('isConstructionEngineer')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isInteriorDesigner"
                                         v-bind:label="$t('isInteriorDesigner')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isLandscapeDesigner"
                                         v-bind:label="$t('isLandscapeDesigner')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isSecurityService"
                                         v-bind:label="$t('isSecurityService')" />
                    </b-col>
                    <b-col sm="2">
                        <field-form-bool v-model="item.isWebDesigner" v-bind:label="$t('isWebDesigner')" />
                    </b-col>
                </b-form-row>
                <b-form-group label-for="numberOfSoldObjects" v-bind:label="$t('numberOfSoldObjects')">
                    <b-form-input v-model.number="item.numberOfSoldObjects"
                                  id="numberOfSoldObjects"
                                  type="number"></b-form-input>
                </b-form-group>
            </div>
            <h6 class="mt-2">{{ $t('Partner') }}</h6>
            <b-form-row>
                <b-col sm="2">
                    <field-form-bool v-model="item.isPartnerRealEstate" v-bind:label="$t('Partner - Real Estate')" />
                </b-col>
                <b-col sm="2">
                    <field-form-bool v-model="item.isPartnerTourism" v-bind:label="$t('Partner - Tourism')" />
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col sm="4">
                    <b-form-group label-for="language" v-bind:label="$t('language')">
                        <b-form-input v-model.number="item.language" id="language"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="4">
                    <b-form-group label-for="country" v-bind:label="$t('country')">
                        <b-form-input v-model.number="item.country" id="country"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>

            <div v-if="item.isSource">
                <b-form-group label-for="companyType" v-bind:label="$t('Company type')">
                    <b-form-input v-model.number="item.companyType" id="companyType"></b-form-input>
                </b-form-group>
            </div>

            <h6 class="mt-2">{{ $t('Name in English') }}</h6>
            <hr class="mt-0 mb-0" />
            <b-form-row>
                <b-col sm="4">
                    <my-input label="lastName" v-model="item.lastNameEng" id="lastNameEng"></my-input>
                </b-col>
                <b-col sm="4">
                    <my-input label="firstName" v-model="item.firstNameEng" id="firstNameEng"></my-input>
                </b-col>
                <b-col sm="4">
                    <my-input label="middleName" v-model="item.middleNameEng" id="middleNameEng"></my-input>
                </b-col>
            </b-form-row>
            <h6 class="mt-2">{{ $t('Name in language of account') }}</h6>
            <hr class="mt-0 mb-0" />
            <b-form-row>
                <b-col sm="4">
                    <my-input label="lastName" v-model="item.lastNameLocal" id="lastNameLocal"></my-input>
                </b-col>
                <b-col sm="4">
                    <my-input label="firstName" v-model="item.firstNameLocal" id="firstNameLocal"></my-input>
                </b-col>
                <b-col sm="4">
                    <my-input label="middleName"
                              v-model="item.middleNameLocal"
                              id="middleNameLocal"></my-input>
                </b-col>
            </b-form-row>

            <div>
                <b-form-row>
                    <b-col sm="6">
                        <my-input label="language" v-model="item.language"></my-input>
                    </b-col>
                    <b-col sm="6">
                        <my-input label="Nationality" v-model="item.nationality"></my-input>
                    </b-col>
                </b-form-row>
            </div>

            <h6 class="mt-2">{{ $t('Emails') }}</h6>
            <b-form-row>
                <b-col sm="3">
                    <b-form-input v-model="email1"></b-form-input>
                </b-col>
                <b-col sm="3">
                    <b-form-input v-model="email2"></b-form-input>
                </b-col>
                <b-col sm="3">
                    <b-form-input v-model="email3"></b-form-input>
                </b-col>
                <b-col sm="3">
                    <b-form-input v-model="email4"></b-form-input>
                </b-col>
            </b-form-row>
            <h6 class="mt-2">{{ $t('Phone numbers') }}</h6>
            <b-form-row>
                <b-col sm="2">
                    <b-form-input v-model="phone1"></b-form-input>
                </b-col>
                <b-col sm="2">
                    <b-form-input v-model="phone2"></b-form-input>
                </b-col>
                <b-col sm="2">
                    <b-form-input v-model="phone3"></b-form-input>
                </b-col>
                <b-col sm="2">
                    <b-form-input v-model="phone4"></b-form-input>
                </b-col>
                <b-col sm="2">
                    <b-form-input v-model="phone5"></b-form-input>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col sm="4">
                    <my-input label="internalPassportNumber" v-model="item.internalPassportNumber"></my-input>
                </b-col>
                <b-col sm="4">
                    <my-input label="passportNumber" v-model="item.passportNumber"></my-input>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col sm="4">
                    <my-input label="taxNumber" v-model="item.taxNumber"></my-input>
                </b-col>
                <b-col sm="4">
                    <my-input label="taxNumberOfOtherCountry" v-model="item.taxNumberOfOtherCountry"></my-input>
                </b-col>
            </b-form-row>

            <h6 class="mt-2">{{ $t('Address') }}</h6>
            <hr class="mt-0 mb-0" />
            <b-form-row>
                <b-col sm="3">
                    <my-input label="city" v-model="item.city" id="city"></my-input>
                </b-col>
                <b-col sm="3">
                    <my-input label="postcode" v-model="item.postcode" id="postcode"></my-input>
                </b-col>
                <b-col sm="6">
                    <my-input label="address"
                              v-model="item.address"
                              id="address"></my-input>
                </b-col>
            </b-form-row>

            <div v-if="item.isEmploye">
                <h6 class="mt-2">{{ $t('Work period') }}</h6>
                <b-form-row>
                    <b-col sm="3">
                        <my-input type="date"
                                  label="startDate"
                                  v-model="item.startDate"
                                  id="startDate"></my-input>
                    </b-col>
                    <b-col sm="3">
                        <my-input type="date" label="endDate" v-model="item.endDate" id="endDate"></my-input>
                    </b-col>
                    <b-col sm="3">
                        <span>{{ $t('Role') }}: </span>
                        <span>{{ item.role }}</span>
                        <router-link class="ml-2" :to="{ name: 'ChangeEmployeRole', params: { id: item.id } }">{{ $t('Change role') }}</router-link>
                    </b-col>
                    <b-col sm="3">
                        <span>{{ $t('Branch') }}:</span>
                        <b-form-select v-model="item.branchId" :options="[{value: null, text: 'not set'}, {value: 1, text: 'Xanthi'}]"></b-form-select>
                    </b-col>
                </b-form-row>
            </div>

            <h6 class="mt-2">{{ $t('internalNotes') }}</h6>
            <b-form-textarea v-model="item.notes"
                             id="notes"
                             rows="4"
                             max-rows="16"
                             class="mb-2"></b-form-textarea>
            <b-col sm="4">
                <field-form-bool v-model="item.hasTransactions" v-bind:label="$t('hasTransactions')" />
            </b-col>
            <span>{{ $t('Created') }}: {{ item.createdOn | date }} {{ item.createdBy }}</span>
            <span> | {{ $t('Modified') }}: {{ item.modifiedOn | date }} {{ item.modifiedBy }}</span>
            <h3>{{ $t('Files') }}</h3>
            <file-pond name="accountFiles"
                       ref="pond"
                       label-idle="Drop files here..."
                       v-bind:allow-multiple="true"
                       accepted-file-types="image/*, video/*, audio/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/rtf, application/vnd.visio, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xml, application/zip, application/x-7z-compressed"
                       server="/api/files"
                       v-bind:files="filesExisting"
                       v-on:processfile="fileLoaded" />
        </b-form>
    </div>
</template>

<script lang="ts">
    import fieldFormBool from '@/components/Shared/FieldFormBool.vue'
    import fieldFormBoolNullable from '@/components/Shared/FieldFormBoolNullable.vue'
    import MyInput from '@/components/Shared/MyInput.vue'
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import AccountDto from '@/models/AccountDto'
    import { Debounce } from 'vue-debounce-decorator'
    import { api } from '@/services/api'
    import vueFilePond from 'vue-filepond'
    import 'filepond/dist/filepond.min.css'
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FileLinkDto from '../../models/FileLinkDto'

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

    @Component({
        components: {
            FilePond,
            fieldFormBool,
            fieldFormBoolNullable,
            MyInput
        }
    })
    export default class AccountEdit extends Vue {
        @Prop({ type: Object, required: true })
        public account!: AccountDto
        date: any

        get item(): AccountDto {
            return this.account
        }
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        public filesExisting: any[] = []

        readonly VIEW_MODE_EDIT_EXPANDED = 2

        created(): void {
            this.setInitialFiles()
        }

        public get email1(): string | null {
            return this.getEmail(1)
        }
        public set email1(value: string | null) {
            this.setEmail(1, value)
        }
        public get email2(): string | null {
            return this.getEmail(2)
        }
        public set email2(value: string | null) {
            this.setEmail(2, value)
        }
        public get email3(): string | null {
            return this.getEmail(3)
        }
        public set email3(value: string | null) {
            this.setEmail(3, value)
        }
        public get email4(): string | null {
            return this.getEmail(4)
        }
        public set email4(value: string | null) {
            this.setEmail(4, value)
        }
        public get phone1(): string | null {
            return this.getPhone(1)
        }
        public set phone1(value: string | null) {
            this.setPhone(1, value)
        }
        public get phone2(): string | null {
            return this.getPhone(2)
        }
        public set phone2(value: string | null) {
            this.setPhone(2, value)
        }
        public get phone3(): string | null {
            return this.getPhone(3)
        }
        public set phone3(value: string | null) {
            this.setPhone(3, value)
        }
        public get phone4(): string | null {
            return this.getPhone(4)
        }
        public set phone4(value: string | null) {
            this.setPhone(4, value)
        }
        public get phone5(): string | null {
            return this.getPhone(5)
        }
        public set phone5(value: string | null) {
            this.setPhone(5, value)
        }

        @Debounce(400)
        @Watch('item', { deep: true })
        public save(): void {
            api.post('/api/account/update', this.item);
        }

        public getEmail(n: number): string | null {
            if (this.item.email === undefined || this.item.email === null) return null
            const emails = this.item.email.split(';')
            return emails.length >= n ? emails[n - 1] : null
        }

        public setEmail(n: number, value: string | null): void {
            if (this.item.email === undefined || this.item.email === null) {
                this.item.email = value
                return
            }
            let emails = this.item.email.split(';')
            if (value === null) emails = emails.splice(n - 1, 1)
            else emails[n - 1] = value
            this.item.email = emails.join(';')
        }

        public getPhone(n: number): string | null {
            if (this.item.phone === undefined || this.item.phone === null) return null
            const phones = this.item.phone.split(';')
            return phones.length >= n ? phones[n - 1] : null
        }

        public setPhone(n: number, value: string | null): void {
            if (this.item.phone === undefined || this.item.phone === null) {
                this.item.phone = value
                return
            }
            let phones = this.item.phone.split(';')
            if (value === null) phones = phones.splice(n - 1, 1)
            else phones[n - 1] = value
            this.item.phone = phones.join(';')
        }

        setInitialFiles(): void {
            this.filesExisting =
                this.item.files?.map(function (i) {
                    return { options: { type: 'limbo' }, source: i.systemName }
                }) ?? []
        }

        // eslint-disable-next-line
        fileLoaded(error: string, file: any): void {
            const isNew = (f: FileLinkDto) => f.systemName == file.serverId
            if (!this.item.files?.some(isNew)) {
                this.item.filesNew =
                    this.item.filesNew == null ? file.serverId : this.item.filesNew + ',' + file.serverId
            }
        }
    }
</script>

<style scoped></style>
