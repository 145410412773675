export class AccountListOutput {
  public items: AccountListOutputItem[] = []
}

export class AccountListOutputItem {
  id?: number
  name?: string
  transactionModifiedOn?: string
  public constructor(init?: Partial<AccountListOutputItem>) {
    Object.assign(this, init)
  }
}
