<template>
  <div class="container-fluid">
    <div class="row justify-content-between">
      <div class="col-4">
        <h1>{{ $t('Partners') }}</h1>
      </div>
      <div class="col-4 text-right">
        <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{list.totalRows}}</b-badge>
      </div>
    </div>
    <b-form class="mb-4">
      <b-form-row>
        <b-button variant="primary" to="/Accounts/AddPartner" class="mr-4">{{ $t('Add new partner') }}</b-button>
        <b-button :variant="hasFilters ? 'success' : 'primary'" v-b-toggle.collapse-3 class="mr-4">
          {{
        $t('Show / Hide Filters')
          }}
        </b-button>
      </b-form-row>
    </b-form>
    <b-collapse id="collapse-3" class="mb-4">
      <b-card title="Filters" style="background-color: lightyellow">
        <b-form>
          <b-form-row class="mb-2">
            <b-col sm="2">
              <label class="mr-sm-2" for="employeeFilterType">Created by:</label>
            </b-col>
            <b-col sm="6">
              <field-account-search v-model="filters.createdBy" filter="2" :initialText="filters.createdByText" />
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-col sm="2">
              <label class="mr-sm-2" for="country">Country:</label>
            </b-col>
            <b-col sm="3">
              <b-form-input v-model="filters.country" id="country"></b-form-input>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-col sm="2">
              <label class="mr-sm-2" for="createdOnStart">Date range of creation</label>
            </b-col>
            <b-col sm="3">
              <b-form-datepicker no-flip class="mr-2"
                                 id="createdOnStart"
                                 reset-button
                                 v-model="filters.createdOnStart"></b-form-datepicker>
            </b-col>
            <b-col sm="3">
              <b-form-datepicker no-flip class="mr-2"
                                 id="createdOnEnd"
                                 reset-button
                                 v-model="filters.createdOnEnd"></b-form-datepicker>
            </b-col>
          </b-form-row>
          <b-form-row class="mb-2">
            <b-button variant="primary" v-on:click="applyFilters" class="mr-4">
              {{
            $t('Apply Filters')
              }}
            </b-button>
            <b-button variant="primary" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
          </b-form-row>
        </b-form>
      </b-card>
    </b-collapse>
    <b-table :items="list.items" :fields="fields" primary-key="id" hover :busy="isBusy"
             sticky-header="70vh"
             :per-page="filters.perPage"
             :current-page="filters.currentPage">
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(createdOn)="data">
        {{ data.item.createdOn | date_short }}
      </template>
      <template #cell(modifiedOn)="data">
        {{ data.item.modifiedOn | date_short }}
      </template>
      <template #cell(name)="data">
        <router-link :to="{ name: 'Accounts/View', params: { id: data.item.id } }">
          {{ data.item.name }}
        </router-link>
      </template>
      <template #cell(category)="data">
        {{ data.item.isPartnerRealEstate ? $t('Real Estate') : '' }}{{ data.item.isPartnerRealEstate && data.item.isPartnerTourism ? ', ' : '' }}
        {{ data.item.isPartnerTourism ? $t('Tourism') : '' }}
      </template>
    </b-table>
    <b-row>
      <b-col sm="2" md="3" class="my-1">
        <b-form-group label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      class="mb-0">
          <b-form-select id="per-page-select"
                         v-model="filters.perPage"
                         :options="pageOptions"
                         size="sm" v-on:input="onPerPageChanged"></b-form-select>
        </b-form-group>
      </b-col>

      <b-col sm="7" md="6" class="my-1">
        <b-pagination v-model="filters.currentPage"
                      :total-rows="list.totalRows"
                      :per-page="filters.perPage"
                      align="fill"
                      size="sm"
                      class="my-0"></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>

<script lang="ts">
  import { Component, Vue } from 'vue-property-decorator'
  import FieldAccountSearch from '@/components/Shared/FieldAccountSearch.vue'
  import { api } from '@/services/api'
  import { myutils } from '@/services/myutils'
  import { EventBus } from '@/components/Shared/EventBus'

  @Component({ components: { FieldAccountSearch } })
  export default class PartnerList extends Vue {
    list = {} as PartnerListQueryOutput
    isBusy = true

    totalRows = 1
    currentPage = 1
    perPage = 10
    pageOptions = [5, 10, 20, 50, { value: 1000000, text: "Show all" }]

    fields = [
      { key: 'createdOn', label: 'Created on', sortable: true },
      { key: 'createdBy', label: 'Created by', sortable: true },
      { key: 'name', label: 'Name', sortable: true },
      { key: 'category', label: 'Category', sortable: false },
      { key: 'modifiedOn', label: 'Modified on', sortable: true },
      { key: 'modifiedBy', label: 'Modified by', sortable: true },
    ]

    filters = {
      createdBy: null as string | null,
      createdByText: null as string | null,
      country: null as string | null,
      createdOnStart: null as Date | null,
      createdOnEnd: null as Date | null,
      currentPage: 1,
      perPage: 20
    } as PartnerListInput

    created() {
      this.loadFilterFromCookies()
      this.fetchData()
      this.perPage = this.$cookies.get('PartnerList.perPage') as number ?? 20
      EventBus.$on('FieldAccountSearch_DisplayedText_Changed', this.setEmployeeText)
    }

    fetchData(): void {
      api.postExt<PartnerListInput, PartnerListQueryOutput>('/api/partners', this.filters)
        .then(data => {
          this.list = data
          this.isBusy = false
        })
    }
    loadFilterFromCookies(): void {
      const cookieFilter = this.$cookies.get('partnerList.filters') as PartnerListInput
      if (!cookieFilter) return
      Object.assign(this.filters, cookieFilter)
    }

    applyFilters(): void {
      this.$cookies.set('partnerList.filters', this.filters)
      this.fetchData()
    }

    clearFilters(): void {
      myutils.setPropertiesToNullOrEmptyArray(this.filters)
      this.filters.perPage = 20
      this.filters.currentPage = 1
      this.fetchData()
    }

    get hasFilters(): boolean {
      return this.filters.createdBy != null ||
        this.filters.country != null ||
        this.filters.createdOnEnd != null ||
        this.filters.createdOnStart != null 
    }

    setEmployeeText(value: string) {
      this.filters.createdByText = value
    }

    onPerPageChanged(): void {
      this.$cookies.set('partnerList.perPage', this.perPage)
      this.fetchData()
    }
  }

  interface PartnerListQueryOutput {
    items: PartnerListQueryOutputItem[];
    totalRows: number;
  }

  interface PartnerListQueryOutputItem {
    id?: number;
    name?: string;
    isPartnerRealEstate?: boolean;
    isPartnerTourism?: boolean;
  }
  interface PartnerListInput {
    createdBy: string | null;
    createdByText: string | null;
    country: string | null;
    createdOnStart: Date | null;
    createdOnEnd: Date | null;
    currentPage: number;
    perPage: number;
  }

</script>

<style scoped lang="scss"></style>
