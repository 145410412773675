<template>
    <b-form @submit="send">
        <logo></logo>
        <div v-if="!emailSent">
            <b-form-group id="input-group-1"
                          class="custom-label"
                          label="email"
                          label-for="forgot-password-email">
                <b-form-input id="forgot-password-email"
                              v-model="email"
                              type="email"
                              required></b-form-input>
            </b-form-group>

            <b-button class="btn-block" type="submit" variant="primary">Send reset link</b-button>
        </div>
        <div v-if="emailSent" class="row justify-content-center align-items-center">
            Password reset link sent to your mail box
        </div>
        <div v-if="emailSent" class="row justify-content-center align-items-center">
            <a href="#" @click="emailSent=false">Send once again</a>
        </div>
    </b-form>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import { api } from '@/services/api'
    import Logo from '@/components/Shared/Logo.vue'

    @Component({
        components: {
            Logo
        }
    })
    export default class ForgotPassword extends Vue {
        email: string | null = null
        emailSent = false

        send(event: Event): void {
            event.preventDefault()
            if (this.email) {
                api.post('/api/authenticate/forgotPassword', { email: this.email })
                    .then(() => {
                        this.emailSent = true
                    })
            }
        }
    }
</script>
<style>
    .my-form {
        margin-top: 60px;
        background-color: white;
        opacity: 0.8;
        padding: 10px;
        border-color: #1861ac;
        border-width: 1px;
        border-style: solid;
        border-radius: 5px;
    }
</style>
