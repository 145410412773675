<template>
    <account-edit v-if="account.id" :account="account" />
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import AccountDto from '@/models/AccountDto'
    import { api } from '@/services/api'
    import accountEdit from './../../components/Accounts/AccountEdit.vue'

    interface AccountEditResponse {
        dto: AccountDto
    }

    @Component({
        components: {
            accountEdit
        }
    })
    export default class AccountEditRequest extends Vue {
        @Prop({ type: Number, required: true }) id!: number

        public account: AccountDto = new AccountDto()
        constructor() {
            super()
            this.fetchData()
        }

        fetchData() {
            api.get<AccountEditResponse>(`/api/account/edit/${this.id}`)
                .then((data: AccountEditResponse) => this.account = data.dto)
        }
    }
</script>

<style scoped></style>
