export const myutils = {
    setPropertiesToNullOrEmptyArray,
    hasAnyValue
}

function setPropertiesToNullOrEmptyArray(obj: any) {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (Array.isArray(obj[key])) {
                obj[key] = [];
            } else {
                obj[key] = null;
            }
        }
    }
}

function hasAnyValue(obj: any): boolean {
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value = obj[key];

            if (
                value !== null &&
                value !== '' &&
                (!Array.isArray(value) || value.length > 0)
            ) {
                return false;
            }
        }
    }
    return true;
}