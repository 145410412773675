import Vue from 'vue';
import Vuex from 'vuex';
import { AuthenticationState } from '@/models/AuthenticationState'
import { authentication } from './authentication.module';
import { alert } from './alert.module';
import AlertState from '@/models/AlertState'

Vue.use(Vuex);

export interface State {
    authentication: AuthenticationState,
    alert: AlertState
}
export default new Vuex.Store<State>({
    modules: {
        authentication,
        alert
    }
});