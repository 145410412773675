<template>
    <div class="container-md">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Payments') }}</h1>
            </div>
            <div class="col-4 text-right">
                <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{list.items.length}}</b-badge>
            </div>
        </div>
        <b-input-group :prepend="$t('Search')+':'" class="mb-2" v-if="list.items.length">
            <b-form-input v-model="searchText" type="search"></b-form-input>
        </b-input-group>
        <!--<b-button variant="primary" to="/Payments/AccountAdd" class="mr-4">
      <font-awesome-icon icon="user" class="mr-1" />
      {{ $t('Add Account') }}
    </b-button>-->
        <table class="table table-hover">
            <sortable-columns :headers="headers"
                              :sortColumnIndex="sortColumnIndex"
                              :sortDescending="sortDescending"
                              v-on:onColumnClick="sortByColumn" />
            <tbody>
                <tr v-for="item in items" v-bind:key="item.id">
                    <td>{{ item.transactionModifiedOn | date_short }}</td>
                    <td>
                        <router-link :to="{ name: 'Payments/View', params: { id: item.id } }">
                            {{ item.name }}
                        </router-link>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
    /* tslint:disable */
    import { Component, Mixins } from 'vue-property-decorator'
    import NavItemLang from './../../components/NavItemLang.vue'
    import { api } from '@/services/api'
    import { AccountListOutput, AccountListOutputItem } from '@/models/Transactions/AccountListOutput'
    import SortableColumns from '@/components/Shared/SortableColumns.vue'
    import SortableList from '@/views/Shared/SortableList.vue'

    @Component({
        components: {
            NavItemLang,
            SortableColumns
        }
    })
    export default class TransactionAccountList extends Mixins(SortableList) {
        public list: AccountListOutput = new AccountListOutput()
        constructor() {
            super()
            this.fetchData()
        }

        async fetchData(): Promise<void> {
            api
                .get<AccountListOutput>('/api/transactions/accounts')
                .then((data) => {
                    this.list = data
                })
        }
        headers = ['Modified on', 'Name']
        columns = [
            'createdOn',
            'createdBy',
            'name',
            'isAccountant,isAttorney,isCleaner,isClient,isConstructionEngineer,isEconomist,isElectrician,isEmploye,isInteriorDesigner,isIT,isLandscapeDesigner,isLead,isLocksmith,isNotary,isOwner,isPlumber,isProfessional,isSecurityService,isSource,isTranslator,isWebDesigner,isWorker',
            'modifiedOn',
            'modifiedBy'
        ]

        searchText = ''
        get items(): AccountListOutputItem[] {
            return this.searchText === '' ? this.list.items :
                this.list.items.filter((el) => el.name?.toLowerCase().split(' ').some((w) => w.startsWith(this.searchText.toLowerCase())))
        }
    }
</script>

<style scoped lang="scss"></style>
