<template>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1 class="mt-3">{{ $t('Objects') }}</h1>
            </div>
            <div class="col-4 text-right">
                <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{(data && data.totalRows) ? data.totalRows : 0}}</b-badge>
            </div>
        </div>
        <div class="mb-2">
            <b-button variant="primary" to="/Objects/Add" class="mr-2">{{ $t('Add') }}</b-button>
            <b-button variant="primary" to="/Objects/Search">{{ $t('Search') }}</b-button>
        </div>
        <b-table :items="tableItems" :fields="fields" primary-key="id" hover :busy="isBusy"
                 sticky-header="70vh">
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(createdOn)="data">
                {{ data.item.createdOn | date_short }}
            </template>
            <template #cell(modifiedOn)="data">
                {{ data.item.modifiedOn | date_short }}
            </template>
            <template #cell(referenceNumber)="data">
                <router-link :to="{ name: 'Objects/View', params: { id: data.item.id } }">
                    {{ data.item.referenceNumber }}
                </router-link>
            </template>
            <template #cell(type)="data">
                {{ $t(data.item.type) }}
            </template>
            <template #cell(owner)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.ownerId } }">
                    {{ data.item.owner }}
                </router-link>
            </template>
        </b-table>
        <b-row>
            <b-col sm="2" md="3" class="my-1">
                <b-form-group label="Per page"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-form-select id="per-page-select"
                                   v-model="filters.perPage"
                                   :options="pageOptions"
                                   size="sm"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1" v-if="data && data.totalRows > 0">
                <b-pagination v-model="filters.currentPage"
                              :total-rows="data ? data.totalRows : 0"
                              :per-page="filters.perPage"
                              align="fill"
                              size="sm"
                              class="my-0">
                </b-pagination>                              
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { api } from '@/services/api'

    export interface IFilters {
        currentPage: number;
        perPage: number;
    }

    export interface IData {
        items: any[];
        totalRows: number;
    }

    @Component
    export default class ObjectList extends Vue {
        public data: IData | null = null
        isBusy = true
        private error: Error | null = null
        private filters: IFilters = {
            currentPage: 1,
            perPage: 20
        }
        pageOptions = [5, 20, 100]

        created() {
            let storedPerPage = parseInt(this.$cookies.get('ObjectList.perPage'))
            const storedCurrentPage = parseInt(this.$cookies.get('ObjectList.currentPage'))

            this.filters.perPage = storedPerPage ? storedPerPage : 20
            this.filters.currentPage = storedCurrentPage ? storedCurrentPage : 1
            
            this.fetchData()
        }

        fields = [
            { key: 'createdOn', label: 'Created', sortable: true },
            { key: 'createdBy', label: 'by', sortable: true },
            { key: 'referenceNumber', label: '#', sortable: true },
            { key: 'type', label: 'Type', sortable: true },
            { key: 'location', label: 'Location', sortable: true },
            { key: 'owner', label: 'Owner', sortable: true },
            { key: 'modifiedOn', label: 'Modified on', sortable: true },
            { key: 'modifiedBy', label: 'Modified by', sortable: true },
        ]

        fetchData(): void {
            this.isBusy = true
            api
                .postExt<IFilters, IData>('/api/object', this.filters)
                .then((response) => {
                    this.data = response
                    this.isBusy = false
                })
                .catch((error) => {
                    this.isBusy = false
                    console.error('Error fetching data:', error)
                })
        }

        @Watch('filters.perPage')
        onPerPageChanged(): void {
            this.$cookies.set('ObjectList.perPage', this.filters.perPage)
            this.fetchData()
        }

        @Watch('filters.currentPage')
        onCurrentPageChanged(): void {
            this.$cookies.set('ObjectList.currentPage', this.filters.currentPage)
            this.fetchData()
        }
        
        get tableItems() {
            if (!this.data || !this.data.items) {
                return []
            }
            return [...this.data.items]
        }
    }
</script>
