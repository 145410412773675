<template>
    <b-nav-item-dropdown :text="langs[language]" right>
        <b-dropdown-item v-for="(lang, index) in langs"
                         :key="lang.Key"
                         v-on:click="setLocale(lang, index)">{{ lang }}</b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { EventBus } from '@/components/Shared/EventBus'

    interface Dictionary<T> {
        [Key: string]: T
    }
    @Component
    export default class NavItemLang extends Vue {
        private langs: Dictionary<string> = {}
        language = ''

        created() {
            this.langs['en-UK'] = 'English'
            this.langs['el'] = 'Ελληνικά'
            this.langs['ru'] = 'Русский'
            this.language = this.$cookies.get('language') as string
            if(!this.language) this.language = 'en-UK'
            this.$root.$i18n.locale = this.language
        }

        setLocale(name: string, newLanguage: string) {
            this.language = newLanguage
            this.$cookies.set('language', this.language)
            this.$root.$i18n.locale = this.language
            api
                .post('/api/UserSettings/Language', { language: this.language })
                .then(() => EventBus.$emit('langChanged'))
        }
    }
</script>
