<template>
    <b-form @submit="login">
        <logo></logo>
        <b-form-group id="input-group-1"
                      :label="$t('email')"
                      class="custom-label"
                      label-for="login-form-username">
            <b-form-input id="login-form-username"
                          v-model="model.username"
                          type="email"
                          required></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="password" label-for="login-form-password" class="custom-label">
            <b-form-input id="login-form-password"
                          type="password"
                          v-model="model.password"
                          required></b-form-input>
        </b-form-group>
        <div class="text-center">
            <b-alert v-if="alert.message" show :variant="alert.type" class="mb-2" v-html="alert.message"></b-alert>
            <b-button class="btn-block" type="submit" variant="primary" :disabled="loggingIn">{{ $t('login') }}</b-button>
            <b-form-group class="mt-4">
                <router-link to="/ForgotPassword">{{ $t('forgot your password') }}?</router-link>
            </b-form-group>
        </div>
        <img v-show="loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
    </b-form>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator';
    import LoginModel from '@/models/LoginModel'
    import Logo from '@/components/Shared/Logo.vue'
    import AlertState from '@/models/AlertState'

    @Component({
        components: {
            Logo
        }
    })
    export default class LoginForm extends Vue {
        model: LoginModel = { username: '', password: '' }
        submitted = false

        constructor() {
            super()
            this.$store.dispatch('authentication/logout');
        }
        get loggingIn(): boolean {
            return this.$store.state.authentication.status.loggingIn;
        }
        login(event: Event): void {
            this.$store.dispatch('alert/clear')
            event.preventDefault()
            this.submitted = true;
            if (this.model.username && this.model.password) {
                this.$store.dispatch('authentication/login', this.model);
            }
        }
        get alert(): AlertState {
            return this.$store.state.alert
        }
    }
</script>
<style>
</style>
