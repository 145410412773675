<template>
    <div>
        <navbar :showBackButton="true" :header="$t('account') | capitalize">
            <template v-slot:visibleButtons>
                <b-nav-text class="mr-2">
                    <toggle-button :width="25 + $tc('Edit').length * 6"
                                   :value="false"
                                   :labels="{ checked: $tc('Edit'), unchecked: $tc('Edit') }"
                                   v-on:change="viewModeChange()" />
                </b-nav-text>
                <b-nav-text v-if="viewMode != VIEW_MODE_VIEW">
                    <b-button class="mr-2" v-on:click="editModeChange()" size="sm">
                        {{ editModeLabel }}
                    </b-button>
                </b-nav-text>
            </template>
            <template v-slot:hiddenButtons>
                <b-dropdown-item v-on:click="deleteRequest()">
                    <font-awesome-icon size="lg" icon="trash" /> {{ $t('Delete account') }}
                </b-dropdown-item>
                <b-dropdown-item :to="{ name: 'Leads/Add', params: { id: id } }">
                    {{ $t('Add lead') }}
                </b-dropdown-item>
            </template>
        </navbar>
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <div class="container mt-3">
            <div>
                <accountViewFields v-if="viewMode != VIEW_MODE_EDIT_EXPANDED"
                                   :id="id"
                                   v-on:referenceNumberReceived="referenceNumber = $event"
                                   :view-mode="viewMode"></accountViewFields>
                <accountEditRequest v-else :id="id"></accountEditRequest>
            </div>
        </div>
        <b-modal id="deleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete account?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { Route } from 'vue-router'
    import navbar from './../../components/Navbar.vue'
    import accountEditRequest from './../../components/Accounts/AccountEditRequest.vue'
    import accountViewFields from './../../components/Accounts/AccountViewFields.vue'
    import { VIEW_MODE_VIEW, VIEW_MODE_EDIT, VIEW_MODE_EDIT_EXPANDED } from '@/misc/viewConst'
    import { api } from '@/services/api'

    @Component({
        components: {
            navbar,
            accountViewFields,
            accountEditRequest
        }
    })
    export default class AccountView extends Vue {
        public id: number | null = null
        public referenceNumber = ''
        public buttons = ['edit']
        public viewMode = VIEW_MODE_VIEW
        public error: string | null = null

        // workaround for [Vue warn]: Property or method "VIEW_MODE_EDIT_EXPANDED" is not defined on the instance but referenced during render. Make sure that this property is reactive, either in the data option, or for class-based components, by initializing the property. See: https://vuejs.org/v2/guide/reactivity.html#Declaring-Reactive-Properties.
        public VIEW_MODE_VIEW = VIEW_MODE_VIEW
        public VIEW_MODE_EDIT = VIEW_MODE_EDIT
        public VIEW_MODE_EDIT_EXPANDED = VIEW_MODE_EDIT_EXPANDED

        created(): void {
            this.id = Number(this.$route.params.id)
            if (this.$route.params.viewMode) {
                this.viewMode = parseInt(this.$route.params.viewMode)
            }
        }
        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            this.id = +r.params.id
        }


        viewModeChange(): void {
            this.viewMode = this.viewMode === VIEW_MODE_VIEW ? VIEW_MODE_EDIT : VIEW_MODE_VIEW
        }

        editModeChange(): void {
            this.viewMode =
                this.viewMode === VIEW_MODE_EDIT_EXPANDED ? VIEW_MODE_EDIT : VIEW_MODE_EDIT_EXPANDED
        }

        //get header(): string {
        //  return Vue.options.filters.capitalize(this.$tc('account'))
        //}

        get editModeLabel(): string {
            return this.viewMode === VIEW_MODE_EDIT_EXPANDED ? this.$tc('Collapse') : this.$tc('More')
        }

        deleteRequest(): void {
            this.$bvModal.show('deleteConfirmation')
        }

        deleteConfirmed(): void {
            if (this.id === undefined) return
            api.del(`/api/account/${this.id}`)
                .then(() => {
                    window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
                }
            )
        }
    }
</script>

<style scoped></style>
