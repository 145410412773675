import { ObjectDto } from '@/models/ObjectDto'
import SelectListItem from '@/models/SelectListItem'
/* eslint-disable  @typescript-eslint/no-explicit-any */

export class ObjectEditQueryOutput {
  object: ObjectDto = {}
  objectSelectLists = new ObjectSelectLists()
  objectAccounts: any
  objectAccountRelationSelectList: any
}

export class ObjectSelectLists {
  objectTypes: SelectListItem[] = []
  objectSubTypes: SelectListItem[] = []
  accessFrom: SelectListItem[] = []
  orientation: SelectListItem[] = []
  floorsType: SelectListItem[] = []
  framesType: SelectListItem[] = []
  energyClass: SelectListItem[] = []
  zone: SelectListItem[] = []
  heatingMedium: SelectListItem[] = []
  heating: SelectListItem[] = []
}
