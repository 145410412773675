<template>
    <div>
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <div class="container">
            <div class="row mb-3">
                <div class="col-lg">
                    <h3>
                        <field v-if="item.isCompany"
                               name="company"
                               type="text"
                               :value="item.name"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field name="lastName"
                               type="text"
                               :value="item.lastName"
                               :edit="isEdit"
                               v-on:click="edit" />&nbsp;
                        <field name="firstName"
                               type="text"
                               :value="item.firstName"
                               :edit="isEdit"
                               v-on:click="edit" />&nbsp;
                        <field name="middleName"
                               type="text"
                               :value="item.middleName"
                               :edit="isEdit"
                               v-on:click="edit" />
                    </h3>
                </div>
            </div>
            <div v-if="item.isClient" class="row mb-3">
                <div class="col-lg">
                    <div>
                        <h6 class="mt-2">{{ $t('Request') }}</h6>
                        <field-range-currency :min="item.purchaseBudgetRangeMin"
                                              :max="item.purchaseBudgetRangeMax"
                                              :edit="isEdit"
                                              v-on:click="edit"
                                              name="purchaseBudgetRangeMin" />
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-lg">
                    <div>
                        <h6 class="mt-2">{{ $t('Account type') }}</h6>
                        <field v-if="item.isOwner != null"
                               name="isOwner"
                               type="boolNullable"
                               :value="item.isOwner"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isClient != null"
                               name="isClient"
                               type="boolNullable"
                               :value="item.isClient"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isEmploye != null"
                               name="isEmploye"
                               type="boolNullable"
                               :value="item.isEmploye"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isSource != null"
                               name="isSource"
                               type="boolNullable"
                               :value="item.isSource"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isProfessional != null"
                               name="isProfessional"
                               type="boolNullable"
                               :value="item.isProfessional"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isPartnerRealEstate != null"
                               name="isPartnerRealEstate"
                               type="boolNullable"
                               :value="item.isPartnerRealEstate"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isPartnerTourism != null"
                               name="isPartnerTourism"
                               type="boolNullable"
                               :value="item.isPartnerTourism"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.hasTransactions != null"
                               name="hasTransactions"
                               type="boolNullable"
                               :value="item.hasTransactions"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <router-link v-if="item.hasTransactions" :to="{ name: 'Payments/View', params: { id: item.id } }">
                            Go to payments
                        </router-link>
                    </div>
                </div>
            </div>
            <div v-if="item.isOwner" class="row">
                <div class="col-lg">
                    <p>
                        Owned object(s):
                        <router-link v-for="o in item.ownedObjectsReferenceNumbers.split(' ').map(function (id) {
                return 'ref' + id
              })"
                                     v-bind:key="o"
                                     :to="{ name: 'Objects/View', params: { id: o } }">
                            {{ o.replace('ref', '#') }}
                        </router-link>
                    </p>
                </div>
            </div>
            <div v-if="item.boughtObjectsReferenceNumbers" class="row">
                <div class="col-lg">
                    <p>
                        Bought object(s):
                        <router-link v-for="o in item.boughtObjectsReferenceNumbers.split(' ').map(function (id) {
                return 'ref' + id
              })"
                                     v-bind:key="o"
                                     :to="{ name: 'Objects/View', params: { id: o } }">
                            {{ o.replace('ref', '#') }}
                        </router-link>
                    </p>
                </div>
            </div>
            <div v-if="item.isClient" class="row">
                <div class="col-lg">
                    <p>
                        Rented object(s):
                        <router-link v-for="o in item.rentedObjectsReferenceNumbers.split(' ').map(function (id) {
                return 'ref' + id
              })"
                                     v-bind:key="o"
                                     :to="{ name: 'Objects/View', params: { id: o } }">
                            {{ o.replace('ref', '#') }}
                        </router-link>
                    </p>
                </div>
            </div>
            <div v-if="item.leads && item.leads.length > 0" class="row">
                <div class="col-lg">
                    <p>
                        Leads:
                        <router-link v-for="(o, idx) in item.leads"
                                     v-bind:key="idx"
                                     :to="{ name: 'Leads/View', params: { id: o.id } }">
                            <br />{{ o.descr }}
                        </router-link>
                    </p>
                </div>
            </div>
            <div v-if="item.isProfessional" class="row mb-3">
                <div class="col-lg">
                    <div>
                        <h6 class="mt-2">{{ $t('Specialty') }}</h6>
                        <field v-if="item.isNotary != null"
                               name="isNotary"
                               type="boolNullable"
                               :value="item.isNotary"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isAttorney != null"
                               name="isAttorney"
                               type="boolNullable"
                               :value="item.isAttorney"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isAccountant != null"
                               name="isAccountant"
                               type="boolNullable"
                               :value="item.isAccountant"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isEconomist != null"
                               name="isEconomist"
                               type="boolNullable"
                               :value="item.isEconomist"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isTranslator != null"
                               name="isTranslator"
                               type="boolNullable"
                               :value="item.isTranslator"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isWorker != null"
                               name="isWorker"
                               type="boolNullable"
                               :value="item.isWorker"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isCleaner != null"
                               name="isCleaner"
                               type="boolNullable"
                               :value="item.isCleaner"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isElectrician != null"
                               name="isElectrician"
                               type="boolNullable"
                               :value="item.isElectrician"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isPlumber != null"
                               name="isPlumber"
                               type="boolNullable"
                               :value="item.isPlumber"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isLocksmith != null"
                               name="isLocksmith"
                               type="boolNullable"
                               :value="item.isLocksmith"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isIT != null"
                               name="isIT"
                               type="boolNullable"
                               :value="item.isIT"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isConstructionEngineer != null"
                               name="isConstructionEngineer"
                               type="boolNullable"
                               :value="item.isConstructionEngineer"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isInteriorDesigner != null"
                               name="isInteriorDesigner"
                               type="boolNullable"
                               :value="item.isInteriorDesigner"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isLandscapeDesigner != null"
                               name="isLandscapeDesigner"
                               type="boolNullable"
                               :value="item.isLandscapeDesigner"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isSecurityService != null"
                               name="isSecurityService"
                               type="boolNullable"
                               :value="item.isSecurityService"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isWebDesigner != null"
                               name="isWebDesigner"
                               type="boolNullable"
                               :value="item.isWebDesigner"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isEnergySupplyCompany != null"
                               name="isEnergySupplyCompany"
                               type="boolNullable"
                               :value="item.isEnergySupplyCompany"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isElectricalStore != null"
                               name="isElectricalStore"
                               type="boolNullable"
                               :value="item.isElectricalStore"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isTechnician != null"
                               name="isTechnician"
                               type="boolNullable"
                               :value="item.isTechnician"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field v-if="item.isRealEstateAgencyOrBroker != null"
                               name="isRealEstateAgencyOrBroker"
                               type="boolNullable"
                               :value="item.isRealEstateAgencyOrBroker"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <field name="numberOfSoldObjects"
                               type="number"
                               :value="item.numberOfSoldObjects"
                               :edit="isEdit"
                               v-on:click="edit"
                               suffix=" objects sold" />
                    </div>
                </div>
            </div>
            <div v-if="item.isProfessional" class="row mb-3">
                <div class="col-lg">
                    <div>
                        <h6 class="mt-2">{{ $t('language') }}</h6>
                        <field name="language" :value="item.language" :edit="isEdit" v-on:click="edit" />
                    </div>
                </div>
            </div>
            <div v-if="item.isProfessional" class="row mb-3">
                <div class="col-lg">
                    <div>
                        <h6 class="mt-2">{{ $t('country') }}</h6>
                        <field name="country" :value="item.country" :edit="isEdit" v-on:click="edit" />
                    </div>
                </div>
            </div>
            <div v-if="item.isEmploye" class="row mb-3">
                <div class="col-lg">
                    <div v-if="item.startDate || item.endDate">
                        <h6 class="mt-2">{{ $t('Work period') }}</h6>
                        <span v-if="item.startDate">{{ $t('startDate') }} </span>
                        <field name="startDate"
                               :value="item.startDate"
                               :edit="isEdit"
                               v-on:click="edit"
                               type="date" />
                        <br v-if="item.startDate && item.endDate" />
                        <span v-if="item.endDate">{{ $t('endDate') }} </span>
                        <field name="endDate"
                               :value="item.endDate"
                               :edit="isEdit"
                               v-on:click="edit"
                               type="date" />
                    </div>
                    <div>
                        <h6 class="mt-2">{{ $t('Role') }}</h6>
                        <span>{{ item.role ? item.role : 'not set' }}</span>
                        <router-link class="ml-2" :to="{ name: 'ChangeEmployeRole', params: { id: item.id } }">{{ $t('Change role') }}</router-link>
                        <router-link class="ml-2" :to="{ name: 'SetPassword', params: { id: item.id } }">{{ $t('Set password') }}</router-link>
                    </div>
                    <div>
                        <h6 class="mt-2">{{ $t('Branch') }}</h6>
                        <span>{{ item.branchId ? 'Xanthi' : 'not set' }}</span>
                    </div>
                </div>
            </div>

            <!--<div class="mb-3">
          <field v-if="item.isClient != null" name="isClient" type="boolNullable" :value=item.isClient :edit="isEdit" v-on:click="edit" />
          <field v-if="item.isEmploye != null" name="isEmploye" type="boolNullable" :value=item.isEmploye :edit="isEdit" v-on:click="edit" />
          <field v-if="item.isSource != null" name="isSource" type="boolNullable" :value=item.isSource :edit="isEdit" v-on:click="edit" />
          <field v-if="item.isProfessional != null" name="isProfessional" type="boolNullable" :value=item.isProfessional :edit="isEdit" v-on:click="edit" />
        </div>-->
            <div class="mb-3">
                <field v-if="item.isIndividual != null"
                       name="isIndividual"
                       type="boolNullable"
                       :value="item.isIndividual"
                       :edit="isEdit"
                       v-on:click="edit" />
                <field v-if="item.isCompany != null"
                       name="isCompany"
                       type="boolNullable"
                       :value="item.isCompany"
                       :edit="isEdit"
                       v-on:click="edit" />
            </div>
            <div class="mb-3">
                <field v-if="item.companyType != null"
                       name="companyType"
                       type="text"
                       :value="item.companyType"
                       :edit="isEdit"
                       v-on:click="edit" />
            </div>
            <div class="row mb-3">
                <div class="col-lg">
                    <div>
                        <div v-if="item.firstNameEng || item.lastNameEng || item.middleNameEng">
                            <h6 class="mt-2">{{ $t('Name in English') }}</h6>
                            <field name="firstNameEng"
                                   type="text"
                                   :value="item.firstNameEng"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                            <field name="middleNameEng"
                                   type="text"
                                   :value="item.middleNameEng"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                            <field name="lastNameEng"
                                   type="text"
                                   :value="item.lastNameEng"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.firstNameLocal || item.lastNameLocal || item.middleNameLocal">
                            <h6 class="mt-2">{{ $t('Name in language of account') }}</h6>
                            <field name="firstNameLocal"
                                   type="text"
                                   :value="item.firstNameLocal"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                            <field name="middleNameLocal"
                                   type="text"
                                   :value="item.middleNameLocal"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                            <field name="lastNameLocal"
                                   type="text"
                                   :value="item.lastNameLocal"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.language">
                            <h6 class="mt-2">{{ $t('language') }}</h6>
                            <field name="language"
                                   type="text"
                                   :value="item.language"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.nationality">
                            <h6 class="mt-2">{{ $t('Nationality') }}</h6>
                            <field name="nationality"
                                   type="text"
                                   :value="item.nationality"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.email">
                            <h6 class="mt-2">{{ $t('Emails') }}</h6>
                            <field name="email"
                                   type="text"
                                   :value="item.email"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.phone">
                            <h6 class="mt-2">{{ $t('Phone numbers') }}</h6>
                            <field name="phone"
                                   type="text"
                                   :value="item.phone"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.internalPassportNumber">
                            <h6 class="mt-2">{{ $t('internalPassportNumber') }}</h6>
                            <field name="internalPassportNumber"
                                   type="text"
                                   :value="item.internalPassportNumber"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.passportNumber">
                            <h6 class="mt-2">{{ $t('passportNumber') }}</h6>
                            <field name="passportNumber"
                                   type="text"
                                   :value="item.passportNumber"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.taxNumber || item.taxNumberOfOtherCountry">
                            <h6 class="mt-2">{{ $t('Tax number') }}</h6>
                            <field name="taxNumber"
                                   type="text"
                                   :value="item.taxNumber"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                            <h6 class="mt-2">{{ $t('Tax number other country') }}</h6>
                            <field name="taxNumberOfOtherCountry"
                                   type="text"
                                   :value="item.taxNumberOfOtherCountry"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>
                        <div v-if="item.postcode || item.city || item.address">
                            <h6 class="mt-2">{{ $t('Address') }}</h6>
                            <field name="city"
                                   type="text"
                                   :value="item.city"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                            <field name="postcode"
                                   type="text"
                                   :value="item.postcode"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                            <field name="address"
                                   type="text"
                                   :value="item.address"
                                   :edit="isEdit"
                                   v-on:click="edit" />
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div id="serviceInfo" class="container mb-3">
            <b-card :title="$t('Service Information')">
                <dt>{{ $t('Modified') }}:</dt>
                <dd>{{ item.modifiedOn | date }}, by {{ item.modifiedBy }}</dd>
                <dt>{{ $t('Created') }}:</dt>
                <dd>{{ item.createdOn | date }}, by {{ item.createdBy }}</dd>
            </b-card>
        </div>
        <div class="container">
            <assignment parent="Account" :parentId="id"></assignment>
        </div>

        <div class="container">
        <fileLinks :items="item.files"
                   :viewMode="viewMode"
                   parent="account"
                   :parentId="item.id"></fileLinks>
        </div>

        <div class="container">
            <ActivityList2 :parentEntity="entityType" :parentId="id"></ActivityList2>
        </div>
        <fieldEditor />
    </div>
</template>

<script lang="ts">
    import field from './../Shared/Field.vue'
    import fieldRow from './../Shared/FieldRow.vue'
    import fieldEditor from './../Shared/FieldEditor.vue'
    import { EventBus } from './../Shared/EventBus'
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import AccountDto from './../../models/AccountDto'
    import FieldRangeCurrency from './../../components/Shared/FieldRangeCurrency.vue'
    import ActivityList2 from './../../components/Activity/ActivityList2.vue'
    import { EntityType } from '../../models/Enums'
    import FileLinks from './../Shared/FileLinks.vue'
    import Assignment from './../Shared/Assignment.vue'

    @Component({
        components: {
            field,
            fieldRow,
            fieldEditor,
            FieldRangeCurrency,
            ActivityList2,
            FileLinks,
            Assignment
        }
    })
    export default class AccountViewFields extends Vue {
        @Prop({ type: Number, required: true }) viewMode!: number
        @Prop(Number) id: number | undefined

        @Watch('id')
        onIdChange(): void {
            this.fetchData()
        }

        readonly VIEW_MODE_VIEW = 0
        readonly VIEW_MODE_EDIT = 1
        readonly VIEW_MODE_EDIT_EXPANDED = 2

        get isEdit(): boolean {
            return this.viewMode === this.VIEW_MODE_EDIT
        }

        public item: AccountDto = new AccountDto()
        public error: string | null = null
        entityType = EntityType.Account

        created() {
            this.fetchData()
            EventBus.$on('viewUpdated', this.updateItem)
        }

        async fetchData(): Promise<void> {
            if (!this.id) {
                return
            }
            api.get<AccountDto>(`/api/account/view/${this.id}`)
                .then((dto: AccountDto) => this.item = dto);
        }

        edit(fieldName: string, type: string): void {
            this.showFieldEditor(fieldName, type)
        }

        showFieldEditor(fieldName: string, type: string): void {
            EventBus.$emit('fieldEdit', {
                entity: 'account',
                id: this.item.id,
                label: this.$t(fieldName),
                name: fieldName,
                type: type,
                // eslint-disable-next-line
                value: (this.item as any)[fieldName]
            })
        }

        updateItem(event: AccountDto, entity: string): void {
            if (entity == 'account') this.item = event
        }
    }
</script>

<style scoped></style>
