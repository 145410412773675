<template>
  <div>
    <error-box :error="error" />
    <div id="loadingSpinner" v-if="!item && !error" class="d-flex justify-content-center">
      <b-spinner small label="Busy"></b-spinner><span class="ml-1">{{ $t('Loading...') }}</span>
    </div>
    <section>
      <div id="firstRow" class="container mb-3" v-if="item">
        <div class="row">
          <div class="col-lg">
            <h3 style="display: inline" class="mr-3">
              <span v-if="item.forSale">
                {{ $t('forSale') }}
                <field name="salePrice"
                       type="currency"
                       :value="item.salePrice"
                       :edit="isEdit"
                       v-on:click="edit" />
              </span>
              <span v-if="item.salePriceWithDiscount"> | </span>
              <span v-if="item.salePriceWithDiscount">
                {{ $t('forSale') }}
                <field name="salePriceWithDiscount"
                       type="currency"
                       :value="item.salePriceWithDiscount"
                       :edit="isEdit"
                       v-on:click="edit" />
              </span>
              <span v-if="item.forSale && item.forRent"> | </span>
              <span v-if="item.forRent">
                {{ $t('forRent') }}
                <field name="rentPrice"
                       type="currency"
                       :value="item.rentPrice"
                       :edit="isEdit"
                       v-on:click="edit" />
              </span>
              <span v-if="item.rentPriceWithDiscount"> | </span>
              <span v-if="item.rentPriceWithDiscount">
                {{ $t('Price with discount') }}
                <field name="rentPriceWithDiscount"
                       type="currency"
                       :value="item.rentPriceWithDiscount"
                       :edit="isEdit"
                       v-on:click="edit" />
              </span>
              <span v-if="(item.forSale || item.forRent) && item.forShortRent"> | </span>
              <span v-if="item.forShortRent">
                {{ $t('forShortRent') }}
                <field name="shortRentPrice"
                       type="currency"
                       :value="item.shortRentPrice"
                       :edit="isEdit"
                       v-on:click="edit" />
              </span>
            </h3>
            <span class="mr-3">
              <field name="type"
                     type="select"
                     :value="item.type"
                     :edit="isEdit"
                     v-on:click="edit" />
              <span v-if="item.bedrooms"> | </span>
              <field name="bathrooms"
                     type="number"
                     :value="item.bedrooms"
                     :edit="isEdit"
                     v-on:click="edit"
                     suffix=" bd" />
              <span v-if="item.bathrooms"> | </span>
              <field name="bathrooms"
                     type="number"
                     :value="item.bathrooms"
                     :edit="isEdit"
                     v-on:click="edit"
                     suffix=" ba" />
              <span v-if="item.area"> | </span>
              <field name="area" type="area" :value="item.area" :edit="isEdit" v-on:click="edit" />
            </span>
            <span>
              <field name="location"
                     type="location"
                     :value="item.location"
                     :edit="isEdit"
                     v-on:click="edit" />
            </span>
          </div>
        </div>
      </div>
      <div id="internalNotes" class="container">
        <b-card :title="$t('internalNotes')" class="mb-3">
          <field name="internalNotes"
                 type="textarea"
                 :value="item.internalNotes"
                 :edit="isEdit"
                 v-on:click="edit" />
        </b-card>
      </div>
      <div id="contacts" class="container">
        <b-card :title="$t('Contacts')" class="mb-3">
          <table class="table">
            <tbody>
              <tr v-for="a in generalAccounts" v-bind:key="a.accountId">
                <td>{{ $t(a.relationName) }}</td>
                <td>
                  <router-link :to="{ name: 'Accounts/View', params: { id: a.accountId } }">
                    {{ a.account }}
                  </router-link>
                </td>
                <td>
                  <span v-if="a.relation==RelationTypeTenantShort || a.relation==RelationTypeTenantLong">
                    <span v-if="a.startDate">
                      {{ $t('Start:') }}{{a.startDate | date_short}}
                    </span>
                    <span v-if="a.endDate">
                      {{ $t('Finish:') }}{{a.endDate | date_short}}
                    </span>
                    <span v-if="a.price">
                      {{ $t('Price:') }}{{a.price}}
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <b-button v-for="year in yearsOfTenants" :key="year" variant="primary" v-on:click="selectedYearOfTenant == year ? selectedYearOfTenant = 0 : selectedYearOfTenant = year" class="mr-4">{{year}}</b-button>
        </b-card>
      </div>
      <div id="tenantsOf" class="container" v-if="selectedYearOfTenant">
        <b-card :title="$t('Tenants of ') + selectedYearOfTenant" class="mb-3">
          <table class="table">
            <tbody>
              <tr v-for="a in tenantsOfYear(selectedYearOfTenant)" v-bind:key="a.accountId">
                <td>{{ $t(a.relationName) }}</td>
                <td>
                  <router-link :to="{ name: 'Accounts/View', params: { id: a.accountId } }">
                    {{ a.account }}
                  </router-link>
                </td>
                <td>
                  <span v-if="a.relation==RelationTypeTenantShort || a.relation==RelationTypeTenantLong">
                    <span v-if="a.startDate">
                      {{ $t('Start:') }}{{a.startDate | date_short}}
                    </span>
                    <span v-if="a.endDate">
                      {{ $t('Finish:') }}{{a.endDate | date_short}}
                    </span>
                    <span v-if="a.price">
                      {{ $t('Price:') }}{{a.price}}
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </b-card>
      </div>
      <div id="leadAndViewing" class="container">
        <div class="row mb-3">
          <div v-if="item.leads && item.leads.length > 0" class="row">
            <div class="col-lg">
              <p>
                <b-button variant="primary" v-b-toggle.collapse-leads class="mr-4">
                  {{$t('Leads / Interests')}}
                </b-button>
                <b-collapse id="collapse-leads">
                  <router-link v-for="(o, idx) in item.leads"
                               v-bind:key="idx"
                               :to="{ name: 'Leads/View', params: { id: o.id } }">
                    <br />{{ o.descr }}
                  </router-link>
                </b-collapse>

              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div v-if="item.leads && item.leads.length > 0" class="row">
            <div class="col-lg">
              <p>
                <b-button variant="primary" v-b-toggle.collapse-3 class="mr-4">
                  {{$t('Viewings')}}
                </b-button>
                <b-collapse id="collapse-3">
                  <router-link v-for="(o, idx) in item.viewings"
                               v-bind:key="idx"
                               :to="{ name: 'Viewing/Edit', params: { id: o.id } }">
                    <br />{{ o.descr }}
                  </router-link>
                </b-collapse>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="dealsAndReservations" class="container">
        <div class="row mb-3">
          <div v-if="item.deals&& item.deals.length > 0" class="row">
            <div class="col-lg">
              <p>
                Deals:
                <router-link v-for="(o, idx) in item.deals"
                             v-bind:key="idx"
                             :to="{ name: 'Deals/Edit', params: { id: o.id } }">
                  <br />{{ o.descr }}
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div v-if="item.reservations && item.reservations.length > 0" class="row">
            <div class="col-lg">
              <p>
                Reservations:
                <router-link v-for="(o, idx) in item.reservations"
                             v-bind:key="idx"
                             :to="{ name: 'Reservations/Edit', params: { id: o.id } }">
                  <br />{{ o.descr }}
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="images" class="container">
        <div class="row mb-3">
          <div class="col-lg">
            <b-carousel id="ObjectViewCarousel"
                        :interval="0"
                        controls
                        indicators
                        background="#ababab"
                        img-width="400"
                        img-height="400"
                        style="text-shadow: 1px 1px 2px #333">
              <b-carousel-slide v-for="image in imagesUrls"
                                :key="image"
                                v-bind:img-src="image"></b-carousel-slide>
            </b-carousel>
          </div>
          <div class="col-lg">
            <field name="description"
                   type="textarea"
                   :value="item.description"
                   :edit="isEdit"
                   v-on:click="edit" />
            <fieldRow name="Video"
                      v-if="item.videoURL"
                      type="url"
                      :value="item.videoURL"
                      :edit="isEdit && item.videoURL"
                      v-on:click="edit" />
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg">
            <ObjectViewMap :lat="item.latitude"
                           :lng="item.longitude"
                           :zoom="item.mapZoom"
                           :approxRadius="item.mapCircleRadius"
                           :approxLat="item.mapCircleLatitude"
                           :approxLng="item.mapCircleLongitude" />
          </div>
          <div class="col-lg">
            <table class="table table-sm table-hover">
              <tbody>
                <fieldRow name="location"
                          type="location"
                          :value="item.location"
                          :edit="isEdit"
                          v-on:click="edit" />
                <fieldRow name="address"
                          v-if="item.displayAddress"
                          type="text"
                          :value="item.address"
                          :edit="isEdit && item.displayAddress"
                          v-on:click="edit" />
                <tr>
                  <th>Link on Map</th>
                  <td><a :href="mapLink" target="_blank">Click here</a></td>
                </tr>
                <fieldRow name="zipCode"
                          type="text"
                          :value="item.zipCode"
                          :edit="isEdit"
                          v-on:click="edit" />
                <fieldRow name="distanceFromSea"
                          type="distance"
                          :value="item.distanceFromSea"
                          :edit="isEdit"
                          v-on:click="edit" />
                <fieldRow name="distanceFromAirport"
                          type="distance-km"
                          :value="item.distanceFromAirport"
                          :edit="isEdit"
                          v-on:click="edit" />
                <fieldRow name="accessFrom"
                          type="enum"
                          :value="item.accessFrom"
                          :edit="isEdit"
                          v-on:click="edit" />
                <fieldRow name="orientation"
                          type="flag-enum"
                          :value="item.orientation"
                          :edit="isEdit"
                          v-on:click="edit" />
                <fieldRow name="zone"
                          type="enum"
                          :value="item.zone"
                          :edit="isEdit"
                          v-on:click="edit" />
                <tr v-for="contact in item.contacts" v-bind:key="contact.contactId">
                  <th>{{ contact.relation }}</th>
                  <td>
                    <router-link :to="{ name: 'contact/edit', params: { id: contact.contactId } }">
                      {{ contact.contact }}
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div id="factsAndFeatures" class="container">
        <b-card :title="$t('Facts and features')" class="my-card">
          <div class="container">
            <div class="row">
              <!-- eslint-disable vue/require-v-for-key -->
              <div class="col" v-for="facts in factGroups">
                <dl class="row">
                  <template v-for="fact in facts">
                    <template v-if="hasValue(item[fact])">
                      <dt>{{ $t(fact) | capitalize }}:</dt>
                      <dd>{{ item[fact] | value }}</dd>
                    </template>
                  </template>
                </dl>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div id="offerDetails" class="container">
        <b-card :title="$t('Offer Details')" class="my-card">
          <div class="container">
            <div class="row">
              <!-- eslint-disable vue/require-v-for-key -->
              <div class="col" v-for="offerDetails in offerDetailsGroup">
                <dl class="row">
                  <template v-for="offerDetail in offerDetails">
                    <template v-if="hasValue(item[offerDetail])">
                      <dt>{{ $t(offerDetail) | capitalize }}:</dt>
                      <dd>{{ item[offerDetail] | value }}</dd>
                    </template>
                  </template>
                </dl>
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div id="serviceInfo" class="container mb-3">
        <b-card :title="$t('Service Information')">
          <dt>{{ $t('Modified') }}:</dt>
          <dd>{{ item.modifiedOn | date }}, by {{ item.modifiedBy }}</dd>
          <dt>{{ $t('Created') }}:</dt>
          <dd>{{ item.createdOn | date }}, by {{ item.createdBy }}</dd>
          <field name="active"
                 type="boolNullable"
                 :value="item.active"
                 :edit="isEdit"
                 v-on:click="edit" />
          <field name="published"
                 type="boolNullable"
                 :value="item.published"
                 :edit="isEdit"
                 v-on:click="edit" />
          <field name="featured"
                 type="boolNullable"
                 :value="item.featured"
                 :edit="isEdit"
                 v-on:click="edit" />
          <field name="soldRecently"
                 type="boolNullable"
                 :value="item.soldRecently"
                 :edit="isEdit"
                 v-on:click="edit" />
          <field name="rented"
                 type="boolNullable"
                 :value="item.rented"
                 :edit="isEdit"
                 v-on:click="edit" />
        </b-card>
      </div>
      <div id="assignment" class="container mb-3">
        <b-card :title="$t('Assignments')">
          <assignment parent="Object" :parentId="item.id"></assignment>
        </b-card>
      </div>

      <div id="activities" class="container">
        <b-card :title="$t('Activities')" class="mb-3">
          <ActivityList2 :parentEntity="entityType" :parentId="id"></ActivityList2>
        </b-card>
      </div>
    </section>
    <fieldEditor />
  </div>
</template>

<script lang="ts">
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import { Route } from 'vue-router'
  import field from './../Shared/Field.vue'
  import fieldRow from './../Shared/FieldRow.vue'
  import fieldWLabel from './../Shared/FieldWLabel.vue'
  import fieldEditor from './../Shared/FieldEditor.vue'
  import { EventBus } from './../Shared/EventBus'
  import ObjectViewMap from './ObjectViewMap.vue'
  import ActivityList2 from '@/components/Activity/ActivityList2.vue'
  import { ObjectViewDto, ObjectAccountDto } from '@/models/ObjectViewDto'
  import { api } from '@/services/api'
  import { EntityType } from '../../models/Enums'
  import ErrorBox from '@/components/Shared/ErrorBox.vue'
  import Assignment from './../Shared/Assignment.vue'

  @Component({
    components: {
      ErrorBox,
      field,
      fieldRow,
      fieldWLabel,
      fieldEditor,
      ObjectViewMap,
      ActivityList2,
      Assignment
    }
  })
  export default class ObjectViewFields extends Vue {
    @Prop({ type: Number, required: true }) viewMode!: number

    idOrRefNum: string | null = null
    public item: ObjectViewDto = new ObjectViewDto()
    public error: Error | null = null
    entityType = EntityType.Object
    id: number | null = null

    created() {
      EventBus.$on('viewUpdated', this.updateItem)
    }

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(r: Route): void {
      this.idOrRefNum = r.params.id
      this.fetchData()
    }

    fetchData(): void {
      if (!this.idOrRefNum) {
        this.error = new Error(this.$t('Object ID undefined').toString())
        return
      }
      api.get<ObjectViewDto>(`/api/object/view/${this.idOrRefNum}`)
        .then((data) => {
          this.error = null
          this.item = data
          this.$emit('referenceNumberReceived', this.item.referenceNumber)
          this.$emit('idReceived', this.item.id)
          if (!this.id) this.id = Number(this.item.id)
        })
    }

    get imagesUrls(): string[] | undefined {
      return this.item.images?.map(function (fileName) {
        return '/api/files/' + fileName
      })
    }

    readonly VIEW_MODE_VIEW = 0
    readonly VIEW_MODE_EDIT = 1
    readonly VIEW_MODE_EDIT_EXPANDED = 2

    readonly RelationTypeTenantShort = 4
    readonly RelationTypeTenantLong = 7

    get isEdit(): boolean {
      return this.viewMode === this.VIEW_MODE_EDIT
    }

    get mapLink(): string {
      return `https://www.google.com/maps/place/${this.item.latitude},${this.item.longitude}`
    }

    edit(fieldName: string, type: string): void {
      this.showFieldEditor(fieldName, type)
    }

    showFieldEditor(fieldName: string, type: string): void {
      /* eslint-disable  @typescript-eslint/no-explicit-any */
      EventBus.$emit('fieldEdit', {
        entity: 'object',
        id: this.item.id,
        label: this.$t(fieldName),
        name: fieldName,
        type: type,
        value: (this.item as any)[fieldName]
      })
    }

    updateItem(event: ObjectViewDto, entity: string): void {
      if (entity == 'object') this.item = event
    }

    factGroups = [
      [
        'area',
        'bedrooms',
        'bathrooms',
        'floor',
        'levels',
        'elevator',
        'internalStaircase',
        'livingRooms',
        'kitchens',
        'wc',
        'storageSpace',
        'playroom',
        'luxurious',
        'penthouse',
        'neoclassical',
        'lotArea',
        'buildableLand',
        'buildableArea',
        'garden',
        'swimmingPool',
        'awning',
        'parking',
        'parkingLots',
        'constructionYear',
        'underConstruction',
        'renovated',
        'renovationYear',
        'newBuilding',
        'requiredRenovation',
        'newDevelopment'
      ],
      [
        'energyClass',
        'heating',
        'heatingMedium',
        'underFloorHeating',
        'fireplace',
        'solarWaterHeating',
        'airCondition',
        'windowScreens',
        'doubleGlass',
        'framesType',
        'floorsType',
        'alarm',
        'secureDoor',
        'nightPower'
      ],
      [
        'balcony',
        'sizeOfBalconies',
        'bright',
        'facade',
        'corner',
        'airy',
        'painted',
        'disabledAccess',
        'attic',
        'view',
        'seaView',
        'firstCoastLine'
      ]
    ]

    offerDetailsGroup = [
      ['dateAvailable', 'unfinished', 'preserved', 'furnished', 'averageMonthlySharedExpenses'],
      ['student', 'petsWelcome', 'suitableForCommercialUse'],
      ['hasKey', 'hasBanner', 'investment', 'noAgentFeeForBuyer', 'active', 'published']
    ]
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    // eslint-disable-next-line
    hasValue(value: any): boolean {
      if (
        value === '' ||
        value === 'Undefined' ||
        value === 0 ||
        value === null ||
        typeof value === 'undefined'
      )
        return false
      return true
    }

    get generalAccounts(): ObjectAccountDto[] {
      return this.item.accounts.filter((i) =>
        i.endDate == null ||
        new Date(i.endDate as Date) > new Date())
    }

    get yearsOfTenants(): number[] {
      return this.item.accounts
        .filter(dto =>
          dto.endDate !== null &&
          dto.endDate !== undefined &&
          new Date(dto.endDate as Date) <= new Date()
        )
        .map(dto => new Date(dto.endDate as Date).getFullYear())
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort((a, b) => a - b)
    }

    tenantsOfYear(year: number): ObjectAccountDto[] {
      return this.item.accounts.filter((i) =>
        (parseInt(i.relation) == this.RelationTypeTenantShort || parseInt(i.relation) == this.RelationTypeTenantLong) &&
        new Date(i.endDate as Date).getFullYear() == year &&
        new Date(i.endDate as Date) < new Date())
    }

    selectedYearOfTenant = 0
  }
</script>
<style scoped></style>
