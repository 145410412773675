<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator';

    @Component
    export default class HomeView extends Vue {
        @Watch('$route', { immediate: true, deep: true })
        onRouteChange() {
            this.$store.dispatch('alert/clear');
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: 100%;
    }
</style>
