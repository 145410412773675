<template>
    <div class="container-md mt-2">
        <div id="loadingSpinner" v-if="!loaded" class="d-flex">
            <b-spinner small label="Busy"></b-spinner><span class="ml-1">{{ $t('Loading...') }}</span>
        </div>
        <b-form v-if="loaded">
            <h1>{{ expense.displayFields.accountDisplayName }}</h1>
            <h1>{{ $t(header) }}</h1>
            <error-box :error="error" />
            <b-form-row>
                <b-col class="col-2">
                    <b-form-group label-for="amount" v-bind:label="$t('amount') | capitalize">
                        <b-form-input v-model.number="expense.updateFields.amount"
                                      id="amount"
                                      type="number"
                                      autofocus></b-form-input>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <b-form-group label-for="transactionDate" v-bind:label="$t('Date') | capitalize">
                        <b-form-datepicker no-flip v-model="transactionDate" id="transactionDate"></b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-2">
                    <b-form-group label-for="transactionDate" v-bind:label="$t('Time') | capitalize">
                        <b-form-timepicker v-model="transactionTime" locale="en"></b-form-timepicker>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-group label-for="status" v-bind:label="$t('Payment status')">
                        <b-form-select v-model="expense.updateFields.status"
                                       :options="expense.lists.status"
                                       id="status"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-group label-for="paymentDate" v-bind:label="$t('Payment date')">
                        <b-form-datepicker no-flip v-model="expense.updateFields.paymentDate"
                                           id="paymentDate"></b-form-datepicker>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-group label-for="paymentMethod" v-bind:label="$t('payment method') | capitalize">
                        <b-form-select v-model="expense.updateFields.paymentMethod"
                                       :options="expense.lists.paymentMethod"
                                       id="paymentMethod"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-group label-for="categoryId" v-bind:label="$t('category') | capitalize">
                        <b-form-select v-model="expense.updateFields.categoryId"
                                       :options="expense.lists.categoryId"
                                       id="categoryId"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-group label-for="expirationDate" v-bind:label="$t('Expiration date')">
                        <b-form-datepicker no-flip v-model="expense.updateFields.expirationDate"
                                           id="expirationDate"></b-form-datepicker>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-3">
                    <b-form-group label-for="startDate" v-bind:label="$t('Billing period start')">
                        <b-form-datepicker no-flip v-model="expense.updateFields.startDate"
                                           id="startDate"></b-form-datepicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-3">
                    <b-form-group label-for="endDate" label="Billing period end">
                        <b-form-datepicker no-flip v-model="expense.updateFields.endDate"
                                           id="endDate"></b-form-datepicker>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-group v-bind:label="$t('Attachments')">
                        <b-form-file v-model="expense.files"
                                     multiple
                                     :state="Boolean(expense.files)"
                                     placeholder="Choose a file or drop it here..."
                                     drop-placeholder="Drop file here..."></b-form-file>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-12">
                    <span v-for="file in expense.attachments" v-bind:key="file.id" class="mr-4">
                        <a :href="'/api/transactions/files/' + file.id"
                           target="_blank"
                           v-bind:class="{ deleted: file.state == AttachmentState.Deleted }">
                            <font-awesome-icon class="mr-1" icon="file" />
                            {{ file.name }}
                        </a>
                        <b-button variant="outline-secondary" @click="toggleDeleteFile(file)" size="sm">
                            <font-awesome-icon v-if="file.state != AttachmentState.Deleted" icon="trash" />
                            <font-awesome-icon v-if="file.state == AttachmentState.Deleted" icon="undo" />
                        </b-button>
                    </span>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-textarea v-model="expense.updateFields.internalNotes"
                                     id="internalNotes"
                                     rows="6"
                                     max-rows="16"></b-form-textarea>
                </b-col>
            </b-form-row>

            <b-form-row class="mt-4">
                <b-button v-on:click="save" variant="success" class="mr-2">Save</b-button>
                <b-button v-on:click="back" class="mr-2">Cancel</b-button>
                <b-button v-if="mode == MODE_EDIT" v-on:click="deleteRequest" variant="danger">Delete</b-button>
            </b-form-row>
        </b-form>
        <b-modal id="deleteConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete expense?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Watch, Mixins } from 'vue-property-decorator'
    import { Route } from 'vue-router'
    import BaseComponent from '@/components/Shared/BaseComponent.vue'
    import { api } from '@/services/api'
    import { Expense } from '@/models/Transactions/Expense'
    import { Attachment, AttachmentState } from '@/models/Transactions/Attachment'

    @Component
    export default class TransactionExpense extends Mixins(BaseComponent) {
        private expense: Expense = {} as Expense

        readonly MODE_UNDEFINED = 0
        readonly MODE_CREATE = 1
        readonly MODE_EDIT = 2
        private mode = this.MODE_UNDEFINED

        @Watch('$route', { immediate: true, deep: true })
        onRouteChange(r: Route): void {
            this.readRouteParams(r)
            this.fetchData()
        }

        readRouteParams(r: Route): void {
            this.id = r.params.id
            if (this.id) {
                this.mode = this.MODE_EDIT
                return
            }

            this.accountId = Number(r.params.accountId)
            if (this.accountId) {
                this.mode = this.MODE_CREATE
                return
            }

            this.mode = this.MODE_UNDEFINED
        }

        loaded = false
        fetchData(): void {
            if (this.mode == this.MODE_UNDEFINED) return

            var result =
                this.mode == this.MODE_CREATE
                    ? api.get<Expense>('/api/transactions/addExpense', { accountId: this.accountId })
                    : api.get<Expense>(`/api/transactions/editExpense/${this.id}`)

            result
                .then((data) => {
                    this.expense = data
                    this.loaded = true
                })
                .catch((error: Error) => {
                    this.error = error
                    this.mode = this.MODE_UNDEFINED
                    this.loaded = false
                })
        }

        save(): void {
            if (this.mode == this.MODE_UNDEFINED) return

            var result =
                this.mode == this.MODE_CREATE
                    ? api.postFormData<Expense>('/api/transactions/addExpense', this.expense)
                    : api.postFormData<Expense>('/api/transactions/editExpense', this.expense)

            result
                .then(() => {
                    this.back()
                })
        }

        deleteRequest(): void {
            this.$bvModal.show('deleteConfirmation')
        }

        deleteConfirmed(): void {
            if (this.mode != this.MODE_EDIT) return

            api.del(`/api/transactions/${this.id}`)
                .then(() => {
                    this.back()
                })
        }

        toggleDeleteFile(file: Attachment): void {
            file.state =
                file.state == AttachmentState.Existing ? AttachmentState.Deleted : AttachmentState.Existing
        }

        private id = ''
        private accountId = 0
        private error: Error | null = null
        get header(): string {
            if (!this.expense || this.mode == this.MODE_CREATE) return 'New expense'
            if (this.expense.displayFields && this.expense.displayFields.referenceNumber)
                return `Expense #${this.expense.displayFields.referenceNumber}`
            return 'Expense'
        }
        get transactionDate(): string {
            return !this.expense ||
                this.expense.updateFields.transactionDate == undefined ||
                this.expense.updateFields.transactionDate.length < 19
                ? ''
                : this.expense.updateFields.transactionDate.substr(0, 10)
        }
        set transactionDate(value: string) {
            if (
                !this.expense ||
                this.expense.updateFields.transactionDate == undefined ||
                this.expense.updateFields.transactionDate.length < 19
            )
                return
            this.expense.updateFields.transactionDate =
                value + this.expense.updateFields.transactionDate.substr(10, 25)
        }

        get transactionTime(): string {
            return !this.expense ||
                this.expense.updateFields.transactionDate == undefined ||
                this.expense.updateFields.transactionDate.length < 19
                ? ''
                : this.expense.updateFields.transactionDate.substr(11, 8)
        }
        set transactionTime(value: string) {
            if (
                !this.expense ||
                this.expense.updateFields.transactionDate == undefined ||
                this.expense.updateFields.transactionDate.length < 19
            )
                return
            this.expense.updateFields.transactionDate =
                this.expense.updateFields.transactionDate.substr(0, 11) +
                value +
                this.expense.updateFields.transactionDate.substr(19, 14)
        }
        AttachmentState = AttachmentState
    }
</script>

<style scoped>
    .deleted {
        text-decoration: line-through;
    }
</style>
