<template>
    <div>
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <activity-edit v-if="isEdit" :activity="activity" />
        <b-button v-if="!isEdit" variant="primary" v-on:click="onAddClick">
            {{
      $t('Add activity')
            }}
        </b-button>
        <b-button v-if="isEdit" variant="primary" v-on:click="onSave">{{ $t('Save') }}</b-button>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import activityEdit from './../../components/Activity/ActivityEdit.vue'
    import { api } from '@/services/api'
    import { ActivityDto2 } from '@/models/ActivityDto2'
    import { EventBus } from './../Shared/EventBus'
    import { EntityType } from '../../models/Enums'
    import RecordPointer2 from '@/models/RecordPointer2'

    @Component({
        components: {
            activityEdit
        }
    })
    export default class ActivityAdd2 extends Vue {
        @Prop({ type: Number, required: true }) parentEntity!: EntityType | null
        @Prop({ required: true }) parentId!: number | string | null 

        public isEdit = false

        public activity =  {} as ActivityDto2

        public error: string | null = null

        onAddClick(): void {
            this.fetchData()
        }

        onSave(): void {
            this.isEdit = false
            EventBus.$emit('activityAdded')
        }

        async fetchData(): Promise<void> {
            api.postExt<RecordPointer2, ActivityDto2>('/api/activity/add', { entityType: this.parentEntity, id: this.parentId } as RecordPointer2)
                .then((output: ActivityDto2) => {
                    this.activity = output
                    this.isEdit = true
                })
        }
    }
</script>

<style scoped></style>
