export enum EntityType {
  Undefined,
  Object,
  Account,
  Activity,
  Demand, 
  Deal,
  Reservation,
  Viewing
}

export function toEntityType(src: string): EntityType {
    switch (src) {
        case 'Lead':
            return EntityType.Demand
        case 'Object':
            return EntityType.Object
        case 'Account':
            return EntityType.Account
        case 'Activity':
            return EntityType.Activity
        case 'Deal':
            return EntityType.Deal
        case 'Reservation':
            return EntityType.Reservation
        case 'Viewing':
            return EntityType.Viewing
        case 'Undefined':
            return EntityType.Undefined
        default:
            return EntityType.Undefined
    }
}
