<template>
    <vue-bootstrap-autocomplete :data="searchResults"
                             v-model="displayedText"
                             :maxMatches="20"
                             :serializer="(s) => s.referenceNumber + ' ' + (s.title ? s.title : '')"
                             @hit="$emit('input', $event.id)" />
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { Debounce } from 'vue-debounce-decorator'
    import { EventBus } from './../Shared/EventBus'

    @Component
    export default class FieldObjectSearch extends Vue {
        @Prop(Number) id?: number
        @Prop(String) initialText?: string
        @Prop(String) options?: string

        selectedText: string | null = null
        searchResults: ObjectSearchOutputItem[] = []

        created() {
            EventBus.$on('FieldObjectSearch_reset', ()=>this.selectedText = null)
        }

        get displayedText() {
            return this.selectedText != null
                ? this.selectedText
                : this.initialText != null
                    ? this.initialText
                    : ''
        }
        set displayedText(newValue: string) {
            this.selectedText = newValue
            EventBus.$emit('FieldObjectSearch_DisplayedText_Changed', newValue)
        }

        @Debounce(400)
        async search(searchStr?: string) {
            if (searchStr == null || searchStr == '') {
                this.searchResults = []
                return
            }
            try {
                api.get<ObjectSearchOutput>('/api/object/searchByRefNumOrTitle', {
                    searchStr: searchStr, 
                    options: this.options ?? 'no'
                })
                    .then((output: ObjectSearchOutput) => this.searchResults = output.items)
            } catch (e) {
                console.log(e)
                EventBus.$emit('Error', e)
            }
        }

        @Watch('displayedText')
        onDisplayedTextChanged(val: string) {
            this.search(val)
        }
    }
interface ObjectSearchOutput {
  items: ObjectSearchOutputItem[]
}

interface ObjectSearchOutputItem {
  id?: number
  referenceNumber?: string
  title?: string
}
</script>

<style scoped></style>
