<template>
    <div>
        <b-form>
            <b-form-row>
                <b-col class="col-2">
                    <field-form-bool v-model="item.forPurchase" v-bind:label="$t('For purchase')" />
                </b-col>
                <b-col class="col-2">
                    <field-form-bool v-model="item.forSell" v-bind:label="$t('Owner for sale')" />
                </b-col>
            </b-form-row>
            <b-collapse id="collapse-purchase-budget" :visible="item.forPurchase || item.forSell">
                <b-form-row>
                    <b-col sm="2">
                        <my-input id="purchaseBudgetRangeMin"
                                  label="from"
                                  v-model.number="item.purchaseBudgetRangeMin"
                                  type="number"></my-input>
                    </b-col>
                    <b-col sm="2">
                        <my-input id="purchaseBudgetRangeMax"
                                  label="up to"
                                  v-model.number="item.purchaseBudgetRangeMax"
                                  type="number"></my-input>
                    </b-col>
                </b-form-row>
            </b-collapse>

            <b-form-row>
                <b-col class="col-2">
                    <field-form-bool v-model="item.forRent" v-bind:label="$t('For long term rent')" />
                </b-col>
                <b-col class="col-2">
                    <field-form-bool v-model="item.forRentOut" v-bind:label="$t('Owner for rent')" />
                </b-col>
            </b-form-row>
            <b-collapse id="collapse-rent-budget" :visible="item.forRent || item.forRentOut">
                <b-form-row>
                    <b-col sm="2">
                        <my-input id="rentBudgetRangeMin"
                                  label="from"
                                  v-model="item.rentBudgetRangeMin"
                                  type="number"></my-input>
                    </b-col>
                    <b-col sm="2">
                        <my-input id="rentBudgetRangeMax"
                                  label="up to"
                                  v-model="item.rentBudgetRangeMax"
                                  type="number"></my-input>
                    </b-col>
                </b-form-row>
            </b-collapse>

            <b-form-row>
                <b-col class="col-12 col-sm-10 col-md-10">
                    <field-form-bool v-model="item.forShortRent" v-bind:label="$t('For short rent')" />
                </b-col>
            </b-form-row>
            <b-collapse id="collapse-short-rent-budget" :visible="item.forShortRent">
                <b-form-row>
                    <b-col sm="2">
                        <my-input id="shortRentBudgetRangeMin"
                                  label="from"
                                  v-model="item.shortRentBudgetRangeMin"
                                  type="number"></my-input>
                    </b-col>
                    <b-col sm="2">
                        <my-input id="shortRentBudgetRangeMax"
                                  label="up to"
                                  v-model="item.shortRentBudgetRangeMax"
                                  type="number"></my-input>
                    </b-col>
                </b-form-row>
            </b-collapse>
            <b-form-row>
                <b-col class="col-6">
                    <b-form-group label-for="source" v-bind:label="$t('Source')">
                        <b-form-select v-model="item.source"
                                       :options="item.sourceList"
                                       id="source"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-3">
                    <b-form-group label-for="stage" v-bind:label="$t('Stage')">
                        <b-form-select v-model="item.stage"
                                       :options="item.stageList"
                                       id="stage"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col class="col-3">
                    <b-form-group label-for="priority" v-bind:label="$t('Priority')">
                        <b-form-select v-model="item.priority"
                                       :options="item.priorityList"
                                       id="priority"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-form-row>
            <b-form-row>
                <b-col class="col-4">
                    <label for="startDate">{{ $t('Start date') }}</label>
                    <b-form-datepicker no-flip id="startDate"
                                       v-model="item.startDate"
                                       class="mb-2"></b-form-datepicker>
                </b-col>
                <b-col class="col-4">
                    <label for="endDate">{{ $t('End date') }}</label>
                    <b-form-datepicker no-flip id="endDate" v-model="item.endDate" class="mb-2"></b-form-datepicker>
                </b-col>
            </b-form-row>

            <b-form-group label="Objects:">
                <b-form-row>
                    <b-form inline>
                        <span v-for="(o, i) in item.objects" v-bind:key="o.objectId" class="ml-2">
                            <router-link :to="{ name: 'Objects/View', params: { id: o.objectId } }">
                                #{{ o.objectReferenceNumber }}
                            </router-link>
                            <b-button variant="outline-danger" size="sm" class="mr-2" @click="deleteRequest(o, i)">
                                <font-awesome-icon icon="trash" />
                            </b-button>
                        </span>
                        <span class="ml-2 mr-1">Add new object:</span><field-object-search v-model="newObjectId" />
                        <b-button variant="primary" v-on:click="onAddObject">{{ $t('Add') }}</b-button>
                        <label v-if="newObjectDupicate" style="color: red" class="ml-2" for="startDate">{{ $t('Object already in the list!') }}</label>
                    </b-form>
                </b-form-row>
            </b-form-group>

            <label>{{ $t('Description') }}</label>
            <b-form-textarea v-model="item.description"
                             id="description"
                             rows="4"
                             max-rows="16"
                             class="mb-2"></b-form-textarea>
            <span> | {{ $t('Created') }}: {{ item.createdOn | date }} {{ item.createdBy }}</span>
            <span> | {{ $t('Modified') }}: {{ item.modifiedOn | date }} {{ item.modifiedBy }}</span>
        </b-form>
        <b-modal id="deleteObjectConfirmation" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete link to object?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import fieldFormBool from '@/components/Shared/FieldFormBool.vue'
    import fieldFormBoolNullable from '@/components/Shared/FieldFormBoolNullable.vue'
    import MyInput from '@/components/Shared/MyInput.vue'
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import LeadDto from '@/models/LeadDto'
    import { LeadObjectDto } from '@/models/LeadDto'
    import { Debounce } from 'vue-debounce-decorator'
    import { api } from '@/services/api'
    import vueFilePond from 'vue-filepond'
    import 'filepond/dist/filepond.min.css'
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
    import FieldObjectSearch from '@/components/Shared/FieldObjectSearch.vue'
    import { EventBus } from './../Shared/EventBus'

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

    @Component({
        components: {
            FieldObjectSearch,
            FilePond,
            fieldFormBool,
            fieldFormBoolNullable,
            MyInput
        }
    })
    export default class LeadEdit extends Vue {
        @Prop({ type: Object, required: true })
        public lead!: LeadDto

        get item(): LeadDto { return this.lead }

        created() {
            EventBus.$on('FieldObjectSearch_DisplayedText_Changed', this.newObjectTextChanged)
        }

        readonly VIEW_MODE_EDIT_EXPANDED = 2

        @Debounce(400)
        @Watch('item', { deep: true })
        public save(): void {
            api.post('/api/lead/update', this.item)
        }
        newObjectId: number | null = null
        newObjectText: string | null = null
        newObjectDupicate: boolean = false
        newObjectTextChanged(val: string) {
            this.newObjectText = val
            this.newObjectDupicate = false
        }
        onAddObject() {
            if (this.newObjectId) {
                if (!this.item.objects) this.item.objects = []
                if(this.item.objects.find(o => o.objectId === this.newObjectId)) {
                    this.newObjectId = null
                    this.newObjectText = null
                    this.newObjectDupicate = true
                    return
                }
                this.item.objects.push({ objectId: this.newObjectId, objectReferenceNumber: this.newObjectText })
                this.newObjectId = null
                this.newObjectText = null
            }
        }
        deleteIndex?: number
        deleteItem?: LeadObjectDto
        deleteRequest(o: LeadObjectDto, index: number) {
            this.deleteItem = o
            this.deleteIndex = index
            this.$bvModal.show('deleteObjectConfirmation')
        }
        deleteConfirmed() {
            if (
                this.deleteIndex === undefined ||
                this.deleteItem === undefined ||
                this.deleteItem.objectId === null
            ) {
                return
            }
            this.item.objects?.splice(this.deleteIndex, 1);
        }
    }
</script>

<style scoped></style>
