<template>
  <vue-bootstrap-autocomplete
    :data="addresses"
    v-model="locationText"
    :maxMatches="20"
    :serializer="(s) => s.text"
    @hit="$emit('input', $event.id)"
  />
</template>

<script>
import _ from 'lodash'

// const API_URL = 'https://localhost:5001/api/location/search?searchStr=:query'
// const API_URL = 'https://trustate.ru/api/location/search?searchStr=:query'
const API_URL = window.location.origin + '/api/location/search?searchStr=:query'

export default {
  name: 'FieldLocation',
  props: ['value', 'text'],
  data() {
    return {
      locationTextInt: null,
      locationId: null,
      addresses: []
    }
  },
  computed: {
    locationText: {
      get: function () {
        return this.locationTextInt != null
          ? this.locationTextInt
          : this.text != null
          ? this.text
          : ''
      },
      set: function (newValue) {
        this.locationTextInt = newValue
      }
    }
  },
  watch: {
    locationText: _.debounce(function (addr) {
      this.getAddresses(addr)
    }, 500)
  },
  methods: {
    async getAddresses(query) {
      //window.console.log(query)
      if (!query) {
        this.addresses = []
        return
      }
      const res = await fetch(API_URL.replace(':query', query))
      const suggestions = await res.json()
      //window.console.log(suggestions)
      this.addresses = suggestions.items
    }
  }
}
</script>

<style scoped></style>
