<template>
  <div class="container-sm mt-2" style="max-width: 400px">
    <b-form @submit.stop.prevent="onSubmit">
      <h1>{{ $t('Add account') }}</h1>
      <error-box :error="error" />

      <b-form-group :label="$t('firstName')" label-for="firstName">
        <b-form-input
          id="firstName"
          name="firstName"
          v-model="firstName"
          trim
          v-validate="'required'"
          :state="errors.items[0] ? false : valid ? true : null"
        >
        </b-form-input>
        <b-form-invalid-feedback id="firstName-live-feedback">{{
          errors.first('firstName')
        }}</b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('lastName')" label-for="lastName">
        <b-form-input
          id="lastName"
          name="lastName"
          v-model="lastName"
          trim
          v-validate="'required'"
          :state="errors.items[0] ? false : valid ? true : null"
        >
        </b-form-input>
        <b-form-invalid-feedback id="lastName-live-feedback">{{
          errors.first('lastName')
        }}</b-form-invalid-feedback>
      </b-form-group>

      <my-input label="Email" v-model="email" id="email"></my-input>
      <b-button type="submit" variant="primary">Add</b-button>
      <b-button type="reset" variant="default">Cancel</b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import MyInput from '@/components/Shared/MyInput.vue'
import { MyError } from '@/misc/ErrorService'

@Component({
  components: {
    MyInput
  }
})
export default class AccountEdit extends Vue {
  firstName = ''
  lastName = ''
  email = ''

  error: MyError | null = null
}
</script>
