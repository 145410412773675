<template>
    <div>
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <div class="container mb-3 mt-2">
            <div class="row mb-3">
                <div class="col-lg">
                    <h4 style="display: inline" class="mr-3">
                        <span v-if="item.forPurchase">
                            {{ $t('For purchase') }}
                            <field-range-currency :min="item.purchaseBudgetRangeMin"
                                                  :max="item.purchaseBudgetRangeMax"
                                                  :edit="isEdit"
                                                  v-on:click="edit"
                                                  name="purchaseBudgetRangeMin" />
                        </span>
                        <span v-if="item.forPurchase && item.forRent"> | </span>
                        <span v-if="item.forRent">
                            {{ $t('For rent') }}
                            <field-range-currency :min="item.rentBudgetRangeMin"
                                                  :max="item.rentBudgetRangeMax"
                                                  :edit="isEdit"
                                                  v-on:click="edit"
                                                  name="rentBudgetRangeMin" />
                        </span>
                        <span v-if="item.forRent && item.forShortRent"> | </span>
                        <span v-if="item.forShortRent">
                            {{ $t('For short rent') }}
                            <field-range-currency :min="item.shortRentBudgetRangeMin"
                                                  :max="item.shortRentBudgetRangeMax"
                                                  :edit="isEdit"
                                                  v-on:click="edit"
                                                  name="shortRentBudgetRangeMin" />
                        </span>
                    </h4>
                    <span class="mr-3">
                        {{ $t('Stage') }}:
                        <field name="stage"
                               type="select"
                               :value="item.stage"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <span v-if="item.stage"> | </span>
                        {{ $t('Priority') }}:
                        <field name="priority"
                               type="select"
                               :value="item.priority"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <span v-if="item.priority"> | </span>
                        {{ $t('Source') }}:
                        <span v-if="!item.source">not set!</span>
                        <field name="source"
                               type="select"
                               :value="item.source"
                               :edit="isEdit"
                               v-on:click="edit" />
                        <span v-if="item.priority"> | </span>
                        {{ $t('Assigned to') }}:
                        <span>(Nikos)</span>
                        <span> | {{ $t('Created') }}: {{ item.createdOn | date }} {{ item.createdBy }} </span>
                        <span>
                            | {{ $t('Modified') }}: {{ item.modifiedOn | date }} {{ item.modifiedBy }}
                        </span>
                        <span></span>
                    </span>
                </div>
            </div>
        </div>
        <div class="container">
            {{ $t('Objects') }}:
            <span v-for="o in item.objects" v-bind:key="o.objectId">
                <router-link :to="{ name: 'Objects/View', params: { id: o.objectId } }">
                    #{{ o.objectReferenceNumber }}
                </router-link>
            </span>
        </div>
        <div class="col-lg">
            <field name="description"
                   type="textarea"
                   :value="item.description"
                   :edit="isEdit"
                   v-on:click="edit" />
        </div>
        <fieldEditor />
        <div class="col-lg mt-3">
            <assignment parent="Lead" :parentId="item.id"></assignment>
        </div>

        <div class="container mt-3">
            <div>
                <accountViewFields v-if="viewMode != VIEW_MODE_EDIT_EXPANDED"
                                   :id="item.accountId"
                                   v-on:referenceNumberReceived="referenceNumber = $event"
                                   :view-mode="viewMode"></accountViewFields>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import field from './../Shared/Field.vue'
    import fieldRow from './../Shared/FieldRow.vue'
    import fieldEditor from './../Shared/FieldEditor.vue'
    import { EventBus } from './../Shared/EventBus'
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import LeadViewDto from './../../models/LeadViewDto'
    import FieldRangeCurrency from './../../components/Shared/FieldRangeCurrency.vue'
    import accountViewFields from './../../components/Accounts/AccountViewFields.vue'
    import Assignment from './../Shared/Assignment.vue'

    @Component({
        components: {
            accountViewFields,
            field,
            fieldRow,
            fieldEditor,
            FieldRangeCurrency,
            Assignment
        }
    })
    export default class LeadViewFields extends Vue {
        @Prop({ type: Number, required: true }) viewMode!: number

        readonly VIEW_MODE_VIEW = 0
        readonly VIEW_MODE_EDIT = 1
        readonly VIEW_MODE_EDIT_EXPANDED = 2

        get isEdit(): boolean {
            return this.viewMode === this.VIEW_MODE_EDIT
        }

        public item: LeadViewDto = new LeadViewDto()
        public error: string | null = null

        constructor() {
            super()
            EventBus.$on('viewUpdated', this.updateItem)
            this.fetchData()
        }

        fetchData(): void {
            const id = this.$route.params.id
            if (!id) {
                return
            }
            api.get<LeadViewDto>(`/api/lead/view/${id}`)
                .then((data: LeadViewDto) =>
                    this.item = data)
        }

        edit(fieldName: string, type: string): void {
            this.showFieldEditor(fieldName, type)
        }

        showFieldEditor(fieldName: string, type: string): void {
            console.log('EventBus.$emit')
            EventBus.$emit('fieldEdit', {
                entity: 'lead',
                id: this.item.id,
                label: this.$t(fieldName),
                name: fieldName,
                type: type,
                // eslint-disable-next-line
                value: (this.item as any)[fieldName]
            })
        }

        updateItem(event: LeadViewDto, entity: string): void {
            if (entity == 'lead') this.item = event
        }
    }
</script>

<style scoped></style>
