import User from './User'

export enum LoginStatus{
    NotLogged, 
    InProgress,
    Logged
}

export interface AuthenticationState {
    status: LoginStatus
    user: User | null
} 