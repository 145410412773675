<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3 form-container">
                <router-view />
            </div>
            <div class="col-md-9 bg-image">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'

    @Component
    export default class FullScreen extends Vue {
    }
</script>
