<template>
    <b-form-row class="ml-0">
        <vue-bootstrap-autocomplete :data="searchResults"
                                 v-model="displayedText"
                                 :maxMatches="20"
                                 :serializer="(s) => s.description"
                                 @hit="$emit('input', $event.id)" />
        <b-button v-if="reset_button" variant="outline-primary" size="sm" @click="reset">
            X
        </b-button>
    </b-form-row>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { AccountSearchOutput, AccountSearchOutputItem } from '@/models/AccountSearchOutput'
    import { Debounce } from 'vue-debounce-decorator'
    import { EventBus } from './../Shared/EventBus'

    // eslint-disable-next-line
    export enum AccountSearchFilter { None, OnlyEmploye, OnlyEmployeAndProfessional }

    @Component
    export default class FieldAccountSearch extends Vue {
        @Prop(Number) id?: number
        @Prop(String) initialText?: string
        @Prop({ default: AccountSearchFilter.None }) filter!: AccountSearchFilter
        @Prop(Boolean) reset_button?: boolean

        selectedText: string | null = null
        searchResults: AccountSearchOutputItem[] = []

        get displayedText() {
            return this.selectedText != null
                ? this.selectedText
                : this.initialText != null
                    ? this.initialText
                    : ''
        }
        set displayedText(newValue: string) {
            this.selectedText = newValue
            EventBus.$emit('FieldAccountSearch_DisplayedText_Changed', newValue)
        }

        @Debounce(400)
        async search(searchStr?: string) {
            if (searchStr == null || searchStr == '') {
                this.searchResults = []
                return
            }
            try {
                api.get<AccountSearchOutput>('/api/account/search', {
                    searchStr: searchStr, filter: this.filter
                })
                    .then((output: AccountSearchOutput) => this.searchResults = output.items)
            } catch (e) {
                console.log(e)
                EventBus.$emit('Error', e)
            }
        }

        @Watch('displayedText')
        onDisplayedTextChanged(val: string) {
            this.search(val)
        }

        reset(): void {
            this.displayedText = ''
            this.$emit('input', null)
        }
    }
</script>

<style scoped></style>
