<script lang="ts">
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    //import Component from 'vue-class-component'

    export interface IIndexable {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        [key: string]: any
    }

    @Component
    export default class SortableList extends Vue {
        list = { items: Array<unknown>() }
        columns: Array<string> = []
        sortColumnIndex = 0
        sortDescending = false
        public sortByColumn(columnIndex: number): void {
            let column: string = this.columns[columnIndex]
            let isMultiColumn = column.indexOf(',') !== -1
            let columns = column.split(',')
            let getMultiColumnValue = function (a: unknown) {
                return columns
                    .map(function (column) {
                        return (a as IIndexable)[column] ? '1' : '0'
                    })
                    .join('')
            }

            this.sortDescending = this.sortColumnIndex == columnIndex && !this.sortDescending
            this.sortColumnIndex = columnIndex

            let sortOrder = this.sortDescending ? -1 : 1
            this.list.items.sort(function (a, b) {
                let aVal = !isMultiColumn ? (a as IIndexable)[column] : getMultiColumnValue(a)
                let bVal = !isMultiColumn ? (b as IIndexable)[column] : getMultiColumnValue(b)
                if (aVal === bVal) return 0
                // move nulls and empty strings to end in any case
                if (aVal === null || aVal === '') return 1
                if (bVal === null || bVal === '') return -1
                return aVal > bVal ? sortOrder : -sortOrder
            })
        }
    }
</script>
