<template>
    <div>
        <lead-edit v-if="lead.id" :lead="lead" />
        <accountEditRequest v-if="lead.accountId" :id="lead.accountId"></accountEditRequest>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import LeadDto from './../../models/LeadDto'
    import { api } from '@/services/api'
    import leadEdit from './../../components/Leads/LeadEdit.vue'
    import accountEditRequest from './../../components/Accounts/AccountEditRequest.vue'

    @Component({
        components: {
            accountEditRequest,
            leadEdit
        }
    })
    export default class LeadEditRequest extends Vue {
        @Prop({ type: String, required: true }) id!: string

        public lead: LeadDto = {} as LeadDto
        constructor() {
            super()
            this.fetchData()
        }

        fetchData(): void {
            api.get<LeadDto>(`/api/lead/get/${this.id}`)
                .then((data: LeadDto) => this.lead = data);
        }
    }
</script>

<style scoped></style>
