<template>
  <div>
    <GmapMap :center="defaultCenter"
             :zoom="10"
             style="width: 1000px; height: 600px">
      <GmapMarker :key="index"
                  v-for="(m, index) in markers"
                  :position="m"
                  :clickable="true"
                  @click="openInfoWindow(m)">
      </GmapMarker>
      <GmapInfoWindow :options="{ pixelOffset: { width: 0, height: -35 } }"
                      :position="infoWindow.position"
                      v-if="infoWindow.marker !== null"
                      @closeclick="infoWindow.marker = null">
        <router-link :to="{ name: 'Objects/View', params: { id: infoWindow.marker.id } }">
          #{{ infoWindow.marker.label }}
        </router-link>
      </GmapInfoWindow>
    </GmapMap>
  </div>
</template>

<script lang="ts">
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import IGMapMarker from '@/models/IGMapMarker'
  @Component({
  })
  export default class ObjectsOnMap extends Vue {
    @Prop({ type: Array, required: true }) markers!: IGMapMarker[]

    defaultCenter: IPosition = {
      lat: 40.63471386411826,
      lng: 22.93874616154762,
      title: ''
    }
    infoWindow = Vue.observable({
      marker: null as IGMapMarker | null,
      position: { lat: 0, lng: 0 }
    });

    openInfoWindow(marker: IGMapMarker) {
      this.infoWindow.marker = marker;
      this.infoWindow.position = { lat: marker.lat, lng: marker.lng };
    }
    //openProperty(id: number) {
    //  this.$router.push({ name: 'Objects/View', params: { id: id.toString() } });
    //}
  }

  interface IPosition {
    lat: number
    lng: number
    title: string
  }
</script>

<style scoped></style>
