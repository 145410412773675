export interface Attachment {
  id?: string
  name?: string
  state?: AttachmentState
}
export enum AttachmentState {
  Deleted,
  Existing,
  Added
}
