<template>
  <b-form-group
    v-bind:label="$t(label)"
    :label-cols="labelcols"
    v-on:click="$emit('input', !value)"
    class="margin-bottom-0"
  >
    <div class="col-form-label">
      <toggle-button
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        :labels="{ checked: $tc('Yes'), unchecked: $tc('No') }"
        :sync="true"
      />
    </div>
  </b-form-group>
</template>

<script>
export default {
  name: 'FieldFormBool',
  props: {
    label: String,
    value: Boolean,
    labelcols: {
      default: true
    }
  }
}
</script>
