export class EmployeListQueryOutput {
    public items: EmployeListQueryOutputItem[] = []
}

export class EmployeListQueryOutputItem {
    id?: number
    name?: string
    role?: string
    public constructor(init?: Partial<EmployeListQueryOutputItem>) {
        Object.assign(this, init)
    }
}
