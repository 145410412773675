<template>
  <b-modal
    id="object-map-editor"
    :title="$t('Edit location on the map')"
    @ok="handleOk"
    @show="beforeShow"
    :cancel-title="$t('Cancel')"
    size="xl"
  >
    <div>
      <b-form>
        <b-form-row>
          <b-button variant="outline-primary" class="m-2" v-b-toggle.set-by-clicking>{{
            $t('Set location by clicking on the map')
          }}</b-button>
          <b-button variant="outline-primary" class="m-2" v-b-toggle.enter-coordinates-manually>{{
            $t('Enter coordinates manually')
          }}</b-button>
          <b-button variant="outline-primary" class="m-2" v-b-toggle.set-approximate-location>{{
            $t('Approximate location')
          }}</b-button>
        </b-form-row>
        <b-collapse accordion="my-accordion" id="set-by-clicking">
          <b-alert show variant="warning"> {{ $t('Click on the map to set a marker') }}: </b-alert>
        </b-collapse>
        <b-collapse accordion="my-accordion" id="enter-coordinates-manually">
          <b-form-row>
            <b-col md="4" sm="12">
              <b-form-group id="mLat" label-cols="auto" :label="$t('Latitude')" label-for="mLat">
                <b-input id="mLat" v-model.number="mLat" />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="12">
              <b-form-group id="mLng" label-cols="auto" :label="$t('Longitude')" label-for="mLng">
                <b-input id="mLng" v-model.number="mLng" />
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-collapse>
        <b-collapse accordion="my-accordion" id="set-approximate-location">
          <b-form-row>
            <b-col md="4" sm="12">
              <field-form-bool
                labelcols="auto"
                v-model="cReq"
                :label="$t('Show approximate location')"
              />
            </b-col>
            <b-col md="4" sm="12">
              <label for="cRad" class="mr-1">{{ $t('Size') }}</label>
              <b-form-spinbutton
                id="cRad"
                v-model.number="cRad"
                inline
                min="40"
                max="10000"
                step="10"
              >
              </b-form-spinbutton>
            </b-col>
            <b-col md="4" sm="12">
              <b-button variant="outline-primary" @click="mode = 'set-approximate-location-set'">{{
                $t('Set on the map')
              }}</b-button>
            </b-col>
          </b-form-row>
          <span v-if="mode == 'set-approximate-location-set'">
            {{ $t('Click on the map to set a center of the approximate location') }}:
          </span>
        </b-collapse>
      </b-form>
      <gmap-map
        :center="center"
        :zoom="zoom"
        style="width: 100%; height: 55vh"
        @click="onClick"
        @zoom_changed="onZoomChanged"
      >
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="center = m.position"
        ></gmap-marker>
        <gmap-circle
          v-if="cReq && mode != 'set-approximate-location-set' && mode != 'set-by-clicking'"
          :center="circlePos"
          :radius="cRad"
          :options="{
            fillColor: 'red',
            fillOpacity: 0.35,
            strokeColor: 'red',
            strokeOpacity: 0.8,
            strokeWeight: 1
          }"
        >
        </gmap-circle>
      </gmap-map>
    </div>
  </b-modal>
</template>

<script>
import { EventBus } from './../Shared/EventBus'
import fieldFormBool from './../Shared/FieldFormBool.vue'

export default {
  name: 'ObjectEditMapEditor',
  props: {
    markerLat: Number,
    markerLng: Number,
    mapZoom: Number,
    circleRequired: Boolean,
    circleLat: Number,
    circleLng: Number,
    circleRadius: Number
  },
  components: { fieldFormBool },
  data() {
    return {
      defaultMarkerLat: 40.62646106367355,
      defaultMarkerLng: 22.948379516601562,
      defaultZoom: 17,
      mLat: this.markerLat,
      mLng: this.markerLng,
      zoom: this.mapZoom,
      cReq: this.circleRequired,
      cLat: this.circleLat,
      cLng: this.circleLng,
      cRad: this.circleRadius,
      places: [],
      currentPlace: null,
      mode: null
    }
  },
  computed: {
    markers() {
      return this.mLat
        ? [
            {
              position: {
                lat: this.mLat,
                lng: this.mLng
              }
            }
          ]
        : []
    },
    center() {
      return this.mLat
        ? this.markers[0].position
        : { lat: this.defaultMarkerLat, lng: this.defaultMarkerLng }
    },
    circlePos() {
      return { lat: this.cLat, lng: this.cLng }
    }
  },
  created() {
    EventBus.$on('ObjectMapEditorShow', this.show)
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (
        (collapseId.startsWith('set-by-clicking') ||
          collapseId.startsWith('enter-coordinates-manually') ||
          collapseId.startsWith('set-approximate-location')) &&
        isJustShown
      ) {
        this.mode = collapseId
        window.console.log('mode set on', this.mode)
      }
    })
  },
  methods: {
    onClick(x) {
      if (this.mode == 'set-by-clicking') {
        this.mLat = x.latLng.lat()
        this.mLng = x.latLng.lng()
        this.$root.$emit('bv::toggle::collapse', 'set-by-clicking')
        this.mode = null
      }
      if (this.mode == 'set-approximate-location-set') {
        this.cLat = x.latLng.lat()
        this.cLng = x.latLng.lng()
        this.mode = 'set-approximate-location'
      }
    },
    onZoomChanged(newZoom) {
      this.zoom = newZoom
    },
    beforeShow() {
      this.mLat = this.markerLat ? this.markerLat : this.defaultMarkerLat
      this.mLng = this.markerLng ? this.markerLng : this.defaultMarkerLng
      this.zoom = this.mapZoom ? this.mapZoom : this.defaultZoom
      this.cReq = this.circleRequired
      this.cLat = this.circleLat ? this.circleLat : this.defaultMarkerLat
      this.cLng = this.circleLng ? this.circleLng : this.defaultMarkerLng
      this.cRad = this.circleRadius ? this.circleRadius : 2500
    },
    handleOk() {
      var event = {
        markerLat: this.mLat,
        markerLng: this.mLng,
        mapZoom: this.zoom,
        circleRequired: this.cReq,
        circleLat: this.cLat,
        circleLng: this.cLng,
        circleRadius: this.cRad
      }
      this.$emit('changed', event)
    }
  }
}
</script>
