import Vue from 'vue'

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

//import { en } from './en'
// import { gr } from './gr';
//import { ru } from './ru'

const en: any = {}
const el: any = {}
const ru: any = {}

fetch('/api/Localization')
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        populate(data, en, 'en');
        populate(data, el, 'el');
        populate(data, ru, 'ru');
    });

function populate(data: any, obj: any, localizationCulture: string) {
    data = data.filter((i: { localizationCulture: string }) => i.localizationCulture == localizationCulture)
    for (let i = 0; i < data.length; i++) { obj[data[i].key] = data[i].text }
}

const i18n = new VueI18n({
  //locale: navigator.language.split('-')[0],
  messages: { ru, en, el },
  silentTranslationWarn: true
})

export { i18n }
