<template>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Viewings') }}</h1>
            </div>
            <div class="col-4 text-right">
                <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{(data && data.totalRows) ? data.totalRows : 0}}</b-badge>
            </div>
        </div>
        <b-form class="mb-4">
            <b-form-row>
                <b-button variant="primary" to="/Viewing/Add" class="mr-4">{{ $t('Add new viewing') }}</b-button>
                <b-button :variant="hasFilters ? 'success' : 'primary'" v-b-toggle.collapse-3 class="mr-4">
                    {{
        $t('Show / Hide Filters')
                    }}
                </b-button>
            </b-form-row>
        </b-form>
        <b-collapse id="collapse-3">
            <b-card title="Filters" style="background-color: lightyellow">
                <b-form>
                    <b-form-row class="mb-2">
                        <b-col sm="10">
                            <multiselect v-model="filters.results"
                                         :options="data && data.options ? data.options.results : []"
                                         label="text"
                                         track-by="value"
                                         :multiple="true"
                                         :close-on-select="false"
                                         :clear-on-select="false"
                                         :preserve-search="true"
                                         placeholder="Any result"></multiselect>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="3">
                            <label class="mr-sm-2" for="appointmentCreatedFrom">Appointment created:</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="appointmentCreatedFrom"
                                               reset-button
                                               v-model="filters.appointmentCreatedFrom"></b-form-datepicker>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="appointmentCreatedTill"
                                               reset-button
                                               v-model="filters.appointmentCreatedTill"></b-form-datepicker>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="3">
                            <label class="mr-sm-2" for="dateOfViewingFrom">Date of viewing:</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="dateOfViewingFrom"
                                               reset-button
                                               v-model="filters.dateOfViewingFrom"></b-form-datepicker>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="dateOfViewingTill"
                                               reset-button
                                               v-model="filters.dateOfViewingTill"></b-form-datepicker>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="3">
                            <label class="mr-sm-2" for="objectId">Object reference number or title:</label>
                        </b-col>
                        <b-col sm="3">
                            <field-object-search v-model="filters.objectId" options="any" />
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="3">
                            <label class="mr-sm-2" for="demandId">Name, surname or lead:</label>
                        </b-col>
                        <b-col sm="6">
                            <field-lead-search v-model="filters.demandId" />
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="3">
                            <label class="mr-sm-2" for="assigned">Assigned:</label>
                        </b-col>
                        <b-col sm="6">
                            <field-account-search v-model="filters.assigned" filter="2" :initialText="filters.assignedText"/>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-button variant="primary" v-on:click="applyFilters" class="mr-4">
                            {{
            $t('Apply Filters')
                            }}
                        </b-button>
                        <b-button variant="primary" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
                    </b-form-row>
                </b-form>
            </b-card>

        </b-collapse>
        <b-table :items="tableItems" :fields="fields" primary-key="id" :tbody-tr-class="rowClass" 
                 sticky-header="70vh" :busy="isBusy">
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(id)="data">
                <router-link :to="{ name: 'Viewing/Edit', params: { id: data.item.id } }">
                    {{ data.item.id }}
                </router-link>
            </template>
            <template #cell(dateOfViewing)="data">
                {{ data.item.dateOfViewing | date }}
            </template>
            <template #cell(appointmentCreated)="data">
                {{ data.item.appointmentCreated | date }}
            </template>
            <template #cell(lead)="data">
                <router-link :to="{ name: 'Leads/View', params: { id: data.item.leadId } }">
                    {{ data.item.lead }}
                </router-link>
            </template>
            <template #cell(objects)="data">
              <span v-if="data.item.objects" >
                <router-link v-for="o in data.item.objects.split(' ').map(function (id) {
                return 'ref' + id
              })"
                             v-bind:key="o"
                             :to="{ name: 'Objects/View', params: { id: o } }">
                  {{ o.replace('ref', '#') }}
                </router-link>
              </span>
            </template>
            <template #cell(action)="data">
                <b-button :to="'/Viewing/'+data.item.id" variant="outline-primary" size="sm" class="mb-2">
                    <font-awesome-icon icon="edit" />
                </b-button>
            </template>
        </b-table>
        <b-row>
            <b-col sm="2" md="3" class="my-1">
                <b-form-group label="Per page"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-form-select id="per-page-select"
                                   v-model="filters.perPage"
                                   :options="pageOptions"
                                   size="sm" ></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1" v-if="data && data.totalRows > 0">
                <b-pagination v-model="filters.currentPage"
                              :total-rows="data ? data.totalRows : 0"
                              :per-page="filters.perPage"
                              align="fill"
                              size="sm"
                              class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import Multiselect from 'vue-multiselect'
    import { api } from '@/services/api'
    import { ISelectListItem } from '@/models/ISelectListItem'
    import { myutils } from '@/services/myutils'
    import FieldObjectSearch from '@/components/Shared/FieldObjectSearch.vue'
    import FieldLeadSearch from '@/components/Shared/FieldLeadSearch.vue'
    import FieldAccountSearch from '@/components/Shared/FieldAccountSearch.vue'
    import { EventBus } from '@/components/Shared/EventBus'

    @Component({
        components: {
            FieldObjectSearch,
            FieldLeadSearch,
            Multiselect,
            FieldAccountSearch
        }
    })
    export default class ViewingList extends Vue {
        isBusy = true
        data: IData | null = null
        filters: IFilters = {
            appointmentCreatedFrom: null,
            appointmentCreatedTill: null,
            dateOfViewingFrom: null,
            dateOfViewingTill: null,
            demandId: null,
            objectId: null,
            results: [],
            assigned: null,
            assignedText: null,
            perPage: 20,
            currentPage: 1
        }

        pageOptions = [5, 20, 100]

        created() {
            this.loadFilterFromCookies()
            this.fetchData()
            EventBus.$on('FieldAccountSearch_DisplayedText_Changed', this.setEmployeeText)
        }

        setEmployeeText(value: string) {
            this.filters.assignedText = value
        }

        loadFilterFromCookies(): void {
            const cookieFilter = this.$cookies.get('ViewingList.filters') as IFilters
            if (!cookieFilter) return
            this.filters.currentPage = cookieFilter.currentPage ? cookieFilter.currentPage : 1
            this.filters.perPage = cookieFilter.perPage ? cookieFilter.perPage : 20
            this.filters.results = cookieFilter.results
            this.filters.appointmentCreatedFrom = cookieFilter.appointmentCreatedFrom
            this.filters.appointmentCreatedTill = cookieFilter.appointmentCreatedTill
            this.filters.dateOfViewingFrom = cookieFilter.dateOfViewingFrom
            this.filters.dateOfViewingTill = cookieFilter.dateOfViewingTill
            this.filters.demandId = cookieFilter.demandId
            this.filters.objectId = cookieFilter.objectId
            this.filters.assigned = cookieFilter.assigned
            this.filters.assignedText = cookieFilter.assignedText
        }

        fields = [
            'id', 'appointmentCreated', 'dateOfViewing', 'lead', 'assigned', 'objects', 'result',
            { key: 'action', label: '' }
        ]

        fetchData(): void {
            this.isBusy = true
            api
                .postExt<IFilters, IData>('/api/Viewing/search', this.filters)
                .then((data) => {
                    this.data = data
                    this.isBusy = false
                })
                .catch((error) => {
                    this.isBusy = false
                    console.error('Error fetching data:', error)
                })
        }

        @Watch('filters.perPage')
        onPerPageChanged(): void {
            this.filters.currentPage = 1
            this.$cookies.set('ViewingList.filters', this.filters)
            this.fetchData()
        }

        @Watch('filters.currentPage')
        onPageChanged(): void {
            Vue.nextTick(() => {
                this.$cookies.set('ViewingList.filters', this.filters)
                this.fetchData()
            })
        }

        get hasFilters(): boolean {
            return (this.filters.results && this.filters.results.length > 0) ||
                this.filters.appointmentCreatedFrom != null ||
                this.filters.appointmentCreatedTill != null ||
                this.filters.dateOfViewingFrom != null ||
                this.filters.dateOfViewingTill != null ||
                this.filters.demandId != null ||
                this.filters.objectId != null ||
                this.filters.assigned != null ||
                this.filters.assignedText != null

        }

        applyFilters(): void {
            this.filters.currentPage = 1
            this.$cookies.set('ViewingList.filters', this.filters)
            this.fetchData()
        }
        clearFilters(): void {
            myutils.setPropertiesToNullOrEmptyArray(this.filters)
            this.applyFilters()
        }

        get tableItems(): IDataItem[] {
            return this.data?.items ?? []
        }

        rowClass(item: IDataItem) { 
            return item && item.result ? 'result' + item.result.replaceAll(' ', '').toLowerCase() : ''
        }
    }
    interface IData {
        items: IDataItem[],
        options: IFiltersOptions,
        totalRows: number
    }
    interface IDataItem {
        id: number
        appointmentCreated: Date | null
        dateOfViewing: Date | null
        lead: string | null
        leadId: string | null
        objects: string | null
        result: string | null
    }

    interface IFilters {
        appointmentCreatedFrom: Date | null
        appointmentCreatedTill: Date | null
        dateOfViewingFrom: Date | null
        dateOfViewingTill: Date | null
        demandId: string | null
        objectId: number | null
        results: ISelectListItem[] | null
        assigned: number | null
        assignedText: string | null
        perPage: number
        currentPage: number
    }
    interface IFiltersOptions {
        results: ISelectListItem[]
    }
</script>
<style scoped>
    .bold-text {
        font-weight: bold;
    }
</style>
