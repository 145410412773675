<template>
    <div class="mb-2">
        <b-alert v-if="error" show variant="danger">{{ error }}</b-alert>
        <span v-for="(item, index) in items" v-bind:key="item.systemName" class="mr-2">
            <a :href="'/api/files/' + item.systemName" target="_blank">
                <font-awesome-icon class="mr-1" icon="file" />{{ item.userName }}
            </a>
            <b-button v-if="viewMode == VIEW_MODE_EDIT"
                      variant="outline-secondary"
                      size="sm"
                      @click="deleteRequest(item, index)"
                      class="mr-3">
                <font-awesome-icon icon="trash" />
            </b-button>
        </span>
        <file-pond v-if="viewMode == VIEW_MODE_EDIT"
                   name="myFiles"
                   ref="pond"
                   label-idle="Drop files here..."
                   v-bind:allow-multiple="true"
                   accepted-file-types="image/*, video/*, audio/*, text/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.presentation, application/vnd.oasis.opendocument.spreadsheet, application/vnd.oasis.opendocument.text, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.rar, application/rtf, application/vnd.visio, application/xhtml+xml, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/xml, application/zip, application/x-7z-compressed"
                   server="/api/files"
                   v-on:processfile="fileAdded" />
        <b-modal :id="deleteFileConfirmationName" title="Please confirm" @ok="deleteConfirmed">
            <p class="my-4">Delete file?</p>
        </b-modal>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop } from 'vue-property-decorator'
    import 'filepond/dist/filepond.min.css'
    import vueFilePond from 'vue-filepond'
    import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
    import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
    import { FilePondFile } from 'filepond'
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview)

    import FileLinkDto from './../../models/FileLinkDto'
    import { api } from '@/services/api'

    interface FileApiSetParentInput {
        entityType: string,
        id: number,
        fileNames: string,
        type: number
    }

    @Component({ components: { FilePond } })
    export default class FileLinks extends Vue {
        @Prop(String) parent!: string
        @Prop({ type: Number }) parentId!: number
        @Prop() items?: FileLinkDto[]
        @Prop({ type: Number, required: true }) viewMode!: number

        constructor() {
            super()
        }

        readonly VIEW_MODE_EDIT = 1
        error: string | null = null

        fileAdded(error: string, file: FilePondFile): void {
            if (error) this.error = error
            else {
                const input: FileApiSetParentInput = {
                    entityType: this.parent,
                    id: this.parentId,
                    fileNames: file.serverId,
                    type: 0
                }
                try {
                    api.postExt<FileApiSetParentInput, string>('/api/files/setParent', input)
                    const n = new FileLinkDto()
                    n.systemName = file.serverId
                    n.userName = file.filenameWithoutExtension
                    this.items?.push(n)
                } catch (e) {
                    if (typeof e === "string") {
                        this.error = e
                    }
                    else if (e instanceof Error) {
                        this.error = e.message
                    }
                }
            }
        }

        deleteIndex?: number
        deleteItem?: FileLinkDto

        deleteRequest(item: FileLinkDto, index: number): void {
            this.deleteItem = item
            this.deleteIndex = index
            this.$bvModal.show(this.deleteFileConfirmationName)
        }

        async deleteConfirmed(): Promise<void> {
            if (
                this.deleteIndex === undefined ||
                this.deleteItem === undefined ||
                this.deleteItem.systemName === undefined ||
                this.deleteItem.systemName === null
            ) {
                return
            }
            try {
                await api.del(`/api/files/${this.deleteItem.systemName}`)
            } catch (e) {
                if (typeof e === "string") {
                    this.error = e
                }
                else if (e instanceof Error) {
                    this.error = e.message
                }
            }
            if (this.items) this.items.splice(this.deleteIndex, 1)
        }

        get deleteFileConfirmationName(): string {
            return 'deleteFileConfirmation' + this.parentId
        }
    }
</script>

<style scoped lang="scss"></style>
