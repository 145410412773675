<template>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Clients') }}</h1>
            </div>
            <div class="col-4 text-right">
                <b-badge variant="primary" class="mr-2">{{ $t('Count') }}: {{items.length}}</b-badge>
            </div>
        </div>
        <b-form inline class="mb-4">
            <b-button variant="primary" to="/Accounts/AddClient" class="mr-4">
                {{
        $t('Add new client')
                }}
            </b-button>
            <b-button :variant="hasFilters ? 'success' : 'primary'" v-b-toggle.collapse-3 class="mr-4">
                {{ $t('Show / Hide Filters') }}
            </b-button>
        </b-form>
        <b-collapse id="collapse-3">
            <b-card title="Filters" style="background-color: lightyellow">
                <b-form>
                    <b-form-row class="mb-2">
                        <div class="col-md-auto">
                            <b-form-select v-model="filters.serviceFilter" :options="serviceFilterOptions"></b-form-select>
                        </div>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <div style="padding: 4px">
                            <multiselect v-model="filters.sourcesFilter"
                                         :options="sourcesFilterOptions"
                                         label="text"
                                         track-by="value"
                                         :multiple="true"
                                         :close-on-select="false"
                                         :clear-on-select="false"
                                         :preserve-search="true"
                                         placeholder="All sources"></multiselect>
                        </div>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="2">
                            <label class="mr-sm-2" for="startDate">Time range</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="startDate"
                                               reset-button
                                               v-model="filters.startDateFilter"></b-form-datepicker>
                        </b-col>
                        <b-col sm="3">
                            <b-form-datepicker no-flip class="mr-2"
                                               id="endDate"
                                               reset-button
                                               v-model="filters.endDateFilter"></b-form-datepicker>
                        </b-col>
                        <b-col sm="3"> </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-col sm="2">
                            <label class="mr-sm-2" for="lastNameFilter">Last name</label>
                        </b-col>
                        <b-col sm="3">
                            <b-form-input class="mr-3"
                                          placeholder="Last name"
                                          id="lastNameFilter"
                                          v-model="filters.lastNameFilter"></b-form-input>
                        </b-col>
                    </b-form-row>
                    <b-form-row class="mb-2">
                        <b-button variant="primary" v-on:click="applyFilters" class="mr-4">
                            {{
            $t('Apply filters')
                            }}
                        </b-button>
                        <b-button variant="primary" v-on:click="clearFilters">{{ $t('Clear filters') }}</b-button>
                    </b-form-row>
                </b-form>
            </b-card>
        </b-collapse>
        <b-table :items="items" :fields="fields" primary-key="id" hover :busy="isBusy"
                 sticky-header="70vh"
                 :per-page="perPage"
                 :current-page="currentPage">
            <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-2"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
            <template #cell(createdOn)="data">
                {{ data.item.createdOn | date_short }}
            </template>
            <template #cell(modifiedOn)="data">
                {{ data.item.modifiedOn | date_short }}
            </template>
            <template #cell(name)="data">
                <router-link :to="{ name: 'Accounts/View', params: { id: data.item.id } }">
                    {{ data.item.name }}
                </router-link>
                <font-awesome-icon v-if="data.item.hasNotClosedLead" color="red" icon="exclamation-circle" />
            </template>
            <template #cell(purchaseBudgetRangeMin)="data">
                <field-range-currency :min="data.item.purchaseBudgetRangeMin"
                                      :max="data.item.purchaseBudgetRangeMax"
                                      name="purchaseBudgetRangeMin" />
            </template>
            <template #cell(objectReferenceNumbers)="data">
                <template v-if="data.item.objectReferenceNumbers">
                    <router-link v-for="o in data.item.objectReferenceNumbers.split(' ').map(function (id) {return 'ref' + id})"
                                 v-bind:key="o"
                                 :to="{ name: 'Objects/View', params: { id: o } }">
                        {{ o.replace('ref', '#') }}
                    </router-link>
                </template>
            </template>
        </b-table>
        <b-row>
            <b-col sm="2" md="3" class="my-1">
                <b-form-group label="Per page"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-cols-md="4"
                              label-cols-lg="3"
                              label-align-sm="right"
                              label-size="sm"
                              class="mb-0">
                    <b-form-select id="per-page-select"
                                   v-model="perPage"
                                   :options="pageOptions"
                                   size="sm" v-on:input="onPerPageChanged"></b-form-select>
                </b-form-group>
            </b-col>

            <b-col sm="7" md="6" class="my-1">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="fill"
                              size="sm"
                              class="my-0"></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import Multiselect from 'vue-multiselect'
    import { IListOutput, IListOutputItem, IListInput, IFilterLists } from '@/models/ClientListModels'
    import FieldRangeCurrency from './../../components/Shared/FieldRangeCurrency.vue'
    import { ISelectListItem } from '../../models/ISelectListItem'
    import { api } from '@/services/api'

    @Component({
        components: {
            FieldRangeCurrency,
            Multiselect
        }
    })
    export default class ClientList extends Vue {
        public items: IListOutputItem[] = []
        public filterLists?: IFilterLists | null
        isBusy = true

        totalRows = 1
        currentPage = 1
        perPage = 10
        pageOptions = [5, 10, 20, 50, { value: 1000000, text: "Show all" }]

        fields = [
            { key: 'createdOn', label: 'Created on', sortable: true },
            { key: 'createdBy', label: 'Created by', sortable: true },
            { key: 'name', label: 'Name', sortable: true },
            { key: 'objectReferenceNumbers', label: 'Objects', sortable: true },
            { key: 'purchaseBudgetRangeMin', label: 'Request', sortable: false },
            { key: 'modifiedOn', label: 'Modified on', sortable: true },
            { key: 'modifiedBy', label: 'Modified by', sortable: true },
        ]

        serviceFilterOptions: ISelectListItem[] = []
        sourcesFilterOptions: ISelectListItem[] = []

        filters: Filters = {
            serviceFilter: 0, sourcesFilter: [],
            startDateFilter: null, endDateFilter: null, lastNameFilter: null
        }

        isFirstFetch = true

        created() {
            this.filterLists = { serviceList: [], sourceList: [] }
            const cookieFilter = this.$cookies.get('ClientList.filter')
            if (cookieFilter) {
                this.filters.endDateFilter = cookieFilter.endDateFilter
                this.filters.lastNameFilter = cookieFilter.lastNameFilter
                this.filters.serviceFilter = cookieFilter.serviceFilter
                this.filters.sourcesFilter = cookieFilter.sourcesFilter
                this.filters.startDateFilter = cookieFilter.startDateFilter
            }
            this.fetchData(this.filters)
            this.perPage = this.$cookies.get('ClientList.perPage') as number ?? 20
        }

        get hasFilters(): boolean {
            return this.filters.endDateFilter != null ||
                this.filters.lastNameFilter != null ||
                this.filters.serviceFilter != 0 ||
                this.filters.sourcesFilter.length != 0 ||
                this.filters.startDateFilter != null
        }

        /* eslint-disable  @typescript-eslint/explicit-module-boundary-types */
        async fetchData(x: Filters): Promise<void> {
            let input: IListInput = {
                serviceFilter: x.serviceFilter ?? 0,
                sources: x.sourcesFilter.map((i: ISelectListItem) => i.value ?? 0),
                startDate: x.startDateFilter,
                endDate: x.endDateFilter,
                lastName: x.lastNameFilter
            }
            api
                .postExt<IListInput, IListOutput>('/api/clients/search', input)
                .then((data) => {
                    this.applyData(data)
                })
        }
        applyData(data: IListOutput): void {
            this.items = data.items
            this.filterLists = data.filterLists

            if (this.isFirstFetch && this.filterLists) {
                this.filterLists.serviceList[0] = { value: 0, text: 'All services' }
                this.serviceFilterOptions = this.filterLists.serviceList

                this.sourcesFilterOptions = this.filterLists.sourceList
                this.isFirstFetch = false
            }
            this.totalRows = data.items.length
            this.isBusy = false
        }
        applyFilters(): void {
            this.$cookies.set('ClientList.filter', this.filters)
            this.fetchData(this.filters)
        }

        clearFilters(): void {
            this.filters.serviceFilter = 0
            this.filters.sourcesFilter = []
            this.filters.startDateFilter = null
            this.filters.endDateFilter = null
            this.filters.lastNameFilter = null
            this.$cookies.set('ClientList.filter', this.filters)
            this.fetchData(this.filters)
        }

        onPerPageChanged(): void {
            this.$cookies.set('ClientList.perPage', this.perPage)
        }
    }
    interface Filters {
        serviceFilter: number
        sourcesFilter: ISelectListItem[]
        startDateFilter: Date | null
        endDateFilter: Date | null
        lastNameFilter: string | null
    }
</script>

<style scoped lang="scss"></style>
