import { ActionContext } from 'vuex'
import { State } from '.';
import AlertState from '@/models/AlertState'

const initialState: AlertState = {
    type: null,
    message: null
};

type Context = ActionContext<AlertState, State>;

export const alert = {
    namespaced: true,
    state: initialState,
    actions: {
        success(context: Context, message: string) {
            context.commit('success', message);
        },
        error(context: Context, message: string) {
            context.commit('error', message);
        },
        warning(context: Context, message: string) {
            context.commit('warning', message);
        },
        clear(context: Context) {
            context.commit('clear');
        }
    },
    mutations: {
        success(state: AlertState, message: string) {
            state.type = 'success';
            state.message = message;
        },
        error(state: AlertState, message: string) {
            state.type = 'danger';
            state.message = message;
        },
        warning(state: AlertState, message: string) {
            state.type = 'warning';
            state.message = message;
        },
        clear(state: AlertState) {
            state.type = null;
            state.message = null;
        }
    }
}
