<template>
    <div class="container-fluid" v-if="data">
        <div class="row justify-content-between">
            <div class="col-4">
                <h1>{{ $t('Documents') }}</h1>
            </div>
        </div>
        <b-form class="mb-4">
            <b-form-row>
                <b-button variant="primary" to="/Document/Add" class="mr-4">{{ $t('Add new document') }}</b-button>
            </b-form-row>
        </b-form>
        <b-table :items="data" :fields="fields" primary-key="id">
            <template #cell(name)="data">
                <a :href="data.item.link" target="_blank">
                    <font-awesome-icon class="mr-1" icon="file" />
                    {{data.item.name}}
                </a>
            </template>
            <template #cell(action)="data">
                <b-button :to="'/Document/'+data.item.id" variant="outline-primary" size="sm" class="mb-2">
                    <font-awesome-icon icon="edit" />
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<script lang="ts">
    import { Component, Vue } from 'vue-property-decorator'
    import { api } from '@/services/api'
    import { DocumentDto } from '@/models/DocumentDto'
    @Component
    export default class DocumentList extends Vue {
        data: DocumentDto[] | null = null
        fields = ['name', 'description', { key: 'action', label: '' }]
        created() {
            api
                .get<DocumentDto[]>('/api/Documents')
                .then((data) => {
                    this.data = data
                })
        }
    }
</script>
